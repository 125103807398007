import React, { useState } from "react";
import { Flex, Radio } from "antd";
import { ConditionalFilter, CrudFilters, LogicalFilter } from "@refinedev/core";

const tabFilters = [
  {
    id: "1",
    label: "Status 1",
  },
  {
    id: "2",
    label: "Status 2",
  },
  {
    id: "3",
    label: "Status 3",
  },
  {
    id: "4",
    label: "Status 4",
  },
];

interface FilterTab{
  id: string;
  label: string;
}

interface FilterTabsProps {
  setFilters: (setter: (prevFilters: CrudFilters) => CrudFilters) => void;
  tabFilters: FilterTab[];
}

const FilterTabs: React.FC<FilterTabsProps> = ({tabFilters, setFilters}) => {
  const [value, setValue] = useState(tabFilters[0]?.id ?? "");

  const handleChange = (e: any) => {
    setValue(e.target.value);
    setFilters((data) => {
      const castedData = data as LogicalFilter[];
      const productStatusExists = castedData.findIndex((x) => x.field === "productstatus")

      if (productStatusExists > -1) {
        castedData[productStatusExists].value = e.target.value;
        return castedData
      }

      return [...data, { field: "productstatus", operator: "eq", value: e.target.value }];
    });
  }
  
  return (
    <Flex vertical gap="middle">
      <Radio.Group
        defaultValue={tabFilters[0]?.id ?? ""}
        buttonStyle="solid"
        onChange={handleChange}
        value={value}
      >
        {tabFilters.map((tabFilter) => {
          return (
            <Radio.Button key={tabFilter.id} value={tabFilter.id}>
              {tabFilter.label}
            </Radio.Button>
          );
        })}
      </Radio.Group>
    </Flex>
  )
};

export default FilterTabs;
