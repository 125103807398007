import type { ThemeConfig } from "antd";

export const themeConfig: ThemeConfig = {
    token: {
        colorPrimaryText: "rgba(0, 0, 0, 0.85)",
        colorTextSecondary: "rgba(0, 0, 0, 0.65)",
        colorTextTertiary: "rgba(0, 0, 0, 0.45)",
        colorPrimary: "#195178",
        colorBgContainer: "#F7F8F9",
        colorBgLayout: "#F0F2F5",
        colorBorderBg: "#E8E9EA",
        colorTextDisabled: "rgba(0, 0, 0, 0.5)",
        fontFamily:
            "'HK Grotesk Regular', sans-serif",
    },
    components: {
        Typography: {
            colorText: "rgba(0, 0, 0, 0.85)",
            colorTextDescription: "rgba(0, 0, 0, 0.65)",
            colorTextDisabled: "rgba(0, 0, 0, 0.45)",
        },
        Card: {
            colorBgContainer: "#FFFFFF",
            headerBg: "#FAFAFA",
            boxShadowTertiary:
                "0 20px 27px rgb(0 0 0/5%);",
        },
        Button: {
            borderRadius: 4,
        },
        Table: {
            colorBgContainer: "#fff",
            headerBg: "#fff",
            headerColor: "#8c8c8c",
            headerSplitColor: "#fff",
            cellPaddingInline: 15,
            cellPaddingBlock: 20,
            footerBg: "#fff",
            rowSelectedBg: "#19517830",
            rowSelectedHoverBg: "#19517820"
        },
        Input: {
            colorBgContainer: "#fff",
        },
        InputNumber: {
            colorBgContainer: "#fff",
        },
        Calendar: {
            colorBgContainer: "#FFFFFF",
        },
        Radio: {
            colorBgContainer: "#fff",
        },
        Select: {
            colorBgContainer: "#fff",
        },
        Menu: {
            colorItemBgSelected: "#306286",
            colorItemTextSelected: "white",
            colorItemTextHover: "white",
            colorItemBgHover: "#306286",
            colorTextLabel: "white",
            colorText: "#dadbe4",
            // colorBgContainer: "#black",
            // colorItemBgSelected: "#195178",
            // colorItemTextSelected: "#195178",
            // colorItemBgHover: "#195178",
            // colorTextLabel: "rgba(0, 0, 0, 0.65)",
        }
    },
};