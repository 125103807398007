import { Button, Flex, Modal } from "antd";
import styled from "@emotion/styled";
import { SaveOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

const ModalContent = styled.div`
  .ant-table-cell {
    padding: 20px 0px !important;
  }
  td .ant-table-cell {
    cursor: pointer;
  }

  .ant-table-footer {
    margin-left: 0px !important;
    padding-left: 0px !important;
    margin-right: 0px !important;
    padding-right: 0px !important;
  }
`;

interface TableModalProps {
  initialValue?: string;
  title?: string;
  isOpen: boolean;
  onSave: () => void;
  onClose: () => void;
  onChange?: (value: any) => void;
}

const TableModal: React.FC<TableModalProps> = ({
  initialValue,
  title,
  onSave,
  onClose,
  isOpen,
  onChange,
}) => {

  const handleOnSave = () => {
    onSave();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange && onChange(e.target.value);
  };

  return (
    <Modal
      title={title}
      open={isOpen}
      okButtonProps={{ hidden: true }}
      okText="Spara"
      okType="primary"
      footer={null}
      onCancel={onClose}
    >
      <ModalContent>
        <TextArea
          rows={30}
          spellCheck="true"
          defaultValue={initialValue}
          onChange={handleOnChange}
        />
        <Flex
          justify="flex-end"
          gap={"0.5rem"}
          style={{ marginTop: "1rem", paddingRight: "0px" }}
        >
          <Button type="primary" icon={<SaveOutlined />} onClick={handleOnSave}>
            Spara
          </Button>
        </Flex>
      </ModalContent>
    </Modal>
  );
};

export default TableModal;
