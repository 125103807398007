import { useModal } from "@refinedev/antd";
import ModalMediaCard from "components/cards/modalMediaCard"; // Not grid specific, be careful changing this comp
import { RelationModalGrid } from "components/modals/relationModalGrid";
import { IBase } from "interfaces";
import { RelationType } from "utility/helper";

interface RelationModalGridProps<T extends IBase> {
  editorProps: any;
  relationType: RelationType;
  selectedItems: T[];
  title?: string;
}

function RelationModalGridEdit<T extends IBase,>({
  editorProps,
  relationType,
  selectedItems,
  title
}: RelationModalGridProps<T>) {
  const { modalProps: editModalProps } = useModal();

  const onClose = () => {
    editorProps.cell.stopEdit();
  };

  const onSave = async (selectedItems: T[]) => {
    await editorProps.onChange(selectedItems);
    editorProps.onComplete();
  };

  return (
    <RelationModalGrid<T>
      modalProps={{ ...editModalProps, open: editorProps.cell.isInEdit() }}
      cardComp={ModalMediaCard} // Not data grid specific, be careful changing this comp
      onFinish={onSave}
      onCancel={onClose}
      initSelectedItems={selectedItems}
      type={relationType}
      title={title ?? ""}
    />
  );
}

export default RelationModalGridEdit;