import { useModal } from "@refinedev/core";
import { Modal } from "antd";

interface LockedMenuOverlayProps {
  isModalVisible: boolean;
  confirmUnlock: () => void;
  cancelUnlock: () => void;
}

const LockedSwitchModal = ({isModalVisible, confirmUnlock, cancelUnlock}: LockedMenuOverlayProps) => {

  return (
    <Modal
      title="Radmarkering är låst."
      open={isModalVisible}
      onOk={confirmUnlock}
      onCancel={cancelUnlock}
      okText="Ok"
      cancelText="Avbryt"
    >
      <p>
        Du har har osparade ändringar i din tabell data.
        Föra att att aktivera radmarkeringen måste du först spara dina ändringar.
      </p>
    </Modal>
  );
};

export default LockedSwitchModal;
