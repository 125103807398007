import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Modal, Select, Space, Upload, Typography } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useApiUrl } from '@refinedev/core';
import { getUserInfoFromCache } from 'utility/helper';

const { Text } = Typography;

import styled from '@emotion/styled'

const SelectFileContainer = styled('div')`
    display: flex;
    gap: 0.5rem;
`

const ImportFileModal: React.FC<any> = ({ modalProps, onFinish }) => {
  const [file, setFile] = useState<UploadFile | undefined>(undefined);
  const [uploading, setUploading] = useState(false);
  const [fileType, setFileType] = useState(null);
  const [blobArray, setBlobArray] = useState<Blob[]>([]);
  const apiUrl = useApiUrl();
  
  const importOptionTypes = [{value: 'AhlsellTechnicalCsv', label: 'Ahlsell teknisk mapping'},{value: 'InriverMigrationCsv', label: 'InRiver migrationsfil'}]

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('file', file as RcFile);
    setUploading(true);
    const userInfo = getUserInfoFromCache();

    fetch(`${apiUrl}/import/file-upload?filetype=${fileType}`, {
      method: 'POST',
      body: formData,
      headers: { 'Authorization': `Bearer ${userInfo.token}` },
    })
      .then((res) => res.json())
      .then(() => {
        setFile(undefined);
        message.success('Uppladdning klar. Bakgrundsjobb startat.');
      })
      .catch(() => {
        message.error('Uppladdningen misslyckades.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const chunkUpload = async () => {
    setUploading(true);
    message.info('Filen är för stor. Påbörjar uppladdning i delar.');
    for (let i = 0; i < blobArray.length; i++) {
      const formData = new FormData();
      formData.append('chunkIndex', i.toString());
      formData.append('totalChunks', blobArray.length.toString());
      formData.append('file', blobArray[i] as RcFile);
      const userInfo = getUserInfoFromCache();

      const res = await fetch(`${apiUrl}/import/chunk-file-upload?filetype=${fileType}`, {
        method: 'POST',
        body: formData,
        headers: { 'Authorization': `Bearer ${userInfo.token}` },
      });

      if(res.status === 200 && i === blobArray.length - 1) {
        message.success('Uppladdning klar. Bakgrundsjobb startat.');
      }else if (res.status === 200) {
        message.success(`Laddar upp del ${i+1} av ${blobArray.length}.`);
      }else{
        message.error('Uppladdningen misslyckades.');
      }

    }
    setFile(undefined);
    setBlobArray([]);
    setUploading(false);
  };

  const props: UploadProps = {
    onRemove: (file) => {
      setFile(file);
    },
    beforeUpload: (file) => {
      const isLt5M = file.size / 1024 / 1024 < 5;

      if(!isLt5M){
        const chunkSize = 1024 * 1024 * 7; // 7MB
        const chunks = Math.ceil(file.size / chunkSize);
        const chunkArray = [];
        for (let i = 0; i < chunks; i++) {
          const start = i * chunkSize;
          const end = Math.min(start + chunkSize, file.size);
          const chunk = file.slice(start, end);
          chunkArray.push(chunk);
        }
        setBlobArray(chunkArray);
      }

      setFile(file);
      return false;
    },
    maxCount: 1,
    accept: '.csv',
    fileList: file ? [file] : [],
  };

  return (
    <Modal {...modalProps} width={900} onOk={onFinish}>
      <Space direction="vertical">
        <SelectFileContainer>
          <Upload {...props}>
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
          <div>
            <Select
              placeholder="Välj import mall"
              style={{ width: 200 }}
              options={importOptionTypes}
              onChange={(value) => setFileType(value)}
            />
          </div>
        </SelectFileContainer>
        <Text strong>
          Uppladdning av fil kan skriva över produkter och dess data.
        </Text>
        {fileType === "AhlsellTechnicalCsv" && (
          <>
            <Text strong>
              Det är viktigt att kolumnerna ligger i rätt ordning enligt nedan:
            </Text>
            <Text strong>
              {"<ID>,<Name>,Vivaldi Itemnumber,Parent_Product,Brands Classification,Long Product Description,Short Product Description"}
            </Text>
          </>
        )}
        <Button
          type="primary"
          onClick={blobArray?.length > 0 ? chunkUpload : handleUpload}
          disabled={!file || !fileType}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? "Laddar upp..." : "Starta uppladdning"}
        </Button>
      </Space>
    </Modal>
  );
};

export default ImportFileModal;
