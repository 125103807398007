import FullScreenLoader from "components/common/loadIndicators/fullScreenLoader";
import useDataGridStore from "stores/dataGridStore";

const SaveTableDataLoader = () => {
  const { groupId, updateDataSourceIsLoading } = useDataGridStore();
  return (
    <FullScreenLoader loading={updateDataSourceIsLoading} message={`Sparar tabelldata för produktgrupp: ${groupId}`}/>
  );
}

export default SaveTableDataLoader;