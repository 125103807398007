import { useApiUrl, useCustomMutation, useNavigation, useIsAuthenticated } from '@refinedev/core';
import { useEffect } from 'react';
import { decodeTokenAndSave, getUserInfoFromCache } from 'utility/helper';

const useAuthCheck = () => {
  const userInfo = getUserInfoFromCache();
  const { push } = useNavigation();
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();

  useEffect(() => {
    const interval = setInterval(() => {
      const expireDiff = checkExpiry(userInfo.exp);

      if(hasExpired(expireDiff)){
        push("/login");
      }
      
      if(shouldBeRefreshed(expireDiff)){
          mutate({
            url: `${apiUrl}/check-token`,
            method: "put",
            values: {
                token: userInfo.token
            },
            config: {
              headers: { Authorization: `Bearer ${userInfo.token}`}
            },
          },
          {
            onError: (error, variables, context) => {
              console.error("Failed to refresh token");
              console.error(error);
              push("/login");
            },
            onSuccess: (data, variables, context) => {
              decodeTokenAndSave(data);
            },
          });
      }
    }, 30000);

    return () => {
      clearInterval(interval);
    };
  }, []);
};

const checkExpiry = (expiryTimestamp: number) => {
  const currentTimestamp = Math.floor(Date.now() / 1000);
  const timeDifference = expiryTimestamp - currentTimestamp;
  return timeDifference;
};

const hasExpired = (expiryDiff: number) => {
    return expiryDiff <= 0;     
}

const shouldBeRefreshed = (expiryDiff: number) => {
    return expiryDiff <= 600;     
}

export default useAuthCheck;
