import React, { useState } from "react";
import {
  Card,
  Menu,
  Dropdown,
  Typography,
  Button,
  Image,
  Flex,
} from "antd";
import {
  DeleteOutlined,
  EllipsisOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import {
  BaseKey,
} from "@refinedev/core";
import PdfIcon from "../../assets/pdf-icon.png";
import NoImage from "../../assets/no-image.png";
import { RelationType } from "utility/helper";
import { getUserInfoFromCache } from "utility/helper";
import { Text as TextCustom } from "../common/text";

const { Text } = Typography;

const RelationCardWrapper = styled(Card.Grid)`
  &:hover {
    cursor: pointer;
  }
  position: relative;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0,
    1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  padding: 16px 8px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .delete-marker {
    position: absolute;
    top: 6px;
    right: 6px;
  }
  .delete-marker:hover {
    opacity: 0.7;
   }

  .card-content {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .card-action {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  img {
    object-fit: contain;
    width: 50px !important;
    height: 50px !important;
  }

  .ant-typography {
    font-weight: 500;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    padding: 0px 8px;
  }

  .ant-btn-icon {
    transform: rotate(90deg);
    font-weight: 500;
  }
`;

interface RelationCardGridProps {
  item: any;
  type: RelationType;
  onDelete: (id: BaseKey) => void;
}

const RelationCardGrid: React.FC<RelationCardGridProps> = ({
  item,
  type,
  onDelete,
}) => {

  const isProductRelation =
    type == RelationType.SpareParts ||
    type == RelationType.RelatedParts ||
    type == RelationType.Accessories;

  const id: number = item.id;
  const mainItemNo: string = item?.mainItemNo;
  const name: string = item.name;
  const extension: string = item.extension;
  const url: string = isProductRelation
    ? item.images?.length > 0
      ? item?.images[0]?.previewUrl
      : NoImage
    : item.previewUrl ?? item.url;

  function openInNewTab(url: string) {
    const win = window.open(url, "_blank");
    win?.focus();
  }

  const menu = (
    <Menu>
      <Menu.Item
        key="open-new-tab"
        onClick={() => openInNewTab(url)}
        icon={<SelectOutlined></SelectOutlined>}
      >
        Öppna i ny tabb
      </Menu.Item>
    </Menu>
  );

  return (
    <RelationCardWrapper>
      <div className="card-content">
        <Image
          src={extension == ".pdf" ? PdfIcon : url}
          alt={name}
          preview={true}
          fallback={NoImage}
        />
        <Flex vertical>
          <Text title={name}>{name}</Text>
          {isProductRelation && (
            <TextCustom
              title={name}
              style={{ color: "#515151", fontSize: "11px", margin: "0px" }}
            >
              Artnr: {mainItemNo}
            </TextCustom>
          )}
        </Flex>
      </div>
      <DeleteOutlined
        onClick={() => onDelete(id)}
        className="delete-marker"
      ></DeleteOutlined>
      <div className="card-action">
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="text" size="small" icon={<EllipsisOutlined />} />
        </Dropdown>
      </div>
    </RelationCardWrapper>
  );
};

export default RelationCardGrid;
