import React, { useState } from 'react';
import { Card, Menu, Dropdown, Typography, Button, MenuProps, Divider, Checkbox, Image, Space, Flex } from 'antd';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, ExportOutlined, SelectOutlined } from '@ant-design/icons';
import styled from '@emotion/styled'
import { IFile } from 'interfaces';
import { BaseKey, useApiUrl, useCustomMutation, useDelete } from '@refinedev/core';
import PdfIcon from '../../assets/pdf-icon.png';
import NoImage from '../../assets/no-image.png';
import { RelationType } from 'utility/helper';
import { getUserInfoFromCache } from 'utility/helper';
import useRelation from 'components/hooks/relationHook';
import { Text as TextCustom } from "../../components/common/text";
const { Text } = Typography;

const RelationCardWrapper = styled(Card.Grid)`
    &:hover{
        cursor: pointer;
    }
    position: relative;
    box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
    padding: 16px 8px !important;
    width: 90% !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .select-marker {
        position: absolute;
        top: 0;
        right: 2px;
    }

    .card-content {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    .card-action {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    img {
        object-fit: contain;
        width: 50px !important;
        height: 50px !important;
    }

    .ant-typography {
        font-weight: 500;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        padding: 0px 8px;
    }

    .ant-btn-icon{
        transform: rotate(90deg);
        font-weight: 500;
    }
`

const MenuDivider = styled(Divider)`
    margin: 4px 0px !important;
`

interface RelationCardProps {
  item: any;
  type: RelationType;
  productItemId: BaseKey;
  refetchRelationEdit?: () => void;
}

const RelationCard: React.FC<RelationCardProps> = ({ productItemId, item, type, refetchRelationEdit }) => {
  const [isSelected, setIsSelected] = useState(false);
  const apiUrl = useApiUrl();
  const userInfo = getUserInfoFromCache();

  const { mutate: deleteRelationMutation } = useCustomMutation<any>();
  
  const isProductRelation = type == RelationType.SpareParts || type == RelationType.RelatedParts || type == RelationType.Accessories;

  const id : BaseKey = item.id;
  const mainItemNo: string = item?.mainItemNo
  const name : string = item.name;
  const extension : string = item.extension;
  const url : string = isProductRelation ? (item.images?.length > 0 ? item?.images[0]?.previewUrl : NoImage) : (item.previewUrl ?? item.url);

  function openInNewTab(url : string) {
    const win = window.open(url, '_blank');
    win?.focus();
  }

  const onDelete = () => {
    deleteRelationMutation({
      url: `${apiUrl}/relations/${productItemId}`,
      method: "delete",
      // successNotification: (data, values) => {
      //   return {
      //     message: `Relationen raderades.`,
      //     description: "Success with no errors",
      //     type: "success",
      //   };
      // },
      values: {
        destId: id,
        relationType: type,
      },
      config: {
        headers: { Authorization: `Bearer ${userInfo.token}`},
      },
    },
    {
      onError: (error, variables, context) => {
        refetchRelationEdit && refetchRelationEdit();
      },
      onSuccess: (data, variables, context) => {
        refetchRelationEdit && refetchRelationEdit();
      },
    })
  }

  const menu = (
    <Menu>
      <Menu.Item
        key="edit"
        onClick={() => console.log(id)}
        icon={<EditOutlined></EditOutlined>}
      >
        Redigera
      </Menu.Item>
      <Menu.Item
        key="open-new-tab"
        onClick={() => openInNewTab(url)}
        icon={<SelectOutlined></SelectOutlined>}
      >
        Öppna i ny tabb
      </Menu.Item>
      <MenuDivider></MenuDivider>
      <Menu.Item
        key="remove-relation"
        onClick={onDelete}
        icon={<DeleteOutlined></DeleteOutlined>}
      >
        Ta bort relation
      </Menu.Item>
    </Menu>
  );

  return (
    <RelationCardWrapper>
      <div className="card-content">
        {/* <img src={extension == ".pdf" ? PdfIcon : url} alt={name} /> */}
        <Image src={extension == ".pdf" ? PdfIcon : url} alt={name} preview={true} fallback={NoImage}/>
        <Flex vertical>
          <Text title={name}>{name}</Text>
          {isProductRelation && <TextCustom title={name} style={{color: "#515151", fontSize: "11px", margin: "0px"}}>Artnr: {mainItemNo}</TextCustom> }
        </Flex>
      </div>
      <Checkbox
        checked={isSelected}
        onChange={(e) => setIsSelected(e.target.checked)}
        className="select-marker"
      ></Checkbox>
      <div className="card-action">
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="text" size="small" icon={<EllipsisOutlined />} />
        </Dropdown>
      </div>
    </RelationCardWrapper>
  );
};

export default RelationCard;
