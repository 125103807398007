import { useState } from 'react';// Replace with your API utility function
import useHttpClient from './useHttpClient';
import { BaseKey } from '@refinedev/core';
import { ProductStatus } from "interfaces";
import { notification } from 'antd';

const useUpdateProductStatus = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const {makeHttpRequest} = useHttpClient();

    const updateProductStatus = async (
        productIds: BaseKey[],
        status: ProductStatus
    ): Promise<void> => {
        setIsLoading(true);
        setError(null);

        try {
            for (const id of productIds) {
                const response = await makeHttpRequest("PATCH", `/products/${id}`, {productStatus: status});
                if (!response.id) {
                    throw new Error(response.message);
                }
            }
            setIsLoading(false);
            notification.success({
                message: "Success",
                description: "Product status updated successfully"
            });
        } catch (error : any) {
            setIsLoading(false);
            notification.error({
                message: "Error",
                description: error.message || 'Something went wrong'
            });
            throw error;
        }
    };

    return { isLoading, error, updateProductStatus };
};

export default useUpdateProductStatus;