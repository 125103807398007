import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Modal, Select, Space, Upload, Typography, Progress } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useApiUrl } from '@refinedev/core';
import { getUserInfoFromCache } from 'utility/helper';

const { Text } = Typography;

import styled from '@emotion/styled'
import axios from 'axios';

const UploadContainer = styled("div")`
  .ant-upload-list {
    max-height: 400px;
    overflow-y: auto;
  }
`;

const UploadMediaModal: React.FC<any> = ({ modalProps, onFinish, itemId }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [progress, setProgress] = useState(0);
  // const [uploading, setUploading] = useState(false);
  // const [fileType, setFileType] = useState(null);

  const apiUrl = useApiUrl();

  const uploadImage = async (options : any) => {
    const { onSuccess, onError, file, onProgress } = options;
    const userInfo = getUserInfoFromCache();
    const fmData = new FormData();

    const config = {
      headers: { Authorization: `Bearer ${userInfo.token}` },
      onUploadProgress: (event : any) => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        setProgress(percent);
        if (percent === 100) {
          setTimeout(() => setProgress(0), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };

    fmData.append("file", file);
    fmData.append('originId', itemId);

    try {
      const res = await axios.post(
        `${apiUrl}/files`,
        fmData,
        config
      );
        // console.log("Media upload succeeded: ", res);
        onSuccess("Ok");
    } catch (err) {
      // console.log("Error media upload: ", err);
      onError({ err });
    }
  };

  return (
    <Modal {...modalProps} width={700} onOk={onFinish}>
      <UploadContainer>
        <Upload
          customRequest={uploadImage}
          accept="image/png, image/jpeg, application/pdf"
          listType="picture"
          multiple
          defaultFileList={[...fileList]}
        >
          <Button icon={<UploadOutlined />}>Välj filer</Button>
        </Upload>
      </UploadContainer>
    </Modal>
  );
};

export default UploadMediaModal;
