import { HttpError, IResourceComponentsProps, useOne, useTranslate } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import {
  Divider,
    Tabs,
} from "antd";
import { IProduct, IProductItem } from "interfaces";
import { useLocaleStore } from "stores/localeStore";
import RelationSection from "components/productItems/sections/relationSection";
import useProductTabs from "components/productItems/useProductTabs";
import styled from '@emotion/styled'
import { PRODUCT_ITEM_RES_ID, PRODUCT_RES_ID } from "utility/constants";
import { RelationType } from "utility/helper";
import { use } from "i18next";
import { useEffect } from "react";
import { useProductStore } from "stores/productStore";

const RelationWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`

const CustomDivider = styled(Divider)`
  margin: 1rem 0;
`

export const ProductItemRelationEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const localeState = useLocaleStore();
    const { productItemTabs, onTabChange, activeKey, id } = useProductTabs();
    const { setActiveProduct } = useProductStore();

    const { data, isLoading, isError, refetch } = useOne<IProduct, HttpError>({
      resource: PRODUCT_RES_ID,
      id,
      meta: { headers: { "lang": localeState.locale, "includes": "MediaAssets" } },
    });

    useEffect(() => {
      setActiveProduct(data?.data as IProduct)
    }, [data?.data])

    return (
      <>
      <Tabs activeKey={activeKey} type="card" items={productItemTabs} onChange={onTabChange}/>
        <RelationWrapper>
          <RelationSection id={id} title="Produktbilder" type={RelationType.ProductImage} refetchRelationEdit={refetch}></RelationSection>
          <CustomDivider></CustomDivider>
          <RelationSection id={id} title="Manualer" type={RelationType.Manual} refetchRelationEdit={refetch}></RelationSection>
          <RelationSection id={id} title="Måttskisser" type={RelationType.DimensionalSheet} refetchRelationEdit={refetch}></RelationSection> 
          <RelationSection id={id} title="Säkerhetsblad" type={RelationType.SafetySheet} refetchRelationEdit={refetch}></RelationSection>
          <CustomDivider></CustomDivider>
          {/* <RelationSection id={id} title="Relaterade Produkter" type={RelationType.RelatedParts} refetchRelationEdit={refetch}></RelationSection> */}
          <RelationSection id={id} title="Tillbehör" type={RelationType.Accessories} refetchRelationEdit={refetch}></RelationSection>
          <RelationSection id={id} title="Reservdelar" type={RelationType.SpareParts} refetchRelationEdit={refetch}></RelationSection>
        </RelationWrapper>
      </>
    );
};