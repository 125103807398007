import { HttpError, IResourceComponentsProps, useOne, useUpdate, useTranslate, BaseKey, useList, useCreate, useDelete } from "@refinedev/core";
import {
    Form,
    Input,
    Tabs,
    Card,
    Space,
    Divider,
    Typography,
    Button,
    Flex,
    Checkbox,
    Tooltip,
    Empty,
    Spin
} from "antd";
import { IProduct, IProductVideo, ISpecificationLabel } from "interfaces";
import { useLocaleStore } from "stores/localeStore";
import { getDescriptionFromLocale } from "utility/helper";
import RelationCard from "components/cards/relationCard";
import RelationSection from "components/productItems/sections/relationSection";
import useProductTabs from "components/productItems/useProductTabs";
import { PRODUCT_RES_ID, VIDEO_RES_ID } from "utility/constants";
import styled from '@emotion/styled'
import { CloseCircleOutlined, InfoCircleOutlined, PlayCircleOutlined } from "@ant-design/icons";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { Text } from "components/common/text";
import { useReducer } from "react";
import { DividerLine } from "components/separators/dividerLine";
import { ProductSpecificationSearch } from "../specificationSearch";
import { SectionHeader } from "components/layout/header/sectionHeader";
import debounce from 'lodash/debounce';
import { use } from "i18next";
const { Title } = Typography;

const VideosCard = styled(Card)`
  .form-label {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  .ant-input {
    /* margin: 0.5rem 0; */
  }
`;

interface EditProductVideoSpecificationsProps {
  productId: BaseKey;
}

interface IExtendedProductVideo extends IProductVideo {
  modified: boolean;
}

export const EditProductVideoSpecifications: React.FC<EditProductVideoSpecificationsProps> = ({productId}) => {
  const localeState = useLocaleStore();
  const [videos, setVideos] = useState<IExtendedProductVideo[]>([]);

  const { data, isLoading: isLoadingProduct } = useList<IExtendedProductVideo, HttpError>({
    resource: VIDEO_RES_ID,
    meta: { headers: { "lang": localeState.locale, "productId": productId } },
  });

  useEffect(() => {
    setVideos(data?.data || []);
  }, [data])

  const { mutate: updateMutation, isError: updateIsError, isLoading: isLoadingUpdate } = useUpdate();
  const { mutate: createMutation, isError: isCreateError, isLoading: isLoadingCreate } = useCreate();
  const { mutate: deleteMutation, isError: isDeleteError, isLoading: isLoadingDelete } = useDelete();

  const deleteVideo = (id: number) => {
    deleteMutation({
      resource: VIDEO_RES_ID,
      id: id,
      meta: { headers: { "lang": localeState.locale } },
    });
  }

  const handleAddNewVideo = (newVideo: IProductVideo) => {
    // if (!newVideo?.youtubeKey) {
    //   return;
    // }
    createMutation({
      resource: VIDEO_RES_ID,
      meta: { headers: { "lang": localeState.locale } },
      values: {
        productId: productId,
        labelId: newVideo.labelId,
        youtubeKey: newVideo.youtubeKey
      },
    });
  }

  const handleVideoUpdate = (id: number, newValue: string) => {
    const newVideos = videos.map((video) => {
      if (video.id === id) {
        return {
          ...video,
          youtubeKey: newValue,
          modified: true
        }
      }
      return video;
    });
    setVideos(newVideos);
  };

  const handleSaveVideos = () => {
    const modifiedVideos = videos.filter((video) => video.modified);
    modifiedVideos.forEach((video) => {
      updateMutation({
        resource: VIDEO_RES_ID,
        id: video.id,
        values: {
          productId: productId,
          youtubeKey: video.youtubeKey
        },
        meta: { headers: { "lang": localeState.locale } },
      });
    });
  }

  const hasChanges = videos.some((video) => video.modified);

  if(isLoadingProduct){
    return <div style={{textAlign: 'center'}}><Spin size="large"></Spin></div>
  }

  return (
    <>
      <VideosCard>
        <SectionHeader
          title="Filmer youtube"
          icon={
            <PlayCircleOutlined style={{ fontSize: "22px", color: "red" }} />
          }
        />
        <Flex vertical gap={10}>
          {!data?.data || data?.data?.length === 0 ? (
            // <Empty description="Inga videos tillagda" />
            <Flex style={{ justifyContent: "center", width: "100%" }}>
              Inga videos tillagda
            </Flex>
          ) : (
            data?.data?.map((video, index) => {
              const { id, name, labelId, youtubeKey } = video;
              return (
                <Flex vertical key={`${id}-${index}`} style={{}}>
                  <Flex>
                    <Flex
                      style={{ justifyContent: "space-between", width: "100%" }}
                    >
                      <div>
                        {/* <Text>ID: {id} LABEL_ID: {labelId}</Text> {' '} */}
                        <Typography.Text className="form-label">
                          {!name ? (
                            <span style={{ color: "silver" }}>
                              *Ej namngiven
                            </span>
                          ) : (
                            name
                          )}
                        </Typography.Text>
                      </div>
                      <Flex style={{ alignItems: "center" }}>
                        <CloseCircleOutlined
                          style={{ fontSize: "20px", marginLeft: "0.5rem" }}
                          onClick={() => deleteVideo(id)}
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex wrap="wrap">
                    <VideoInput video={video} onUpdate={handleVideoUpdate} />
                  </Flex>
                </Flex>
              );
            })
          )}
          <Button onClick={handleSaveVideos} type="primary" style={{width: "100%"}} disabled={!hasChanges} loading={isLoadingUpdate}>
            Spara Videos
          </Button>
        </Flex>

        <DividerLine lineIntensity={"strong"} />
        <VideosForm handleAddNewVideo={handleAddNewVideo} videos={data?.data} />
      </VideosCard>
    </>
  );
};


interface IVideoInput {
  video: IProductVideo;
  onUpdate: (id: number, newValue: string) => void;
}

const VideoInput: React.FC<IVideoInput> = ({ video, onUpdate }) => {
  const [value, setValue] = useState(video.youtubeKey);
  
  useEffect(() => {
    setValue(video.youtubeKey);
  }, [video.youtubeKey]);

  const handleChange = (value : string ) => {
    setValue(value);
    onUpdate(video.id, value);
  };

  const validateInput = (video: IProductVideo): React.ReactNode => {
    const { youtubeKey } = video;
    if (!youtubeKey || youtubeKey.trim().length === 0) {
      return (
        <Tooltip title="Fältet är tomt">
          <InfoCircleOutlined style={{ color: 'rgba(244, 6, 6, 0.45)' }} />
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <Input
      style={{ margin: "0.5rem 0" }}
      suffix={validateInput(video)}
      addonAfter="key"
      type="text"
      value={value}
      onChange={(e) => handleChange(e.target.value)}
    />
  );
};

interface VideoAddFormProps {
  handleAddNewVideo: (value: IProductVideo) => void;
  videos?: IProductVideo[]
}

const VideosForm: React.FC<VideoAddFormProps> = ({handleAddNewVideo, videos}) => {
  const [form] = Form.useForm();

  const handleAddNewSearchVideo = (label: ISpecificationLabel) => {
    // Create new video object
    const newVideo: IProductVideo ={
      id: null,
      labelId: label?.id,
      name: label?.name,
      productId: 0,
      youtubeKey: ""
    }
    
    // Add video directly
    handleAddNewVideo(newVideo)
  };


  return (
    <Form
      layout="vertical"
      form={form}
      name="control-hooks"
    >
      <Flex gap='small' align="center">
        <Title level={5} style={{marginBottom: "0px"}} >Lägg till etikett</Title>                          
        <Tooltip title="Klicka på önskvärd etikett i sökresultatets lista för att lägga till etikett.">
          <InfoCircleOutlined style={{ color: "black", fontSize: "19px" }} />
        </Tooltip>
      </Flex>
     <Form.Item name="label" style={{margin: "0.5rem 0"}} rules={[{ required: true }]}>
        <ProductSpecificationSearch handleAddNewSearchSpecification={handleAddNewSearchVideo}/>
    </Form.Item>
     {/* <Form.Item name="label" style={{margin: "0.5rem 0"}} rules={[{ required: true }]}>
      <Input placeholder="Specifikationsnamn" style={{margin: "0.5rem 0"}}/>
    </Form.Item> */}
      
    </Form>
  );
};
