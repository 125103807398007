import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Select, Space } from 'antd';
import { useGetLocale, useSetLocale } from "@refinedev/core";
import { useTranslation } from 'react-i18next';
import ReactCountryFlag from 'react-country-flag';

export const LangDropdown: React.FC = () => {
  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const currentLocale = locale();
  const changeLanguage = useSetLocale();

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {[...(i18n.languages || [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <ReactCountryFlag
                countryCode={lang == "sv" ? "SE" : "GB"}
                svg
                style={{
                  width: "1.3em",
                  height: "1.3em",
                  marginBottom: "2px",
                }}
              />
            </span>
          }
        >
          {lang === "en" ? "Engelska" : "Svenska"}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <a>
        <Space>
          <ReactCountryFlag
            countryCode={currentLocale == 'sv' ? 'SE' : 'GB'}
            svg
            style={{
              width: "1.3em",
              height: "1.3em",
              marginBottom: "2px",
            }}
          />
          {currentLocale === "en" ? "Engelska" : "Svenska"}
          <DownOutlined />
        </Space>
      </a>
    </Dropdown>
  );
};