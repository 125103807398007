import { LiveEvent } from "@refinedev/core";
import { ILiveContext } from "@refinedev/core/dist/interfaces";
import { addHubListener, startConnection, stopConnection } from "./signalRService";

export const liveProvider = () : ILiveContext => {
    return {
      subscribe: ({ channel, types, params, callback }) => {

        // if(channel == "resources/productItems"){
        //     console.log("Running method for subscribing to channel", channel);
        //     console.log("Types:", types);
        //     console.log("Params:", params);
        // }
        
        startConnection();

        addHubListener("DashboardReceiver", (eventMessage : LiveEvent) => {
            console.log("Received message:", eventMessage);
            callback(eventMessage);
        });

      },

      unsubscribe: (subscription : any) => {
        // console.log("Running method for unsubscribing from channel", subscription);
        stopConnection();
      },

      publish: (event: LiveEvent) => {
      },
    };
  };
  
  