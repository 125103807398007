import { BaseKey } from "@refinedev/core";
import SearchableSelect from "components/common/select/SearchableSelect";
import { IProductGroup } from "interfaces";
import useDataGridStore from "stores/dataGridStore";
import useMetadataStore from "stores/metadataStore";

interface SelectGroupProps {
  isDisabled?: boolean | undefined;
  isOpen?: boolean | undefined;
}
const SelectGroup = ({isDisabled, isOpen}: SelectGroupProps) => {
  const { isloading: metaDataIsLoading, metadata } = useMetadataStore();
  const { productGroups = [] } = metadata ?? {};

  const { setGroupId, groupId } = useDataGridStore();
  const handleGroupIdChange = (groupIdValue: BaseKey) => {
    if (!groupIdValue || groupIdValue == groupId) return;
    setGroupId(groupIdValue);
  };
  
  const groupOptions = productGroups?.map((brand: IProductGroup) => ({
    value: brand?.id,
    label: `${brand?.name} (${brand?.number})`,
  }));

  return (
    <SearchableSelect
      options={groupOptions}
      onChange={handleGroupIdChange}
      placeholder={"Hämta varugrupp"}
      isLoading={metaDataIsLoading}
      isTableCell={false}
      widthRem={18}
      isDisabled={isDisabled}
      isOpen={isOpen}
      value={groupId}
    ></SearchableSelect>
  );
};

export default SelectGroup;
