import { BaseKey } from "@refinedev/core";
import { TabsProps } from "antd";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const useProductTabs = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const { id } = useParams<{ id: string }>();

    const productItemTabs: TabsProps['items'] = [
        {
          key: `/products/edit/${id}`,
          label: 'Överblick'
        },
        {
          key: `/products/edit/specification/${id}`,
          label: 'Specifikationer'
        },
        {
          key: `/products/edit/media/${id}`,
          label: 'Filhantering'
        },
        {
          key: `/products/edit/relations/${id}`,
          label: 'Relationer'
        }
    ];

    const onTabChange = (key: string) => {
        navigate(key);
    };
  
    return {
        productItemTabs,
        onTabChange,
        activeKey: pathname,
        id : id as BaseKey
    };
  };
  
  export default useProductTabs;
  