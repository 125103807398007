import { Row, Col, Card, Avatar, Typography, Space, Statistic } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import ChangeLogSection from "components/common/ChangeLogSection";

const { Text, Title } = Typography;

export const ChangeLogPage: React.FC = () => {

    return (
      <Card bordered={false} className="criclebox h-full">
        <Row>
          <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mobile-24">
            <div className="h-full col-content p-20">
              <div className="ant-muse">
                <Title level={4}>Changelog</Title>
                <Paragraph className="mb-36">
                  Nedan hittar du en lista över de senaste uppdateringarna som
                  gjorts i systemet.
                </Paragraph>
              </div>
            </div>
            <ChangeLogSection
              title="Statusfiltrering - 2024-04-30"
              changes={[
                "Produkter kan nu filtreas på status i produktlistan mellan aktiva och arkiverade produkter.",
                "Namn på varianter kopplade till en produkt kan nu ändras direkt på produktsidan.",
                "Redan kopplade varianter kan kopplas loss från icke publicerade produkter.",
                "Produkter kan placeras i olika statusar genom att använda multi-redigeraren i produktlistan.",
                "Bemämning 2 från Kassanova tillagd på produktsidan.",
              ]}
            ></ChangeLogSection>
            <ChangeLogSection
              title="Ny filhanterare - 2024-04-24"
              changes={[
                "Uppladdning av en eller flera bilder via webbläsarens inbyggda filhanterare eller drag and drop.",
                "Finns i två lägen strikt och vanligt läge, checkboxen avgör vilket läge den befinner sig i. I strikt läge kan endast bilder med korrekta filnamn ladddas upp, i vanligt läge kan alla filer laddas upp. Automatisk matchning mot produkt sker endast i striktläge och funkar på både Artnr och Vivaldi-nummer.",
                "Statusvyn indikerar status för respektive fil som laddas upp, och där det finns en maxstorlek på 7MB per fil. Vill man rensa Statusvyn kan man göra det genom att klicka på sopborsten uppe i högra hörnet.",
                "En ladda ner funktion har tillkommit på alla media-kort där man kan ladda ner respektive fil till sin dator.",
              ]}
            ></ChangeLogSection>
          </Col>
          <Col
            xs={24}
            md={12}
            sm={24}
            lg={12}
            xl={10}
            className="col-img"
          ></Col>
        </Row>
      </Card>
    );
};
