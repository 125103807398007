import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
} from "react-grid-dnd";
import { useMediaQuery } from "@react-hook/media-query";
import { RelationType } from "utility/helper";
import RelationCardGrid from "components/cards/relationCardGrid";
import ExpandableContainer from "components/common/expandableContainer";
import { IBase } from "interfaces";
import { BaseKey } from "@refinedev/core";

const CardWrapper = styled("div")`
  .card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 56px;
    padding: 0px 24px;
    background: #fafafa;
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #f0f0f0;
  }

  .card-head .card-title {
    font-weight: 500;
    font-size: 16px;
  }

  .card-action .ant-btn-icon {
    transform: rotate(90deg);
  }

  .card-body {
    padding-bottom: 2.5rem;
  }

  .grid-item {
    padding: 0.5rem;
  }

  .grid-item:last-child{
    padding-bottom: 100px;
  }
`;

interface SelectedRelationSectionGridProps<T extends IBase> {
  type: RelationType;
  title: string;
  items: T[];
  onDelete: (id: BaseKey) => void;
  onOrderChange: (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number
  ) => void;
}

const SelectedRelationsSectionGrid = <T extends IBase,>({ 
  type,
  items,
  title,
  onDelete,
  onOrderChange,
 }: SelectedRelationSectionGridProps<T>) =>  {
  const [cardHeight, setCardHeight] = useState<number>(82);
  const cardRef = useRef<HTMLDivElement>(null);
  const isMobile = useMediaQuery("(max-width: 800px)");
  const isTablet = useMediaQuery("(min-width: 800px) and (max-width: 1400px)");
  const isDesktop = useMediaQuery("(min-width: 1400px)");
  const boxesPerRow = isMobile ? 2 : isTablet ? 3 : isDesktop ? 4 : 1;
  // const itemsCount = items?.length ?? 0;
  // const containerHeight = Math.ceil(itemsCount / boxesPerRow) * (cardHeight + 8);

  return (
    <ExpandableContainer isDisabled={!Array.isArray(items) || items.length === 0}>
      <CardWrapper>
        <div className="card-body">
          <GridContextProvider onChange={onOrderChange}>
            <GridDropZone
              id="items"
              boxesPerRow={boxesPerRow}
              rowHeight={cardHeight + 8}
              style={{ height: `${cardHeight}px` }}
            >
              {items?.map((item, i) => {
                if (i == 0) {
                  return (
                    <GridItem key={item?.id} className="grid-item">
                      <div ref={cardRef}>
                        <RelationCardGrid
                          key={i}
                          item={item}
                          type={type}
                          onDelete={onDelete}
                        ></RelationCardGrid>
                      </div>
                    </GridItem>
                  );
                }
                return (
                  <GridItem key={item?.id} className="grid-item">
                    <RelationCardGrid
                      key={i}
                      item={item}
                      type={type}
                      onDelete={onDelete}
                    ></RelationCardGrid>
                  </GridItem>
                );
              }) ?? []}
            </GridDropZone>
          </GridContextProvider>
        </div>
      </CardWrapper>
    </ExpandableContainer>
  );
};
export default SelectedRelationsSectionGrid;