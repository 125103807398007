import { CrudFilters } from "@refinedev/core";
import { mapOperator } from "./mapOperator";

export const generateFilter = (filters?: CrudFilters) => {
  const queryFilters: { [key: string]: string } = {};

  if (filters && filters !== undefined && filters.length > 0) {
    queryFilters[`filters`] = "";
    filters.map((filter, index) => {
      if (filter.operator === "or" || filter.operator === "and") {
        throw new Error(
          `[@refinedev/simple-rest]: \`operator: ${filter.operator}\` is not supported. You can create custom data provider. https://refine.dev/docs/api-reference/core/providers/data-provider/#creating-a-data-provider`
        );
      }

      if ("field" in filter) {
        const { field, operator, value } = filter;

        if (field === "q") {
          queryFilters[field] = value;
          return;
        }
        
        const mappedOperator = mapOperator(operator);
        
        if(index == 0){
          queryFilters[`filters`] += `${field}${mappedOperator}=${value}`;
        }else{
          queryFilters[`filters`] += `&${field}${mappedOperator}=${value}`;
        }
      }
    });
  }

  return queryFilters;
};
