import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create } from "@refinedev/antd";
import { Form, Input, Select } from "antd";
import { useForm, useSelect } from "@refinedev/antd";

import { ITenant, IUser } from "../../interfaces";
import { TENANT_RES_ID } from "utility/constants";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, onFinish, redirect,  } = useForm<IUser>({
      resource: "register",
      action: "create",
    });
    
    const { selectProps: tenantProps } = useSelect<ITenant>({
        resource: TENANT_RES_ID,
        optionLabel: "name",
        optionValue: "id",
    });

    const onHandleFinish = async (values: any) => {
        await onFinish(values);
        window.location.href = "/users"
    };
    
    return (
        <Create saveButtonProps={saveButtonProps} title="Skapa ny Användare">
            <Form {...formProps} onFinish={onHandleFinish} layout="vertical">
                <Form.Item
                    label="Användarnamn"
                    name="username"
                    rules={[
                        {
                            required: true,
                            type: 'email'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Lösenord"
                    name="password"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Organisation"
                    name={"tenantId"}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...tenantProps} />
                </Form.Item>
            </Form>
        </Create>
    );
};