import { HttpError, useInfiniteList, useList } from "@refinedev/core";
import { Modal, Button, Form, Typography, Spin, Empty } from "antd";
import TextArea from "antd/es/input/TextArea";
import { IFile, IProductItem, ISettings } from "interfaces";
import { FILE_RES_ID } from "utility/constants";
import { useLocaleStore } from "stores/localeStore";
import styled from '@emotion/styled'
import { useMediaQuery } from "@react-hook/media-query";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import useRelation from "components/hooks/relationHook";
import { RelationType } from 'utility/helper';
const { Text } = Typography;

// Sync size on loading/error/empty element with succeful dataItems element
enum UI_SYNC_FETCH {
  EXPECTED_ITEM_HEIGHT = "141.844px",
  MARGIN_BOTTOM = "1.5rem",
}

const ModalWrapper = styled("div")`
`;

const RequestIndicatorContainer = styled("div")`
  margin-bottom: ${UI_SYNC_FETCH.MARGIN_BOTTOM};
  min-height: ${UI_SYNC_FETCH.EXPECTED_ITEM_HEIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.88);
`

const CardContainer = styled("div")`
  margin-bottom: ${UI_SYNC_FETCH.MARGIN_BOTTOM};
  background: white;
  border-radius: 0 0 8px 8px;
  display: grid;
  grid-template-columns: repeat(
    ${(props: MediaGridStyleProps) => props.columnCount},
    1fr
  );
  grid-gap: 1rem;

  .grid-item {
    padding: 0.5rem;
  }
`;

const Header = styled("div")`
  padding: 16px 0px;
  margin-bottom: 0.5rem;
`;

const Footer = styled("div")`
  padding: 0px 2px;
  display: flex;
  align-items: center;
  gap: 1rem;

  .ant-typography {
    font-weight: 600;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
  }

  .page-div {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex: 1;
  }

  .info-div {
    display: flex;
    gap: 1rem;
    flex: 1;
  }

  .page-count-div {
    display: flex;
    gap: 1rem;
    flex: 1;
    justify-content: flex-end;
  }
`;

type MediaGridStyleProps = {
  columnCount: number
}

export const RelationAddModal: React.FC<any> = ({ modalProps, onFinish, cardComp: CardComponent, type, title }) => {
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const { addModuleFilter, addModuleResource, setSearchValue, idsToExclude } = useRelation(type);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(18);
  const localeState = useLocaleStore();
  const [inputValue, setInputValue] = useState<string>('');
  const isMobile = useMediaQuery('(max-width: 800px)');
  const columnCount = isMobile ? 4 : 6;
  const isProductRelation = type == RelationType.RelatedParts || type == RelationType.SpareParts || type == RelationType.Accessories;

  const { data, isLoading, isError, isFetching, isRefetching, isInitialLoading } = useList<IFile | IProductItem, HttpError>({
    resource: addModuleResource,
    pagination: {
      current,
      pageSize,
    },
    queryOptions: {
      enabled: !!modalProps?.visible,
    },
    meta: { headers: { exclude: idsToExclude } },
    filters: addModuleFilter,
  },);

  const files = Array.isArray(data?.data) ?  data?.data  : []

  const setDefaultStatesOnModalLeave = () => {
    setSelectedIds([]);
    setSearchValue("")
    setInputValue("")
  }

  const onItemSelect= (id : number, checked: boolean) => {
    if(checked && !selectedIds.includes(id)) {
      setSelectedIds([...selectedIds, id])
      return;
    }
    setSelectedIds(selectedIds.filter((i) => i !== id))
  };

  const handleSearch = (newValue : any) => {
    setCurrent(1)
    setSearchValue(newValue)
  };

  const handleOk = () => {
    onFinish(selectedIds);
    setDefaultStatesOnModalLeave()
  };

  const handleCancel = () => {
    setDefaultStatesOnModalLeave()
    modalProps.onCancel();
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  return (
    <>
      <Modal {...modalProps} okText={"Lägg till relationer"} width={1000} onOk={handleOk} onCancel={handleCancel}>
        <ModalWrapper>
          <h3 style={{ fontSize: "18px" }}>{title} - lägg till relationer </h3>
          <Header>
            <Search
              placeholder={isProductRelation ? "Sök efter produkter" : "Sök efter filer"}
              size={"large"}
              value={inputValue}
              onChange={handleInputChange}
              onSearch={() => handleSearch(inputValue)}
              allowClear
              enterButton={true}
              disabled={isInitialLoading}
            />
          </Header>

          { isLoading || isFetching || isRefetching?  (
            <RequestIndicatorContainer>
              <Spin />
            </RequestIndicatorContainer>
          ) : isError ? (
            <RequestIndicatorContainer>
              <div> Något gick fel </div>
            </RequestIndicatorContainer>
          ) : !files?.length ? (
            <RequestIndicatorContainer>
              <Empty style={{padding: "0px"}} image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </RequestIndicatorContainer>) : 
          (
            <CardContainer columnCount={columnCount}>
              {files?.map((file, i) => {
                return (
                  <CardComponent
                    key={i}
                    item={file}
                    onSelect={onItemSelect}
                    selected={selectedIds.includes(file.id)}
                    className="file-relation-card"
                  ></CardComponent>
                );
              })}
            </CardContainer>
          )}

          <Footer>
            <div className="info-div">
              <Text>Antal objekt: {data?.total ?? 0} </Text>
              { data?.total && <Text>Antal valda objekt {selectedIds.length}</Text> }
            </div>
              <>
                <div className="page-div">
                  <Button type="primary" size="small" disabled={current == 1} onClick={() => setCurrent((prev) => prev - 1)}>
                    Föregående sida
                  </Button>
                  <Button type="primary" size="small" disabled={current * pageSize >= (data?.total ?? 0)} onClick={() => setCurrent((prev) => prev + 1)}>
                    Nästa sida
                  </Button>
                </div>
                <div className="page-count-div">
                  <Text>{current} / { data?.total ? (Math.ceil(data?.total / pageSize)) : 1}</Text>
                </div>
              </>
          </Footer>
          
        </ModalWrapper>
      </Modal>
    </>
  );
}