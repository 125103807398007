import React from 'react';

interface DisabledCellStyleProps {
  children: React.ReactNode;
}

const DisabledCellStyle: React.FC<DisabledCellStyleProps> = ({ children }) => {
  return (
    <span style={{opacity: "0.7"}}>
      {children}
    </span>
  );
};

export default DisabledCellStyle;