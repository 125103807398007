import React, { useState, useEffect } from 'react';
import { useList, HttpError, CrudFilter, LogicalFilter } from "@refinedev/core";
import { Button, Card, Col, Collapse, Divider, Row, Segmented, Spin, Tabs, Typography } from 'antd';
import { IFile } from 'interfaces';
import { FILE_RES_ID } from 'utility/constants';
import MediaCard from 'components/cards/mediaCard';
import styled from '@emotion/styled';
import TabPane from 'antd/es/tabs/TabPane';
import { SegmentedValue } from 'antd/es/segmented';
import ImageUploader from 'components/common/ImageUploader';
import Search from 'antd/es/input/Search';

const Wrapper = styled('div')`
    .ant-image {
        background-color: white;
    }

    .flex-container {
        display: flex;
        gap: 1rem;
    }

    .flex-center {
        display: flex;
        justify-content: center;
    }

    .file-toolbar {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        margin-bottom: 1rem;
    }

    .upload-container {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }

    .ant-input-search > span {
        margin-top: 8px;
    }
`

const FileGrid: React.FC = () => {
    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(24);
    const [showCards, setShowCards] = useState(false);
    const [readyToLoad, setReadyToLoad] = useState(false);
    const [filters, setFilters] = useState<CrudFilter[]>([]);

    const resetAnimation = () => {
        setShowCards(false);
        setReadyToLoad(false);
    }

    const handleFilterChange = (value: string) => {
        const newFilters = [
            {
                field: "extension",
                operator: "contains",
                value,
            },
        ] as CrudFilter[];
        if (value === "all") {
            newFilters.pop();
        }
        resetAnimation();
        setFilters((prevFilters) => [
            ...prevFilters.filter((filter) => (filter as LogicalFilter).field !== "extension"),
            ...newFilters
        ]);
        setCurrent(1);
    };

    const changePageSize = (value: SegmentedValue) => {
        setPageSize(value as number);
        resetAnimation();
    }

    const { data, isLoading, isError } = useList<IFile, HttpError>({
      resource: FILE_RES_ID,
      pagination: {
        current,
        pageSize,
      },
      sorters: [
        {
          field: "createdAt",
          order: "desc",
        },
      ],
      filters: filters ?? []
    });

    useEffect(() => {
        const timeout = setTimeout(() => {
            setShowCards(true);
        }, 100);
        setReadyToLoad(true);

        return () => {
            clearTimeout(timeout);
        };
    }, [data]);

    const handleSearch = (newValue : any) => {
        if(newValue == ""){
          setFilters([]);
          return;
        }
        setFilters([
            ...filters.filter((filter) => (filter as LogicalFilter).field !== "fullSearch"),
            { field: "fullSearch", operator: "contains", value: newValue }
        ]);
        setCurrent(1);
    };

    return (
      <Wrapper>
        <div>
          <Typography.Title level={4}>Filhanterare</Typography.Title>
          <p>
            Alla bilder som laddas upp enligt följande format blir automatiskt
            kopplade till respektive produkt:{" "}
            <strong>{"{artikelnummer}_{ordningsnummer}.jpg"}</strong>.
          </p>
          <div className="file-toolbar">
            <Tabs defaultActiveKey="all" onChange={handleFilterChange}>
                <TabPane tab="Alla" key="all"></TabPane>
                <TabPane tab=".pdf" key=".pdf"></TabPane>
                <TabPane tab=".jpg" key=".jpg"></TabPane>
                <TabPane tab=".png" key=".png"></TabPane>
            </Tabs>
            <Search placeholder="Sök efter fil..." onSearch={handleSearch} allowClear enterButton={true}/>
          </div>
          <Collapse className="upload-container" defaultActiveKey="1">
            <Collapse.Panel header="Ladda upp bilder" key="1">
              <ImageUploader></ImageUploader>
            </Collapse.Panel>
          </Collapse>
          <Divider
            style={{ backgroundColor: "#00000015", margin: "16px 0px" }}
          ></Divider>
        </div>

        {isLoading && (
          <div className="flex-center" style={{ marginBottom: "2rem", marginTop: '2rem' }}>
            <Spin size="large" />
          </div>
        )}

        <Row gutter={[16, 16]}>
          {data?.data.map((image, index) => (
            <Col key={index} xs={24} sm={12} md={8} lg={6} xxl={4}>
              <div
                style={{
                  display: readyToLoad ? "block" : "none",
                  opacity: showCards ? 1 : 0,
                  transition: "opacity 0.3s ease-in-out",
                }}
              >
                <MediaCard file={image}></MediaCard>
              </div>
            </Col>
          ))}
        </Row>

        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="flex-container">
            <Segmented
              value={pageSize}
              options={[
                { label: "24", value: 24 },
                { label: "48", value: 48 },
                { label: "96", value: 96 },
              ]}
              onChange={changePageSize}
            ></Segmented>
          </div>
          <div className="flex-container">
            <Button
              type="primary"
              disabled={current == 1}
              onClick={() =>
                setCurrent((prev) => (current == 1 ? 1 : prev - 1))
              }
            >
              Föregående sida
            </Button>{" "}
            <Button type="primary" onClick={() => setCurrent(current + 1)}>
              Nästa sida
            </Button>{" "}
          </div>
        </div>
      </Wrapper>
    );
};

export default FileGrid;