import { BaseKey, useApiUrl, useCustomMutation, useSelect } from '@refinedev/core';
import { getUserInfoFromCache } from 'utility/helper';

export const useTypesenseSync = () => {
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();
  const userInfo = getUserInfoFromCache();
  
  const syncTypesenseProducts = (specIds?: React.Key[], productIds?: React.Key[]) => {
    mutate({
      url: `${apiUrl}/actions/resync-typesense`,
      method: "post",
      successNotification: (data, values) => {
        return {
          message: "",
          description: "Uppdaterar mot sökmotorn",
          type: "success",
        };
      },
      values: {
        specIds: specIds,
        productIds: productIds
      },
      config: {
        headers: { Authorization: `Bearer ${userInfo.token}`}
      }
    });
  };

  return { syncTypesenseProducts };
};