import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import {
    Form,
    Input,
    Avatar,
    Row,
    Col,
    Typography,
    Space,
    Radio,
    InputProps,
    Select,
} from "antd";
import { ITenant, IUser } from "interfaces";
import { ROLE_RES_ID, TENANT_RES_ID } from "utility/constants";

const { Text } = Typography;

export const UserEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { formProps, saveButtonProps } = useForm<IUser>();

    const { selectProps: tenantProps } = useSelect<ITenant>({
        resource: TENANT_RES_ID,
        optionLabel: "name",
        optionValue: "id",
    });

    const { selectProps: roleProps } = useSelect<ITenant>({
        resource: ROLE_RES_ID,
        optionLabel: "name",
        optionValue: "name",
    });
    
    return (
      <Edit saveButtonProps={saveButtonProps}>
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            ...formProps.initialValues,
            allowedTenants: formProps.initialValues?.allowedTenants.map(
              (tenant: any) => tenant.id
            ),
          }}
        >
          <Form.Item
            label={"Användarnamn"}
            name="username"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
              label="Organisationer"
              name={"allowedTenants"}
            >
              <Select
                placeholder="Välj organisationer"
                mode="multiple"
                allowClear
                {...tenantProps}
              />
            </Form.Item>
            <Form.Item
              label="Roller"
              name="roles"
            >
              <Select
                placeholder="Välj roller"
                mode="multiple"
                allowClear
                {...roleProps}
              />
            </Form.Item>
        </Form>
      </Edit>
    );
};