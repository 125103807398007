import React, { useState } from 'react';
import { Drawer, Button, Divider, Select, Space, Flex } from 'antd';
import { useApiUrl, useCustom, useNotification } from '@refinedev/core';
import { IProperty } from 'interfaces';
import { CheckCircleOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import FilterSection from './filterSection';
import styled from '@emotion/styled'
import { DividerLine } from 'components/separators/dividerLine';

const DrawerStyled= styled(Drawer)`
  .ant-drawer-title {
    font-size: 18px;
  }
`

interface ClassType {
  name: string;
  key: string;
}

interface FilterDrawerProps {
  visible: boolean;
  onClose: (filters : any[]) => void;
  primaryType: ClassType;
  secondaryTypes: ClassType[];
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({ visible, onClose, primaryType, secondaryTypes }) => {
  const [activeFilters, setActiveFilters] = useState<{[filterKey: string]: any[]}>({}); 
  const { open : showCustomNotification } = useNotification();
  
  const apiUrl = useApiUrl();
  const { data, isLoading } = useCustom<any>({
    url: `${apiUrl}/properties`,
    method: "get",
    config: {
      query: {
        primaryType: primaryType.key,
        secondaryTypes: secondaryTypes.map(secType => secType.key).join(',')
      },
    },
  });

  const addNewFilter = (key: string) => {
    if(!activeFilters[key]) {
      setActiveFilters({...activeFilters, [key]: [{
        field: null,
        operator: null,
        value: null,
      }]});
      return;
    }
    const newFilterState = [...activeFilters[key]];
    newFilterState.push({
      field: null,
      operator: null,
      value: null,
    });
    setActiveFilters({...activeFilters, [key]: newFilterState});
  };

  const updateFilterState = (updatedFilters: any[], key: string) => {
    setActiveFilters((prev) => ({ ...prev, [key]: updatedFilters }));
  }

  const flatFilters = Object.keys(activeFilters).map((key) => activeFilters[key]).flat();

  const onCloseEvent = () => {
    // const someFilterPropIsNull = flatFilters.some(obj => Object.values(obj).some(value => value === null));
    // if(someFilterPropIsNull){
    //   showCustomNotification?.({
    //     type: "error",
    //     message: "Se över dina filterinställningar och försök igen.",
    //     description: "Något gick fel!"
    //   });
    //   return;
    // }
    onClose(flatFilters);
  }

  return (
    <DrawerStyled
      title="Hantera filter"
      placement="right"
      width={500}
      closable={false}
      onClose={onCloseEvent}
      visible={visible}
      // extra={"For future use"}
      footer={
        <Flex vertical>
          <Button
            size='large'
            style={{ width: "100%" }}
            type="primary"
            icon={<CheckCircleOutlined />}
            onClick={() => onClose(flatFilters)}
          >
            Verkställ filtering
          </Button>
          <DividerLine style={{ margin: "0.5rem 0" }} />
          <Space style={{marginBottom: "0.4rem"}}>
            <Button type="default" icon={<SaveOutlined />}>
              Spara filtering
            </Button>
            <Button type="default" icon={<SaveOutlined />}>
              Ladda filtering
            </Button>
          </Space>
        </Flex>
      }
    >
      <DividerLine style={{ margin: "1rem 0" }} />

      {primaryType && (
        <>
          <div style={{ marginBottom: "1rem" }}>
            <h3 style={{ fontSize: "16px" }}>{primaryType.name}</h3>
            <Button
              type="default"
              icon={<PlusOutlined />}
              onClick={() => addNewFilter("Product")}
            >
              Lägg till filter
            </Button>
          </div>
          <FilterSection
            filterProperties={data?.data[primaryType.key]}
            activeFilters={activeFilters["Product"]}
            updateFilterState={(updatedFilters) =>
              updateFilterState(updatedFilters, "Product")
            }
          ></FilterSection>
        </>
      )}

      <DividerLine style={{ margin: "1rem 0" }} />

      {secondaryTypes?.map((type, i) => {
        return (
          <React.Fragment key={type.key}>
            <div style={{ marginBottom: "1rem" }} >
              <h3 style={{ fontSize: "16px" }}>{type.name}</h3>
              <Button
                type="default"
                icon={<PlusOutlined />}
                onClick={() => addNewFilter(type.key)}
              >
                Lägg till filter
              </Button>
            </div>
            <FilterSection
              filterProperties={data?.data[type.key]}
              activeFilters={activeFilters[type.key]}
              updateFilterState={(updatedFilters) =>
                updateFilterState(updatedFilters, type.key)
              }
            ></FilterSection>
            {
              secondaryTypes.length - 1 !== i && <DividerLine style={{ margin: "1rem 0" }} />
            }
          </React.Fragment>
        );
      })}

    </DrawerStyled>
  );
};

export default FilterDrawer;
