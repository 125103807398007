import { useProductCategoriesSelectContext } from "components/common/productCategories/context/productCategoriesContext";
import { CloseCircleOutlined } from '@ant-design/icons';
import { Tag, List } from 'antd';
import styled from '@emotion/styled';

const LisItemContent = styled("span")`
  width: fit-content;


  .ant-tag-default {
    padding-top: 0.25em;
    padding-bottom: 0.2em;
  }

  .badge-icon {
    font-size: 1.1rem;
    vertical-align: -0.29rem;
    color: #ff7b7b;
    margin-inline-start: 0.4rem;
  }

  .badge-icon:hover {
    color: red;
  }
`

interface ProductCategoriesSelectedListProps {
  formLoading?: boolean;
}

export const ProductCategoriesSelectedList: React.FC<ProductCategoriesSelectedListProps> = ({formLoading}) => {
  const { selectedCategoryNodes, deleteCategoryNode } = useProductCategoriesSelectContext();
  return (
    <List
      loading={formLoading}
      header={<div style={{padding: "0rem", margin: "0rem", paddingInlineStart: "0px"}}>Valda kategorier</div>}
      bordered={true}
      style={{padding: "0.2rem 0 0.2rem 0", height: "100%"}}
      itemLayout="horizontal"
      dataSource={selectedCategoryNodes}
      renderItem={(item, index) => (
        <List.Item style={{padding: "0.1rem 0.1rem 0.1rem 1rem "}}>
          <div   style={{padding: "0.2rem 0 0.2rem 0"}}>
            <LisItemContent>
              <Tag key={item?.key} color="default" closeIcon={<CloseCircleOutlined className="badge-icon" onClick={() => deleteCategoryNode(item.key)} />}>
                {item.title}
              </Tag>
            </LisItemContent>
          </div>
          {/* <Tag key={item.key} color="default" closeIcon={<CloseCircleOutlined onClick={() => deleteCategoryNode(item.key)}/>}>
              {item.title}
          </Tag> */}
        </List.Item>
      )}
    />
  );
}