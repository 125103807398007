import { jwtDecode } from "jwt-decode";
import dayjs from 'dayjs';
import { notification } from "antd";

export enum RelationType {
  Manual = "Manual",
  SafetySheet = "SafetySheet",
  DimensionalSheet = "DimensionalSheet",
  ProductImage = "ProductImage",
  SpareParts = "SpareParts",
  RelatedParts = "RelatedParts",
  Accessories = "Accessories",
}

export const stringToColor = (str: string) => {
  if (str == "Focus") {
    return "#005488";
  }

  if (str == "Tura") {
    return "#000";
  }

  if (str == "pågående") {
    return "#23b06e";
  }

  if (str == "avslutat") {
    return "#3eb2fa";
  }

  if (str == "pausat") {
    return "#f5ad42";
  }

  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};

export const hashCode = (value : string) => {
  var hash = 0,
    i, chr;
  if (value?.length === 0) return hash;
  for (i = 0; i < value?.length; i++) {
    chr = value?.charCodeAt(i);
    hash = ((hash << 5) - hash) + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

export function getUniqueValuesFromArrayInArray<T>(dataSource: readonly T[], key: keyof T) : string[]{
  const allIds = dataSource?.flatMap(obj => obj[key] as unknown as string);
  const uniqueIDs = new Set<string>(allIds);
  return Array.from<string>(uniqueIDs);
}

export const decodeTokenAndSave = (response : any) => {
  const token = response?.data?.token;
  const decodedTokenObj = jwtDecode(token);
  localStorage.setItem('user', JSON.stringify({...decodedTokenObj, token}));
}

export const getUserInfoFromCache = () => {
  try {
    const data = localStorage.getItem('user');
    const userInfo = data ? JSON.parse(data) : null;
    return userInfo;
  } catch (e) {
    console.error(e);
  }
  return null;
}

export const getDescriptionFromLocale = (locale: string) => {
  if (locale == "sv") {
    return "Svenska";
  }

  if (locale == "da") {
    return "Danska";
  }

  if (locale == "no") {
    return "Norska";
  }

  return "Svenska";
};

export const formatDate = (rawDate: string) => {
  const date = dayjs(rawDate?.toString())
  return date.format('YYYY-MM-DDTHH:mm:ss');
};

export function splitArrayEvenlyIntoThreeChunks<T>(arr: T[] | undefined): [T[], T[], T[]] {
  // Return empty arrays if input is not an array
  if (!Array.isArray(arr)) {
    return [[], [], []];
  }

  let part1: T[] = [], part2: T[] = [], part3: T[] = [];
  let totalItems: number = arr.length;
  let partSizeBase: number = Math.floor(totalItems / 3);
  let extraItems: number = totalItems % 3;

  // Determine the sizes of each part dynamically
  let part1Size: number = partSizeBase + (extraItems > 0 ? 1 : 0);
  let part2Size: number = partSizeBase + (extraItems > 1 ? 1 : 0);

  for (let i = 0; i < arr.length; i++) {
      if (i < part1Size) {
          part1.push(arr[i]);
      } else if (i < part1Size + part2Size) {
          part2.push(arr[i]);
      } else {
          part3.push(arr[i]);
      }
  }
  
  return [part1, part2, part3];
}

export function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(
    function () {
      notification.info({
        message: "Text kopierad",
        duration: 1,
      });
    },
    function (err) {
      console.error("Could not copy text: ", err);
    }
  );
} 

export function areArraysEqual<T>(a: T[] | null | undefined, b: T[] | null | undefined, comparator: (x: T, y: T) => boolean = (x, y) => x === y): boolean {
  // Check if both are null or undefined, consider them equal
  if (a === b) return true;

  // If either is null/undefined and not both, they are not equal
  if (a == null || b == null) return false;

  // Proceed with length and content checks
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
      if (!comparator(a[i], b[i])) return false;
  }
  return true;
}