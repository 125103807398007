import React, { MutableRefObject, useCallback, useState } from "react";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import '@inovua/reactdatagrid-community/index.css'
import { TypeComputedProps, TypeEditInfo, TypeColumn, TypeRowSelection, TypeFilterValue } from "@inovua/reactdatagrid-community/types";
import useDataGrid from "components/hooks/dataGrid/useDataGrid";
import useDataGridStore from "stores/dataGridStore";
import ToolBarGrid from "components/dataGrid/toolBarGrid";
import { useHandleDataGridExit } from "components/hooks/useHandleComponentExit";
import SaveTableDataLoader from "components/dataGrid/loadIndicators/saveTableDataLoader";
import FooterGrid from "components/dataGrid/footerGrid";
import useDataGridLoading from "components/hooks/dataGrid/useDataGridLoading";
import SaveReportModal from "components/dataGrid/modals/saveReportModal";
// import RenderUpdateStackDevComp from "components/dataGrid/renderUpdateStackDevComp";
import { t, use } from "i18next";
import useMetadataStore from "stores/metadataStore";
import { TypeOnSelectionChangeArg } from "@inovua/reactdatagrid-community/types/TypeDataGridProps";
import { selectLine } from "@uiw/react-md-editor";

const editRestricted = ["mainItemNo", "groupName", "ean", "status"];
const gridStyle = { minHeight: "80vh" }

const DataGridView: React.FC = () => {
    const [ gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);
    const { columns, filterValue } = useDataGrid();
    const { dataGridIsLoading } = useDataGridLoading();
    useHandleDataGridExit();
    const {
        setDataSource,
        dataSource,
        updateStack,
        setUpdateStack,
        groupId,
        cellSelection,
        setCellSelection,
        firstSelectedCellKey,
        setFirstSelectedCellKey,
        selectedRows,
        setSelectedRows,
        checkboxColumnActive,
      } = useDataGridStore();

    const onEditComplete = useCallback((editInfo: TypeEditInfo) => {
        const data = [...dataSource];
        const productId = editInfo.rowId;
        const rowToUpdateIndex = data.findIndex((items) => items.id == productId);
        const oldValue = data[rowToUpdateIndex][editInfo.columnId];

        if(editInfo.value == oldValue) return;

        data[rowToUpdateIndex][editInfo.columnId] = editInfo.value;
        setDataSource(data);
        setUpdateStack(prevStack => [
            ...prevStack, 
            { cell: `${productId},${editInfo.columnId}`, value: oldValue, revisionId: generateRandomRevisionId() }
        ]);
    }, [dataSource]);

    const onCellSelectionChange = (value: { [key: string]: boolean }) => {
        const selectedCellCount = Object.keys(value).length;
        if(selectedCellCount === 0){
            return;
        }
        if(selectedCellCount === 1){
            setFirstSelectedCellKey(getFirstCellKey(value));
        }
        setCellSelection(value);
    }

    const updateAndParseSelectedCell = (selectedCell: string, newValue: any, revisionId: number) => {
        const selectedRowId: number = parseInt(selectedCell.split(',')[0]);
        const selectedColumnProp: string = selectedCell.split(',')[1];
        const data = [...dataSource];
        const indexToUpdate = data.findIndex(data => data.id === selectedRowId);
        const oldValue = data[indexToUpdate][selectedColumnProp];
        const column = columns.find(c => c.name == selectedColumnProp)

        if(column?.type == "array" && typeof newValue === 'string'){
            newValue = newValue.split(",").map((id: string) => ({ id: parseInt(id) }));
        }

        if (!isNaN(newValue) && typeof newValue === 'string') {
            newValue = parseInt(newValue);
        }

        if(newValue == "false"){
            newValue = false;
        }

        if(column?.type == "arrayObject" && typeof newValue === 'string'){
            newValue = newValue.split("|").map((item: string) => (JSON.parse(item)));
        }

        data[indexToUpdate][selectedColumnProp] = newValue;
        setDataSource(data);
        setUpdateStack(prevStack => [...prevStack, { cell: selectedCell, value: oldValue, revisionId: revisionId }]);
    };

    
    const generateRandomRevisionId = () => {
        const revisionId = Math.floor(Math.random() * 10000);
        return revisionId;
    };
    
    const performMultiCellUpdate = (selectedCells: string[], newValue: any) => {
        const revisionId = generateRandomRevisionId();
        for (const selectedCell of selectedCells) {
            updateAndParseSelectedCell(selectedCell, newValue, revisionId);
        }
    };

    const getProductIdFromCell = (cellKey: string | null) => {
        return parseInt(cellKey?.split(',')[0] ?? "0");
    }

    const getColumnKeyFromCell = (cellKey: string | null) => {
        return cellKey?.split(',')[1] ?? "";
    }

    const getFirstCellKey = (cellSelection: { [key: string]: boolean }) => {
        return Object.keys(cellSelection)[0];
    }
    
    const extractColumnsForMultiCellUpdate = () => {
        if (Object.keys(cellSelection).length === 0) {
            return;
        }

        const productId = getProductIdFromCell(firstSelectedCellKey);
        const columnKey = getColumnKeyFromCell(firstSelectedCellKey);
        const valueToCopy = dataSource?.find(data => data.id === productId)[columnKey];
        
        if(editRestricted.includes(columnKey)){
            return;
        }
        
        const cellsToUpdate = Object.keys(cellSelection).filter(cell => cell != firstSelectedCellKey && 
            getColumnKeyFromCell(cell) === columnKey
        );
        performMultiCellUpdate(cellsToUpdate, valueToCopy);
    }
    
    const handleKeyDown = useCallback((event: React.KeyboardEvent) => {
        try {
            if (event.key === "Escape") {
                setCellSelection({});
            } else if (event.key === "Enter") {
                extractColumnsForMultiCellUpdate();
            } else if ((event.ctrlKey || event.metaKey) && event.key === "z") {
                // handleUndoStackUpdate
            } else if((event.ctrlKey || event.metaKey) && event.key === "c"){
                const selectedCell = getFirstCellKey(cellSelection);
                const productId = getProductIdFromCell(selectedCell);
                const columnKey = getColumnKeyFromCell(selectedCell);
                const valueToCopy = dataSource?.find(data => data.id === productId)[columnKey];
                const column = columns.find(c => c.name == columnKey)
    
                if (column?.type == "array") {
                    navigator.clipboard.writeText(valueToCopy.map((item: any) => item).join(","));
                } else if (column?.type == "arrayObject") {
                    navigator.clipboard.writeText(valueToCopy.map((item: any) => JSON.stringify(item)).join("|"));
                } else {
                    navigator.clipboard.writeText(valueToCopy);
                }
            } else if((event.ctrlKey || event.metaKey) && event.key === "v"){
                navigator.clipboard.readText().then(clipText => {
                    performMultiCellUpdate(Object.keys(cellSelection), clipText);
                });
            }
         }
        catch (error) {
            console.error("Error updating cell", error);
        }

    }, [cellSelection, updateStack]);
     
    const onBlur = useCallback((event : any) => {
        setCellSelection({});
    }, [])

    const onRowSelectionChange = useCallback(({ selected }: {selected: TypeRowSelection}) => {
        setSelectedRows(selected)
    }, []);

    // const onFilterValuesChange = useCallback((filterValues: TypeFilterValue) => {
    //     const someFilterActive = filterValues?.some(
    //       (filter) => filter?.value !== null && filter?.value !== ''
    //     );
    //     console.log("Filter value change", someFilterActive);
    // }, [])

    // const handleResetFilters = () => {
    //     if (gridRef?.current?.clearAllFilters) {
    //       gridRef?.current?.clearAllFilters();
    //     }
    // };

    return (
        <>
        {/* <RenderUpdateStackDevComp /> */}
        <ToolBarGrid />
        <div onKeyDown={handleKeyDown}>
          <ReactDataGrid
            idProperty="id"
            defaultFilterValue={filterValue}
            loading={dataGridIsLoading}
            onReady={setGridRef}
            columns={columns}
            onEditComplete={onEditComplete}
            dataSource={dataSource}
            activateRowOnFocus={false}
            onBlur={onBlur}
            shareSpaceOnResize={true}
            style={gridStyle}
            emptyText={
              groupId
                ? "Ingen data tillgänglig"
                : "Välj en varugrupp för att ladda in produkter"
            }
            key={"grid-" + checkboxColumnActive}
            checkboxColumn={checkboxColumnActive}
            editable={!checkboxColumnActive}
            onSelectionChange={ checkboxColumnActive ? onRowSelectionChange : undefined }
            selected={checkboxColumnActive ? selectedRows : undefined}
            cellSelection={!checkboxColumnActive ? cellSelection : undefined}
            onCellSelectionChange={ !checkboxColumnActive ? onCellSelectionChange : undefined}
            // onFilterValueChange={onFilterValuesChange}
            // pagination
            // renderPaginationToolbar={() => <FooterGrid itemsCount={dataSource?.length} />}
            // onFocus={onFocus}
            // checkboxOnlyRowSelect={true}
            // clearNodeCacheOnDataSourceChange={true}
          />
        </div>
        <FooterGrid itemsCount={dataSource?.length ?? 0} />
        <SaveTableDataLoader />
        <SaveReportModal />
        
      </>
    );
};

export default DataGridView;
