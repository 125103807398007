import React from 'react';
import SVG from 'react-inlinesvg';
import {ReactComponent as AhlsellShippingSvg} from '../../assets/shipping/dropshipment-ahsell.svg';
import {ReactComponent as Shipping100Svg} from '../../assets/shipping/fraktklass-100.svg';
import {ReactComponent as Shipping250Svg} from '../../assets/shipping/fraktklass-250.svg';
import {ReactComponent as Shipping1500Svg} from '../../assets/shipping/fraktklass-1500.svg';
import { Tooltip } from 'antd';
import { useTranslate } from '@refinedev/core';

export const RenderShippingSvg: React.FC<{ shippingType: number }> = ({ shippingType }) => {
    const t = useTranslate();
    const getSvgComponent = () => {

        switch (shippingType) {
            case 1:
                return <AhlsellShippingSvg />;
            case 2:
                return <Shipping100Svg />;
            case 3:
                return <Shipping250Svg />;
            case 4:
                return <Shipping1500Svg />;
            default:
                return <></>;
        }
    };
    return (
        <div style={{ width: '32px', marginLeft: '10px' }}>
        <Tooltip title={t(shippingType?.toString())} color={"#195178"}>
            {getSvgComponent()}
        </Tooltip>
        </div>
    );
}