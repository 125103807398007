import { Flex } from "antd";
import styled from "@emotion/styled";
import FilterSection from "./toolBarGrid/filterSection";
import ToolBarButtons from "./toolBarGrid/toolBarButtons";

const ToolBarWrapper = styled(Flex)`
  flex-wrap: wrap;
  padding: 12px 8px 12px 8px;
  background-color: white;
  border-top: 1px solid #e4e3e2;
  border-right: 1px solid #e4e3e2;
  border-left: 1px solid #e4e3e2;
  border-bottom: 1px solid #e4e3e2;
  row-gap: 8px;
`;

const ToolBarGrid = () => {
  return (
    <ToolBarWrapper justify="space-between">
      <FilterSection />
      <ToolBarButtons />
    </ToolBarWrapper>
  );
};

export default ToolBarGrid;
