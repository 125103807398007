import { IProductItem } from "interfaces";
import { create } from "zustand";


interface UpdatedVariantsData {
  variantId: React.Key;
  value: string;
}

interface ProductVariantsStore {
  productVariants: IProductItem[];
  setProductVariants: (newProductVariants: IProductItem[]) => void;
  addProductVariant: (newProductVariant: IProductItem) => void;
  deleteProductVariant: (variantId: number) => void;
  updateProductVariantsProperty: (
    editedVariants: UpdatedVariantsData[],
    propertyName: string
  ) => void;
}


export const useProductVariantsStore = create<ProductVariantsStore>((set) => ({
  productVariants: [],
  setProductVariants: (newProductVariants: IProductItem[]) =>
    set({ productVariants: newProductVariants }),

  addProductVariant: (newProductVariant: IProductItem) =>
    set((state) => {
      const exists = state.productVariants.some(
        (variant) => variant.id === newProductVariant.id
      );
      if (!exists) {
        return {
          productVariants: [...state.productVariants, newProductVariant],
        };
      } else {
        console.warn("Variant already exists in the list");
        // Return the state unchanged
        return state;
      }
    }),

  deleteProductVariant: (variantId: number) =>
    set((state) => ({
      productVariants: state.productVariants.filter(
        (variant) => variant.id !== variantId
      ),
    })),

  updateProductVariantsProperty: (
    editedVariants: UpdatedVariantsData[],
    propertyName: string
  ) =>
    set((state) => {
      const updatedProductVariants = state?.productVariants?.map((variant) => {
        const editedVariant = editedVariants?.find(
          (edited) => edited?.variantId == variant?.id
        );
        if (editedVariant) {
          return { ...variant, [propertyName]: editedVariant?.value };
        }
        return variant;
      });
      return { productVariants: updatedProductVariants };
    }),
}));
