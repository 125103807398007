import { BaseKey, useTranslate } from "@refinedev/core";
import {
  ICategory,
  IFile,
  IFileReqId,
  IProduct,
  IProductItem,
} from "interfaces";
import BoolFilter from "@inovua/reactdatagrid-community/BoolFilter";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";

import SelectEditor from "@inovua/reactdatagrid-community/SelectEditor";
import BoolEditor from "@inovua/reactdatagrid-community/BoolEditor";
import {
  IColumn,
  TypeColumn,
  TypeFilterValue,
} from "@inovua/reactdatagrid-community/types";
import useMetadataStore from "stores/metadataStore";
import { RelationType, copyToClipboard } from "utility/helper";
import BrandSelectGrid from "components/dataGrid/editors/brandSelectGrid";
import TextModalGrid from "components/dataGrid/editors/textModalGrid";
import RelationModalGridEdit from "components/dataGrid/editors/relationModalGridEdit";
import { useFetchProductsByGroupChange } from "./useDataGridSource";
import CategoriesModalGrid from "components/dataGrid/editors/categoriesModalGrid";
import { IconBaseProps } from "@ant-design/icons/lib/components/Icon";
import useDataGridStore from "stores/dataGridStore";
import GridCellImagesInfo from "components/dataGrid/cellRender/gridCellImagesInfo";
import { Progress, Tooltip } from "antd";
import RenderStringCellWithCopy from "components/dataGrid/cellRender/renderStringCellWithCopy";
import { css } from "@emotion/react";
import DisabledCellStyle from "components/dataGrid/cellRender/disabledCellStyle";

// Extended TypeColumn type handles
// inovua BoolEditor and SelectEditor
type ExtendedTypeColumn = TypeColumn & {
  editor?: React.ComponentType<any>;
  editorProps?: any;
};

interface DataGridStore {
  columns: ExtendedTypeColumn[];
  filterValue: TypeFilterValue | undefined;
}

const useDataGrid = (): DataGridStore => {
  useFetchProductsByGroupChange();
  const { getBrandById, getCategoryById } = useMetadataStore();
  const t = useTranslate();

  const shippingOptions: { id: number; label: string }[] = [
    { id: 0, label: t("0") },
    { id: 1, label: t("1") },
    { id: 2, label: t("2") },
    { id: 3, label: t("3") },
    { id: 4, label: t("4") },
    { id: 5, label: t("5") },
  ];

  const filterValue: TypeFilterValue = [
    { name: "name", operator: "startsWith", type: "string", value: null },
    { name: "mainItemNo", operator: "startsWith", type: "string", value: null },
    { name: "isPublished", operator: "eq", type: "boolean", value: null },
    {
      name: "completenessPercentage",
      operator: "gte",
      type: "number",
      value: null,
    },
  ];

  const columns: ExtendedTypeColumn[] = [
    {
      name: "id",
      header: "Id",
      defaultVisible: false,
      defaultFlex: 1,
      minWidth: 100,
      type: "number",
      editable: false,
      cellSelectable: false,
    },
    {
      name: "images",
      header: "Bilder",
      type: "arrayObject",
      minWidth: 56,
      defaultFlex: 2,
      render: ({ value }: { value: IFile[] }) => {
        return <GridCellImagesInfo value={value} />;
      },
      renderEditor: (editorProps: any) => {
        return (
          <RelationModalGridEdit<IFile>
            editorProps={editorProps}
            relationType={RelationType.ProductImage}
            selectedItems={editorProps.value}
            title={"Bilder"}
          />
        );
      },
    },
    {
      name: "isPublished",
      header: "Publicerad",
      type: "boolean",
      minWidth: 40,
      maxWidth: 130,
      filterEditor: BoolFilter,
      defaultFlex: 1,
      render: ({ value }: { value: boolean }) =>
        value ? (
          <div
            style={{
              backgroundColor: "green",
              width: "15px",
              height: "15px",
              borderRadius: "100px",
              margin: "auto",
            }}
          ></div>
        ) : (
          <div
            style={{
              backgroundColor: "red",
              width: "15px",
              height: "15px",
              borderRadius: "100px",
              margin: "auto",
            }}
          ></div>
        ),
      editor: BoolEditor as any,
    },
    {
      name: "completenessPercentage",
      header: "Status",
      type: "number",
      minWidth: 100,
      maxWidth: 130,
      defaultFlex: 1,
      editable: false,
      cellSelectable: false,
      style: { cursor: "not-allowed" },
      filterEditor: NumberFilter,
      render: ({ value, data }: { value: number; data: any }) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Tooltip
              title={data?.completenessText ?? "Statusinformation saknas"}
              className="auto-cursor"
              color={
                data?.completenessText ? (value < 100 ? "blue" : "green") : ""
              }
            >
              <Progress type="circle" percent={value} size={27} />
            </Tooltip>
          </div>
        );
      },
    },
    {
      name: "mainItemNo",
      header: "Artikelnummer",
      minWidth: 70,
      maxWidth: 140,
      defaultFlex: 2,
      editable: false,
      cellSelectable: false,
      style: { cursor: "not-allowed" },
      render: ({ value }: { value: string }) => {
        return <RenderStringCellWithCopy value={value} />;
      },
    },
    {
      name: "ean",
      header: "Ean",
      minWidth: 70,
      defaultFlex: 1,
      editable: false,
      cellSelectable: false,
      // sortable: false,
      style: { cursor: "not-allowed" },
      render: ({ data }: { data: IProduct }) => {
        const ean =
          data.variants.find((v) => v?.itemNo == data.mainItemNo)?.ean ?? "";
        return <RenderStringCellWithCopy value={ean} />;
      },
    },
    { name: "name", header: "Webbnamn", minWidth: 50, defaultFlex: 2 },
    {
      name: "description",
      header: "Beskrivning",
      // minWidth: 100,
      defaultFlex: 1,
      render: ({ value, inEdit }: { value: any; inEdit: any }) => {
        return value;
      },
      renderEditor: (editorProps: any) => {
        return <TextModalGrid editorProps={editorProps} />;
      },
    },
    {
      name: "descriptionSupplier",
      header: "Lev beskr.",
      // minWidth: 100,
      defaultFlex: 1,
      render: ({ value, inEdit }: { value: any; inEdit: any }) => {
        return value;
      },
      renderEditor: (editorProps: any) => {
        return <TextModalGrid editorProps={editorProps} />;
      },
    },
    {
      name: "brandId",
      header: "Varumärke",
      // minWidth: 100,
      defaultFlex: 2,
      render: ({ value }: { value: number }) =>
        `${getBrandById(value)?.name ?? ""}`,
      renderEditor: (editorProps: any) => {
        return (
          <BrandSelectGrid
            editorProps={editorProps}
            placeholder="Välj varumärke"
          />
        );
      },
    },
    {
      name: "categories",
      header: "Kategorier",
      type: "arrayObject",
      defaultFlex: 2,
      render: ({ value }: { value: ICategory[] }) => {
        return `[${value?.map((item) => item?.name)?.join(",")}]`;
      },
      renderEditor: (editorProps: any) => {
        return <CategoriesModalGrid editorProps={editorProps} />;
      },
    },
    {
      name: "accessories",
      header: "Tillbehör",
      type: "arrayObject",
      // minWidth: 100,
      defaultFlex: 2,
      render: ({ value }: { value: IProduct[] }) => {
        return `[${value?.map((item) => item?.mainItemNo)?.join(",")}]`;
      },
      renderEditor: (editorProps: any) => {
        return (
          <RelationModalGridEdit<IProduct>
            editorProps={editorProps}
            relationType={RelationType.Accessories}
            selectedItems={editorProps.value}
            title={"Tillbehör"}
          />
        );
      },
    },
    {
      name: "groupName",
      header: "Varugrupp",
      // minWidth: 100,
      defaultVisible: false,
      defaultFlex: 2,
      editable: false,
      cellSelectable: false,
      style: { cursor: "not-allowed" },
      render: ({ value }: { value: string }) => (
        <DisabledCellStyle>{value}</DisabledCellStyle>
      ),
    },
    {
      name: "internalComments",
      header: "Kommentarer",
      // minWidth: 100,
      defaultVisible: false,
      defaultFlex: 1,
      render: ({ value, inEdit }: { value: any; inEdit: any }) => {
        return value;
      },
      renderEditor: (editorProps: any) => {
        return <TextModalGrid editorProps={editorProps} />;
      },
    },
    {
      name: "manuals",
      header: "Manualer",
      defaultVisible: false,
      type: "arrayObject",
      defaultFlex: 2,
      render: ({ value }: { value: IFile[] }) => {
        return `[${value?.map((item) => item?.id)?.join(",")}]`;
      },
      renderEditor: (editorProps: any) => {
        return (
          <RelationModalGridEdit<IFile>
            editorProps={editorProps}
            relationType={RelationType.Manual}
            selectedItems={editorProps.value}
            title={"Manualer"}
          />
        );
      },
    },
    {
      name: "safetySheets",
      header: "Säkerhetsblad",
      defaultVisible: false,
      type: "arrayObject",
      defaultFlex: 2,
      render: ({ value }: { value: IFile[] }) => {
        return `[${value?.map((item) => item?.id)?.join(",")}]`;
      },
      renderEditor: (editorProps: any) => {
        return (
          <RelationModalGridEdit<IFile>
            editorProps={editorProps}
            relationType={RelationType.SafetySheet}
            selectedItems={editorProps.value}
            title={"Säkerhetsblad"}
          />
        );
      },
    },
    {
      name: "dimensionalSheets",
      header: "Måttskisser",
      defaultVisible: false,
      type: "arrayObject",
      defaultFlex: 2,
      render: ({ value }: { value: IFile[] }) => {
        return `[${value?.map((item) => item?.id)?.join(",")}]`;
      },
      renderEditor: (editorProps: any) => {
        return (
          <RelationModalGridEdit<IFile>
            editorProps={editorProps}
            relationType={RelationType.DimensionalSheet}
            selectedItems={editorProps.value}
            title={"Måttskisser"}
          />
        );
      },
    },
    {
      name: "shippingMethod",
      header: "Fraktsätt",
      // minWidth: 150,
      defaultFlex: 2,
      render: ({ value }: { value: number }) =>
        shippingOptions.find((option) => option.id === value)?.label,
      editor: SelectEditor as any,
      editorProps: {
        idProperty: "id",
        dataSource: shippingOptions,
        collapseOnSelect: true,
        clearIcon: null,
      },
    },
    {
      name: "spareParts",
      header: "Reservdelar",
      type: "arrayObject",
      defaultFlex: 2,
      render: ({ value }: { value: IProduct[] }) => {
        return `[${value?.map((item) => item?.mainItemNo)?.join(",")}]`;
      },
      renderEditor: (editorProps: any) => {
        return (
          <RelationModalGridEdit<IProduct>
            editorProps={editorProps}
            relationType={RelationType.SpareParts}
            selectedItems={editorProps.value}
            title={"Reservdelar"}
          />
        );
      },
    },
    {
      name: "modifiedAt",
      header: "Senast ändrad",
      defaultFlex: 1,
      defaultVisible: false,
      editable: false,
      cellSelectable: false,
      style: { cursor: "not-allowed" },
      render: ({ value }: { value: string }) => {
        const formattedDate =
          new Date(value).toLocaleString("sv-SE") == "Invalid Date"
            ? "Ogiltig datum"
            : new Date(value).toLocaleString("sv-SE");
        return <span>{formattedDate}</span>;
      },
    },
    {
      name: "createdAt",
      header: "Skapad",
      defaultFlex: 1,
      defaultVisible: false,
      editable: false,
      cellSelectable: false,
      style: { cursor: "not-allowed" },
      render: ({ value }: { value: string }) => {
        const formattedDate =
          new Date(value).toLocaleString("sv-SE") == "Invalid Date"
            ? "Ogiltig datum"
            : new Date(value).toLocaleString("sv-SE");
        return <span>{formattedDate}</span>;
      },
    },
  ];

  return { columns, filterValue };
};

export default useDataGrid;

// Add fields later specifications, videos, variants, relatedParts
// {
//   name: "videos",
//   header: "Videos",

//   defaultFlex: 1,
//   render: ({ value }: { value: IProductVideo[] }) => {
//     return `[${value.map((item) => item?.id).join(",")}]`;
//   },
//   renderEditor: (editorProps: any) => {
//     return <div tabIndex={0}>Modal</div>;
//   },
// },
// {
//   name: "variants",
//   header: "Varianter",
//   minWidth: 200,
//   defaultFlex: 1,
//   render: ({ value }: { value: IProductItem[] }) => {
//     return `[${value.map((item) => item?.id).join(",")}]`;
//   },
//   renderEditor: (editorProps: any) => {
//     return <div tabIndex={0}>Modal</div>;
//   },
// },
// {
//   name: "specifications",
//   header: "Specifikationer",

//   defaultFlex: 1,
//   render: ({ value }: { value: ISpecification[] }) => {
//     return `[${value.map((item) => item?.id).join(",")}]`;
//   },
//   renderEditor: (editorProps: any) => {
//     return <div tabIndex={0}>Modal</div>;
//   },
// },
// {
//   name: "relatedParts",
//   header: "Relaterade produkter",
//   // minWidth: 100,
//   defaultFlex: 2,
//   render: ({ value }: { value: BaseKey[] }) => `[${value?.join(",")}]`,
//   renderEditor: (editorProps: any) => {
//     return (
//       // <RelationModalGridEdit
//       //   editorProps={editorProps}
//       //   relationType={RelationType.RelatedParts}
//       // />
//       <></>
//     );
//   },
// },

// Show in grid?
// Create multiple columns for gropus of data from pictogramData?
// {
//   name: "pictoGramData",
//   header: "PictoGramData",
//   minWidth: 50,
//   defaultFlex: 1,
//   render: ({ value }: { value: IPictogram }) => value.signalWord.toString(),
//   renderEditor: (editorProps: any) => {
//     return <div tabIndex={0}></div>;
//   },
// },

// interface DataGridStore {
// dataSource: any[];
// isLoading: boolean;
// error: string | null;
// setDataSource: React.Dispatch<React.SetStateAction<IProduct[]>>;
//   columns: ExtendedTypeColumn[];
// }
