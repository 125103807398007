import { CheckCircleOutlined, CloseCircleOutlined, MonitorOutlined } from '@ant-design/icons';
import { Tag, List, Flex, Spin, Empty, Button } from 'antd';
import { useProductVariantsStore } from "stores/productVariantsStore";
import styled from '@emotion/styled';
import {
  Typography,
} from "antd";
import { IProductItem } from 'interfaces';
import { splitArrayEvenlyIntoThreeChunks } from 'utility/helper';
import SpecStatsModal from 'components/modals/specStats';
import { useState } from 'react';
import { BaseKey } from '@refinedev/core';

const RequestIndicatorContainer = styled("div")`
  margin-bottom: 50px;
  min-height: 0px;
  display: flex;
  justify-content: center;
  border: 1px solid #D9D9D9;
  align-items: center;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.88);
`

const ListWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid #D9D9D9;
  border-radius: 4px;
  padding: 0.5rem;

  .table-cell {
    padding: 0.2rem 0 0.2rem 0;
  }

  .ant-tag-default {
    padding-top: 0.25em;
    padding-bottom: 0.2em;
  }

  .badge-icon {
    font-size: 1.1rem;
    vertical-align: -0.29em;
    color: #ff7b7b;
    margin-inline-start: 0.4rem;
  }

  .badge-icon:hover {
    color: red;
  }

  @media (min-width: 800px) { /* This breakpoint might need adjustment */
    grid-template-columns: 1fr 1fr 1fr;
    & .one {
    /* background-color: lime; */
    grid-column: 1;
    grid-row: 1;
    }
    & .two {
    /* background-color: red; */
    grid-column: 1 / -1;
    }
    & .three {
    grid-column: 2 / -1;
    /* background-color: blue; */
    grid-row: 1;
    }
  }

  @media (min-width: 1250px) { /* This breakpoint might need adjustment */
  grid-template-columns: auto auto 1fr; /* Assign space based on content for the first two items and remaining space for the third */
    .one,
    .two,
    .three {
      /* Reset properties as needed for larger screens */
      background-color: unset; /* Or specify a different color */
      grid-column: unset; /* Reset or specify a different value */
      grid-row: unset; /* Reset or specify a different value */
  }
}
`
interface ProductVariantSelectedList {
  formLoading: boolean;
  selectedVariantsActionEl?: JSX.Element;
}
export const ProductVariantsSelectedList: React.FC<ProductVariantSelectedList> = ({formLoading, selectedVariantsActionEl}) => {
  const [specModalId, setSpecModalId] = useState<BaseKey | undefined>();
  const { productVariants, deleteProductVariant } = useProductVariantsStore();

  const variantName = (name: string | undefined, artNr: string | undefined): string => {
    if (name && artNr) {
      return `${name} (${artNr})`;
    } else if (artNr) {
      return `${artNr}`;
    } else if (name) {
      return `${name}`;
    }
    return 'No name or article number';
  };

  const [firstChunk, secondChunk, thirdChunk] = splitArrayEvenlyIntoThreeChunks(productVariants) as [IProductItem[], IProductItem[], IProductItem[]];

  return (
    <>
      <Flex gap="small" justify="space-between" align="center" style={{ marginBottom: "0.3rem" }}>
        <Flex gap="small">
          <CheckCircleOutlined></CheckCircleOutlined>
          <Typography.Text color="#626262">Valda varianter</Typography.Text>
        </Flex>
        { selectedVariantsActionEl && selectedVariantsActionEl}
        {/* <Button type="primary" icon={<MonitorOutlined />} size="small" onClick={() => setSpecModalId("2")}/> */}
      </Flex>
        { 
        // formLoading ? (
        //   <RequestIndicatorContainer>
        //     <Spin />
        //   </RequestIndicatorContainer>
        // ) : 
        !firstChunk.length ? (
          <RequestIndicatorContainer>
            <Empty style={{padding: "0px"}} image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </RequestIndicatorContainer>
        ) : (
          <ListWrapper>
          <div className="one">
          <div style={{display: "flex", flexDirection: "column" }}>
            {firstChunk?.map((item) => (
              <div key={item?.id} className="table-cell">
                <span style={{width: "fit-content"}}>
                  <Tag color="default" closeIcon={<CloseCircleOutlined className="badge-icon" onClick={() => deleteProductVariant(item?.id)} />}>
                    {variantName(item?.name, item?.itemNo)}
                  </Tag>
                </span>
              </div>
            ))}
            </div>
          </div>
          <div className="two">
          <div style={{display: "flex", flexDirection: "column"}}>
            {secondChunk?.map((item) => (
              <div key={item?.id} className="table-cell">
              <span style={{width: "fit-content"}}>
                <Tag color="default" closeIcon={<CloseCircleOutlined className="badge-icon" onClick={() => deleteProductVariant(item?.id)} />}>
                  {variantName(item?.name, item?.itemNo)}
                </Tag>
              </span>
            </div>
            ))}
            </div>
          </div>
          <div className="three">
            <div style={{display: "flex", flexDirection: "column"}}>
            {thirdChunk?.map((item) => (
              <div key={item?.id} className="table-cell">
              <span style={{width: "fit-content"}}>
                <Tag color="default" closeIcon={<CloseCircleOutlined className="badge-icon" onClick={() => deleteProductVariant(item?.id)} />}>
                  {variantName(item?.name, item?.itemNo)}
                </Tag>
              </span>
            </div>
            ))}
            </div>
          </div>
        </ListWrapper>
      )}
      <SpecStatsModal specId={specModalId} onClose={() => setSpecModalId(undefined)}></SpecStatsModal>
    </>
  );
}

// const mockData: IProductItem[] = new Array(50).fill(0).map((item, index) => ({
//   id: index,
//   name: `${item.name} ${index}` || "Default Name",
//   artNr: item.artNr || "Default ArtNr",
//   itemNo: "Default ItemNodreerpreipjiejri ItemNodreerpreipjiejri",
//   secondaryName: "", // Add the missing properties
//   supplierItemNo: "",
//   shippingClass: "",
//   isComplete: false
// }));