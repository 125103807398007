import { CrudFilters, HttpError, useLink, useMany, useExport, useApiUrl, useCreate, CrudFilter, useTranslate, LogicalFilter } from "@refinedev/core";
import React, { useEffect, useState } from 'react'
import { EditButton, List, useModal, ImportButton, useImport, useTable, RefreshButton, ExportButton } from "@refinedev/antd";
import { Button, Card, Checkbox, Drawer, Form, Input, Progress, Select, Space, Table, Typography, message } from "antd";
import { IProduct, IProductItem, ITenant, IUser } from "interfaces";
import { JOB_RES_ID, JOB_TEXT_REWRITE, PRODUCT_ITEM_RES_ID, TENANT_RES_ID } from "utility/constants";
import styled from '@emotion/styled'
import { getUserInfoFromCache, stringToColor } from "utility/helper";
import { useLocaleStore } from "stores/localeStore";
import { MultiGenerateTextModal } from "components/modals/multiGenerateText";
import { FilterConfigurationModal } from "components/modals/filterConfig";
import { FilterOutlined, StopOutlined } from "@ant-design/icons";
import { addHubListener, startConnection, stopConnection } from "live-provider/signalRService";
import ImportFileModal from "components/modals/importFile";
import FilterDrawer from "components/filters/filterDrawer";
import Search from "antd/es/input/Search.js";

const TableHeader = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: space-between;

  .header-buttons {
    display: flex;
    gap: 0.75rem;
  }
`

const Dot = styled.div`
    height: 10px;
    width: 10px;
    background-color: ${props => props.color};
    border-radius: 1000px;
    display: inline-block;
`

const TableTitle = styled(Typography.Title)`
  margin-bottom: 0.75rem !important;
`

const StyledCard = styled(Card)`
  .ant-page-header-heading-left {
    display: none;
  }

  .ant-page-header-heading {
    display: none;
  }
`


const TextSpan = styled.span`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    max-width: 300px;
`

interface ISearch {
    name: string;
    customer: string;
    status: string;
}

export const ProductItemList: React.FC<{ record: IProduct | undefined }> = ({ record }) => {
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [globalFilters, setGlobalFilters] = useState<CrudFilter[]>([]);
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const t = useTranslate();

    // const { show, close, modalProps } = useModal();
    // const { show : showFilterConfig, close : closeFilterConfig, modalProps : filterConfigModalProps } = useModal();
    // const { show : showImportModal, close : closeImportModal, modalProps : importModalProps } = useModal();
    const localeState = useLocaleStore();

    const { mutate } = useCreate();
    
    const permanentFilters: CrudFilters = record
      ? [
          {
            field: "ProductId",
            operator: "eq",
            value: record?.id,
          },
        ]
      : [];
    
    const { tableProps, setFilters, setCurrent } = useTable<IProductItem, HttpError, ISearch>(
        {
            resource: PRODUCT_ITEM_RES_ID,
            liveMode: "auto",
            syncWithLocation: false,
            filters: {
              defaultBehavior: "replace",
              permanent: permanentFilters,
            },
            meta: { headers: { "lang": localeState.locale } },
            queryOptions: {
              // retry: 1,
            },
            successNotification: (data, values, resource) => {
              return false
            },
            errorNotification: (data, values, resource) => {
              message.error("Någonting gick fel när varianterna skulle hämtas.");
              return false
            },
        }, 
    );

    const pagination : any = tableProps?.pagination;

    const handleSearch = (newValue : any) => {
      if(newValue == ""){
        setFilters([]);
        return;
      }

      setFilters(data => {
        const castedData = data as LogicalFilter[];
        const fullSearchIndex = castedData.findIndex((x) => x.field === "fullSearch")

        if (fullSearchIndex > -1) {
          castedData[fullSearchIndex].value = newValue;
          return castedData
        }

        return [...data, { field: "fullSearch", operator: "contains", value: newValue }];
      });
      setCurrent(1);
    };

    const onSelectChange = (selectedRowKeys: React.Key[]) => {
        setSelectedRowKeys(selectedRowKeys);
    };
  
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    // const onImportModalFinish = async (data: any) => {
    //   closeImportModal();
    // }

    // const onTextRewriteFinish = async (data: any) => {
    //   close();
    //   mutate({
    //     resource: JOB_RES_ID,
    //     values: {
    //       Ids: selectedRowKeys,
    //       type: JOB_TEXT_REWRITE,
    //       context: data?.context
    //     },
    //     meta: { headers: { "lang": localeState.locale } }
    //   });
    //   setSelectedRowKeys([]);
    // }

    const clearFilter = () => {
      setTableIsLoading(true);
      setFilters([]);
      setTableIsLoading(false);
    }
    
    const closeFilter = (filters : any[]) => {
      setTableIsLoading(true);
      setFilterDrawerOpen(false);
      setFilters(filters);
      setTableIsLoading(false);
    };

    const showFilter = () => {
      setFilterDrawerOpen(true);
    };

    return (
      <StyledCard bordered={false} className="criclebox h-full">
      <TableTitle level={4}>Varianter</TableTitle>
        <TableHeader>
          <Search placeholder="Sök variant..." onSearch={handleSearch} allowClear enterButton={true}/>
          <div className="header-buttons">
            <>
              <Button
                type="primary"
                icon={<FilterOutlined />}
                onClick={showFilter}
              >
                Filter
              </Button>{" "}
              <Button
                type="primary"
                icon={<StopOutlined />}
                onClick={clearFilter}
              >
                Rensa
              </Button>{" "}
              {/* <ImportButton {...importProps} />{" "} */}
              {/* <ExportButton onClick={triggerExport} loading={exportLoading} />{" "} */}
              {/* <Button type="primary" onClick={showImportModal}>
                Importera filer
              </Button>{" "} */}
            </>
          </div>
        </TableHeader>
        <List title={"Varianter"} >
          <Table
            {...tableProps}
            loading={tableIsLoading}
            rowKey="id"
            rowSelection={rowSelection}
            pagination={{ ...pagination, showSizeChanger: true }}
            footer={() => (
              <span>
                Totalt antal varianter <strong>{pagination?.total}</strong>
              </span>
            )}
          >
            <Table.Column dataIndex="itemNo" title="Art.nr" />
            <Table.Column dataIndex="ean" title="EAN" />
            <Table.Column dataIndex="name" title="Namn" />
            <Table.Column dataIndex="groupName" title="Varugrupp" />
            <Table.Column dataIndex="groupNumber" title="Varugrupp Nr" />
            <Table.Column dataIndex="supplierItemNo" title="Lev Art.Nr" />
            {/* <Table.Column<IProductItem>
              dataIndex="isComplete"
              title="Hanterad"
              align="center"
              render={(_, record) => (
                <Space>
                  <Dot color={record.isComplete ? "#04AA6D" : "#FF0000"}></Dot>
                </Space>
              )}
            /> */}
            {/* <Table.Column<IProductItem>
              dataIndex="completeness"
              title="Status"
              render={(_, record) => <Progress percent={30} size="small" />}
            /> */}
            {/* <Table.Column<IProductItem>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                </Space>
              )}
            /> */}
          </Table>
        </List>
        {/* <ImportFileModal
          modalProps={importModalProps}
          onFinish={onImportModalFinish}
        ></ImportFileModal>
        <MultiGenerateTextModal
          modalProps={modalProps}
          onFinish={onTextRewriteFinish}
        ></MultiGenerateTextModal> */}
        <FilterDrawer
          visible={filterDrawerOpen}
          onClose={closeFilter}
          primaryType={{ name: "Grundfiltrering", key: "ProductItem" }}
          secondaryTypes={[
            { name: "Textfiltrering", key: "ProductItemTranslation" },
            { name: "Varugruppfiltrering", key: "ProductItemGroup" },
          ]}
        ></FilterDrawer>
      </StyledCard>
    );
};