import { BaseKey, HttpError, IResourceComponentsProps, useOne, useTranslate } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { useInvalidate } from "@refinedev/core";
import {
    Form,
    Input,
    Tabs,
    Card,
    Radio,
    Switch,
    InputNumber,
    Space,
    Divider,
    Tag,
    Select,
    Row,
    Col,
    Checkbox,
    Progress,
    Button,
    Image,
    Alert,
    notification,
    Tooltip,
} from "antd";
import { IProduct, IProductItem, ITenant, ProductStatus } from "interfaces";
import TextArea from "antd/lib/input/TextArea";
import { useLocaleStore } from "stores/localeStore";
import { copyToClipboard, getDescriptionFromLocale } from "utility/helper";
import RelationCard from "components/cards/relationCard";
import RelationSection from "components/productItems/sections/relationSection";
import useProductTabs from "components/productItems/useProductTabs";
import { PRODUCT_ITEM_RES_ID, PRODUCT_RES_ID, RESOURCES_RES_ID } from "utility/constants";
import styled from '@emotion/styled'
import CustomSelect from "components/common/customSelect";
import ProductCategoriesSelect from "components/common/productCategories/productCategoriesSelect";
import { cwd } from "process";
import FormItem from "antd/es/form/FormItem";
import ProductVariantsSelect from "components/common/productVariants/productVariantsSelect";
import { DividerLine } from "components/separators/dividerLine";
import Icon from "@ant-design/icons/lib/components/Icon";
import { FireOutlined, UnorderedListOutlined, DiffOutlined, AppstoreOutlined, CustomerServiceOutlined, FileTextOutlined, CopyOutlined, EditOutlined } from "@ant-design/icons";
import { SectionHeader } from "components/layout/header/sectionHeader";
import NoImage from '../../../assets/no-image.png';
import { useRef, useState } from "react";
import ContentLoader from "components/loaders/contentLoader";
import StickySaveButtons from "components/common/editDetails/stickySaveButtons";
import { OverviewIcon } from "components/icons/iconsVB";
import useUpdateProductStatus from "components/hooks/UpdateProductStatus";
import SpecStatsModal from "components/modals/specStats";
import VariantEditModal from "components/modals/variantEdit";

const EditCardContainer = styled('div')`
  // A short transition to make first content loading smoother
  transition: opacity 0.08s ease-out;
  &.form-loading-hidden {
    opacity: 0;
    visibility: hidden;
    pointer-events: none; // Prevent interaction with the hidden content
  }
  &.form-no-loading-visible {
    visibility: visible;
    opacity: 1;
  }
  .ant-breadcrumb {
    display: none;
  }
  .ant-page-header-heading {
    display: none;
  }
  .ant-page-header-content {
    padding-block-start: 0px;
  }
  & .ant-spin-container:first-child > .ant-card > .ant-card-body { 
    padding: 0px !important;
    margin: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none;
  }
  & .ant-spin-container:first-child > .ant-card:first-child { 
    padding: 0px !important;
    margin: 0px;
    border-radius: 0px;
    background-color: transparent !important;
    box-shadow: none;
  }
  // Make the save button full width
  & .ant-card-actions {
    background-color: transparent;
    .ant-space, 
    .ant-space-item, 
    .ant-btn {
      width: 100%;
    }
    .ant-space,
    .ant-btn {
      float: unset !important;
      margin-right: 0;
    }
  }
  .extra-space {
    margin-top: 1.5rem !important;
  }
`;

const ImageWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 4px;
  flex-direction: column;
  .ant-image-img {
    object-fit: contain;
    max-height: 173px;
    flex-basis: 173px; /* Initial height */
  }
`

// const EditDivider = styled(Divider)`
//   background-color: #f0f0f0;
//   margin: 1rem 0;
// `;

// const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
//   <>
//     {required ? <Tag color="error">Required</Tag> : <Tag color="warning">optional</Tag>}
//     {label}
//   </>
// );

// requiredMark={customizeRequiredMark}


export const ProductItemDetailsEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const localeState = useLocaleStore();
    const { productItemTabs, onTabChange, activeKey, id } = useProductTabs();
    const isFirstFormLoadingRef = useRef<boolean>(true);
    const [mutationError, setMutationError] = useState<HttpError | null>(null);
    const { updateProductStatus } = useUpdateProductStatus();
    const [variantModalIsOpen, setVariantModalIsOpen] = useState<boolean>(false);
    const invalidate = useInvalidate();

    const { formProps, saveButtonProps, formLoading, mutationResult  } = useForm<IProduct>({
        id: id,
        action: "edit",
        resource: PRODUCT_RES_ID,
        meta: { headers: { "lang": localeState.locale, "includes": "Categories,ProductItems,Brand,PictoGramData,MediaAssets,Variants" } },
        warnWhenUnsavedChanges: false,
        redirect: false,
        onMutationError: (error) => {
          setMutationError(error)
        },
        queryOptions: {
          onSuccess: () => { 
            isFirstFormLoadingRef.current = false; },
          onError: (error) => {
            isFirstFormLoadingRef.current = false; 
          }
        }
    });
    
    // Get the main variant search id, which is used to get/fetch the ean and supplier item number
    const mainVariantProductItemSearchId = formProps?.initialValues?.variants?.find((variant: IProductItem) => variant?.itemNo === formProps?.initialValues?.mainItemNo)?.id

    const { data: productItemData, isError: productItemIsError, isInitialLoading: isInitialLoadingProductItem } = useOne<IProductItem, HttpError>({
      resource: PRODUCT_ITEM_RES_ID,
      id: mainVariantProductItemSearchId,
      meta: { headers: { "lang": localeState.locale } },
      queryOptions: {
        retry: 2,
        enabled: !!mainVariantProductItemSearchId, 
      },
    });

    // Get the ean and supplier item article number from the product item data
    const { ean, supplierItemNo } = productItemData?.data ?? {}

    const isInitalLoading = formLoading && isFirstFormLoadingRef.current || isInitialLoadingProductItem;
    
    const onCategoryTreeChange = (categoryIds: number[]) => {
      formProps.form?.setFieldValue("categories", categoryIds)
    }
    const onProductVariantChange = (variantIds: number[]) => {
      formProps.form?.setFieldValue("variants", variantIds)
    }

    const onFinish = (values: any) => {
      formProps.onFinish?.({
        ...values, 
        categoryIds: values.categories,
        variantIds: values.variants
      });
    }

    const getPictogramName = (ghsKey : string) => {
      switch (ghsKey) {
        case 'ghs01':
          return 'GHS01 (Explosivt)';
        case 'ghs02':
          return 'GHS02 (Brandfarligt)';
        case 'ghs03':
          return 'GHS03 (Oxiderande)';
        case 'ghs04':
          return 'GHS04 (Gas under tryck)';
        case 'ghs05':
          return 'GHS05 (Frätande)';
        case 'ghs06':
          return 'GHS06 (Giftigt)';
        case 'ghs07':
          return 'GHS07 (Skadligt)';
        case 'ghs08':
          return 'GHS08 (Hälsofarligt)';
        case 'ghs09':
          return 'GHS09 (Miljöfarligt)';
        case 'signalWordDanger':
          return 'GHS 02';
        case 'signalWordWarning':
          return 'Varning';
        default:
          return ghsKey;
      }
    };

    const signalWordOptions = [
      { label: 'Ingen', value: 0 },
      { label: 'Varning', value: 1 },
      { label: 'Fara', value: 2 },
    ];
    
    return (
      <div style={{position:"relative"}}>
        <ContentLoader loading={isInitalLoading} />
        {
          !(!!mainVariantProductItemSearchId) && formProps?.initialValues?.productStatus == 0 && !formLoading &&
          <>
            <div style={{marginBottom: '1rem'}}>
              <Alert 
                showIcon={true} 
                message={<div style={{display: 'flex', gap: '1rem', alignItems: 'center'}}><span>Är detta en produkt som ska arkiveras?</span><Button onClick={() => updateProductStatus([id], 1)}>Arkivera</Button></div>}
                type="info" />
            </div>        
            <div style={{marginBottom: '1rem'}}>
              <Alert 
                showIcon={true} 
                message="Huvudvariant saknas på produkt" 
                description="Huvudvariant hittades inte bland varianterna på denna produkt, kontakta Digifi. En manuell ändring i Norce behöver genomföras." 
                type="error" />
            </div>
          </>
        }
        <Tabs
          activeKey={activeKey}
          type="card"
          items={productItemTabs}
          onChange={onTabChange}
        />
        <EditCardContainer id="top-1" className={`${isInitalLoading ? "form-loading-hidden" : "form-no-loading-visible"}`}>
          <Edit saveButtonProps={saveButtonProps}>
            <Form
              id="top-2"
              {...formProps}
              layout="vertical"
              onFinish={onFinish}
            >
              <StickySaveButtons 
                formLoading={formLoading}
                isInitalLoading={isInitalLoading}
                saveButtonProps={saveButtonProps}
                productId={id}
              />
              <Progress style={{width: "calc(100% - 110px)"}} percent={formProps?.initialValues?.completenessPercentage} />
              <Space
                wrap
                direction="vertical"
                size="large"
                style={{ display: "flex" }}
              >
                <Card
                  style={{ backgroundColor: "white" }}
                  bordered={false}
                  className="criclebox h-full"
                >
                  {/* <SectionHeader title="Produktöversikt" icon={<UnorderedListOutlined style={{ fontSize: "22px", color: "#848484" }}/>} /> */}
                  <SectionHeader title="Produktöversikt" icon={<OverviewIcon pathStroke="#848484" width="25px" height="25px"/>} />
                    <Row gutter={[16, 0]}>
                      <Col span={4} style={{backgroundColor: ""}}>
                        <ImageWrapper className="no-navigate-action">
                          <Image
                            src={formProps?.initialValues?.images[0]?.url}
                            fallback={NoImage}
                          />
                        </ImageWrapper>
                      </Col>
                      <Col span={8} >
                        <Form.Item label="Leverantörs artnr">
                          <Input
                            placeholder={""} disabled={true} value={supplierItemNo}
                          />
                        </Form.Item>
                        <Form.Item label="EAN">
                          <Input
                            placeholder="" disabled={true} value={ean}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={12}>
                        <Form.Item name={"mainItemNo"} label="Huvudartikelnummer">
                          <Input
                            placeholder="Huvudartikelnummer" disabled={true}
                          />
                        </Form.Item>
                            <Form.Item
                            label="Produktnamn Webb"
                            name="name"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Sekundär Benämning"
                            name="secondaryName"
                          >
                            <Input disabled={true} addonAfter={<CopyOutlined onClick={() => copyToClipboard(formProps.form?.getFieldValue("secondaryName"))}></CopyOutlined>} />
                        </Form.Item>
                      </Col>
                    </Row>

                    {/* <Row gutter={[16, 0]}>
                      <Col span={12}>
                        <Form.Item name="externalId" label="Jetshop Id">
                          <Input placeholder="Jetshop Id" disabled={true} />
                        </Form.Item>
                      </Col>
                    </Row> */}
            
                    <Form.Item
                      name="shippingMethod"
                      label="Fraktklass"
                      className="extra-space"
                    >
                      <Radio.Group>
                        <Radio value={0}>{t('0')}</Radio>
                        <Radio value={1}>{t('1')}</Radio>
                        <Radio value={2}>{t('2')}</Radio>
                        <Radio value={3}>{t('3')}</Radio>
                        <Radio value={4}>{t('4')}</Radio>
                        <Radio value={5}>{t('5')}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Card>

                  {/* <Form.Item label="Produktnamn Leverantör" name="secondaryName">
                    <Input />
                  </Form.Item> */}
                  <Card
                    style={{ backgroundColor: "white" }}
                    bordered={false}
                    className="criclebox h-full"
                  >
                    <SectionHeader title="Kategorier" icon={<AppstoreOutlined style={{ fontSize: "22px", color: "#848484" }}/>} />
                    <Form.Item name="categories" label="Välj kategorier">
                      <ProductCategoriesSelect
                        categories={formProps.initialValues?.categories}
                        onChange={onCategoryTreeChange}
                        formLoading={formLoading}
                      ></ProductCategoriesSelect>
                    </Form.Item>
                  </Card>
                  
                  <Card
                    style={{ backgroundColor: "white" }}
                    bordered={false}
                    className="criclebox h-full"
                  >
                    <SectionHeader title="Varianter" icon={<DiffOutlined style={{ fontSize: "22px", color: "#848484" }}/>} />
                    <Form.Item name="variants" label="Lägg till variant">
                      <ProductVariantsSelect
                        mutationError={mutationError}
                        variants={formProps.initialValues?.variants}
                        onChange={onProductVariantChange}
                        formLoading={formLoading}
                        selectedVariantsActionEl={
                          <Tooltip placement="topRight" title={"Variantnamn"}>
                          <Button
                            type="text"
                            icon={<EditOutlined />}
                            size="small"
                            onClick={() => setVariantModalIsOpen(true)}
                          />
                          </Tooltip>
                        }
                      ></ProductVariantsSelect>
                    </Form.Item>
                  </Card>

                  {/* <Row gutter={[32, 0]}>
                    <Col span={6}>
                      <Form.Item label="Förpackning längd" name="length">
                        <InputNumber min={0} step="1" addonAfter="mm" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Förpackning bredd" name="width">
                        <InputNumber min={0} step="1" addonAfter="mm" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Förpackning höjd" name="height">
                        <InputNumber min={0} step="1" addonAfter="mm" />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item label="Förpackning vikt" name="weight">
                        <InputNumber min={0} step="0.001" addonAfter="kg" />
                      </Form.Item>
                    </Col>
                  </Row> */}

                  <Card
                    id="top-1"
                    style={{ backgroundColor: "white" }}
                    bordered={false}
                    className="criclebox h-full"
                  >
                    <SectionHeader title="Artikelinformation" icon={<UnorderedListOutlined style={{ fontSize: "22px", color: "#848484" }}/>} />
                    <Form.Item name="brandId" label="Varumärke">
                      <CustomSelect
                        selectedLabel={formProps.initialValues?.brandName}
                        selectedId={formProps.initialValues?.brandId}
                        resource={RESOURCES_RES_ID}
                        entity="Brand"
                      ></CustomSelect>
                    </Form.Item>
                    <Form.Item label="Beskrivning" name="description">
                      <TextArea rows={10} spellCheck="true"></TextArea>
                    </Form.Item>
                    <Form.Item
                      label={`Leverantör beskrivning`}
                      name="descriptionSupplier"
                    >
                      <TextArea rows={10} spellCheck="true"></TextArea>
                    </Form.Item>

                    <Form.Item label="Anteckningar" name="internalComments">
                      <TextArea rows={5} spellCheck="true"></TextArea>
                    </Form.Item>
                    <Form.Item  label="Status" valuePropName="checked" name="isPublished">
                      <Switch size={"default"} checkedChildren="Publicerad" unCheckedChildren="Ej Publicerad" disabled={formProps?.initialValues?.completenessPercentage != 100}/>
                    </Form.Item>
                  </Card>

                  <Card
                    id="top-1"
                    style={{ backgroundColor: "white" }}
                    bordered={false}
                    className="criclebox h-full"
                  >
                    <SectionHeader title="Pictogram" icon={<FireOutlined style={{ fontSize: "22px", color: "red" }} />} />
                    <Row gutter={16}>
                      {Object.keys(formProps?.initialValues?.pictoGramData ?? {})?.map(
                        (ghsKey, index) => {
                          if (ghsKey == "dangerText" || ghsKey.includes("signal")) return null;
                          return (
                            <Col span={8} key={ghsKey}>
                              <Form.Item
                                name={["pictoGramData", ghsKey]}
                                valuePropName="checked"
                              >
                                <Checkbox>{getPictogramName(ghsKey)}</Checkbox>
                              </Form.Item>
                            </Col>
                          );
                        }
                      )}
                    </Row>
                    <Form.Item label="Signalord" name={['pictoGramData', 'signalWord']}>
                      <Radio.Group>
                        {signalWordOptions.map((option) => (
                          <Radio key={option.value} value={option.value}>
                            {option.label}
                          </Radio>
                        ))}
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label="Faroangivelse"
                      name={["pictoGramData", "dangerText"]}
                    >
                      <TextArea rows={5} spellCheck="true"></TextArea>
                    </Form.Item>
                  </Card>
                </Space>
              </Form>
            </Edit>
        </EditCardContainer>
        <VariantEditModal isOpen={variantModalIsOpen} onClose={() => setVariantModalIsOpen(false)}></VariantEditModal>
      </div>
    );
};