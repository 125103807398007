import { CrudFilters } from "@refinedev/core";
import { useState, useEffect } from "react";
import {
  FILE_RES_ID,
  PRODUCT_ITEM_RES_ID,
  PRODUCT_RES_ID,
} from "utility/constants";
import { RelationType } from "utility/helper";

interface UseRelationValues {
  addModuleFilter: CrudFilters;
  addModuleResource: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  typeOptions: RelationType[];
  isProductRelation: boolean;
}

const useHandleRelationValues = (
  type: RelationType,
): UseRelationValues => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [addModuleResource, setAddModuleResource] = useState<string>("");

  const isProductRelation = [
    RelationType.RelatedParts,
    RelationType.SpareParts,
    RelationType.Accessories,
  ].includes(type);

  let filters: CrudFilters = [];
  let typeOptions: RelationType[] = [];

  useEffect(() => {
    setAddModuleResource(isProductRelation ? PRODUCT_RES_ID : FILE_RES_ID);
  }, [isProductRelation]);

  if (
    type == RelationType.Manual ||
    type == RelationType.SafetySheet ||
    type == RelationType.DimensionalSheet
  ) {
    filters = [
      {
        field: "name",
        operator: "contains",
        value: searchValue,
      },
      {
        field: "extension",
        operator: "eq",
        value: ".pdf",
      },
    ];
    typeOptions = [
      RelationType.Manual,
      RelationType.SafetySheet,
      RelationType.DimensionalSheet,
    ];
  }

  if (type == RelationType.ProductImage) {
    filters = [
      {
        field: "name",
        operator: "contains",
        value: searchValue,
      },
      {
        field: "extension",
        operator: "ne",
        value: ".pdf",
      },
    ];
    typeOptions = [];
  }

  if (isProductRelation) {
    filters = [
      {
        field: "fullSearch",
        operator: "contains",
        value: searchValue,
      },
    ];
    typeOptions = [];
  }

  return {
    isProductRelation: isProductRelation,
    addModuleFilter: filters,
    addModuleResource,
    typeOptions: typeOptions,
    setSearchValue,
  };
};

export default useHandleRelationValues;