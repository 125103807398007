import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { Progress, Spin, Row, Col, Checkbox, Button } from 'antd';
import useHttpClient from 'components/hooks/useHttpClient';
import { CheckCircleFilled, ClearOutlined, CloseCircleFilled } from '@ant-design/icons';

const getColor = (props: any) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#195178';
    }
    return '#eeeeee';
};

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
`;

const UploadStatusContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    margin-top: 1rem;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: solid;
    background: #e6f4ff;
    border: 1px solid #91caff;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.88);
`;

const InnerToolbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
`;

interface ImageUploadResult {
    fileName: string;
    statusText: string;
    success: boolean;
}

const ImageUploader: React.FC = () => {
    const filesCurrentlyUploadedRef = React.useRef(0);
    const filesFinishedUploadedRef = React.useRef(0);
    const { makeHttpRequest } = useHttpClient();
    const [uploading, setUploading] = useState<boolean>(false);
    const [strictMode, setStrictMode] = useState<boolean>(true);
    const [imageUploadResults, setImageUploadResults] = useState<ImageUploadResult[]>([]);
    const progressPercent = filesCurrentlyUploadedRef.current === 0 ? 0 : Math.round((filesFinishedUploadedRef.current / filesCurrentlyUploadedRef.current) * 100);

    const onDropAccepted = useCallback(async (acceptedFiles: File[]) => {
        if (acceptedFiles.length === 0) {
            return;
        }
        filesCurrentlyUploadedRef.current = acceptedFiles.length;
        filesFinishedUploadedRef.current = 0;
        setUploading(true);
        for (let i = 0; i <= acceptedFiles.length; i++) {

            if(acceptedFiles[i] == null){
                continue;
            }

            if(acceptedFiles[i]?.size > 7 * 1024 * 1024){
                setImageUploadResults(prevState => [...prevState, {
                    fileName: acceptedFiles[i].name,
                    statusText: "Filen är för stor, max 7MB",
                    success: false
                }]);
                filesFinishedUploadedRef.current = filesFinishedUploadedRef.current + 1; 
                continue;
            }

            const fmData = new FormData();
            fmData.append("file", acceptedFiles[i]);
            if(strictMode){
                fmData.append("strictMode", "true");
            }else{
                fmData.append("strictMode", "false");
            }

            const response = await makeHttpRequest("POST", `/files`, fmData);

            if (response?.id){
                setImageUploadResults(prevState => [...prevState, {
                    fileName: acceptedFiles[i].name,
                    statusText: "",
                    success: true
                }]);
            }else{
                setImageUploadResults(prevState => [...prevState, {
                    fileName: acceptedFiles[i].name,
                    statusText: response?.errorObject?.message,
                    success: false
                }]);
            }
            filesFinishedUploadedRef.current = filesFinishedUploadedRef.current + 1; 
        }
        setUploading(false);
    }, [strictMode]);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({ accept: { 'image/*': [], 'application/pdf': ['.pdf'] }, onDropAccepted, disabled: uploading });

    return (
      <section>
        <InnerToolbar>
            <Checkbox checked={strictMode} onChange={() => setStrictMode(prev => !prev)}>Strikt uppladdningsläge</Checkbox>
            <Button type="primary" icon={<ClearOutlined />} onClick={() => setImageUploadResults([])}/>
        </InnerToolbar>
        <Container {...getRootProps({ isFocused, isDragAccept, isDragReject })}>
          <input {...getInputProps()} />
          <p>Dra filer hit eller klicka för att välja från filhanteraren</p>
        </Container>
        <div style={{ display: "flex", gap: "1rem", marginTop: "0.5rem" }}>
          {uploading && <Spin style={{ marginLeft: "5px" }} />}
          <Progress percent={progressPercent} status="active" />
        </div>
        <UploadStatusContainer>
          <h3>Status</h3>
          <Row gutter={[16,16]}>
            {imageUploadResults.map((result, index) => (
              <Col xs={12} md={6} key={index} className="gutter-row" span={6}>
                <div style={{fontWeight: 'bold'}}>
                  {result.fileName} {result.statusText && `(${result?.statusText})`}{" "}
                  {result.success ? <CheckCircleFilled style={{color: 'green'}}/> : <CloseCircleFilled style={{color: 'red'}} />}
                </div>
              </Col>
            ))}
          </Row>
        </UploadStatusContainer>
      </section>
    );
};

export default ImageUploader;