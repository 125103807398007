import React, { ComponentType, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled'
import { Button, Checkbox, CheckboxProps, Dropdown, Menu, Spin, Upload, UploadFile } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { useMediaQuery } from '@react-hook/media-query';
import MediaCard from 'components/cards/mediaCard';
import { IFile } from 'interfaces';
import { useModal } from '@refinedev/antd';
import UploadMediaModal from 'components/modals/uploadMedia';
import { FILE_RES_ID } from 'utility/constants';
import { BaseKey, CrudFilters, useInfiniteList } from '@refinedev/core';

const CardWrapper = styled('div')`
    .card-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 56px;
      padding: 0px 24px;
      background: #FAFAFA;
      border-radius: 8px 8px 0 0;
      border-bottom: 1px solid #f0f0f0;
   }

   .card-head .card-title {
      font-weight: 500;
      font-size: 16px;
    }

   .card-body {
      padding: 24px;
      background: white;
      border-radius: 0 0 8px 8px;
      display: grid;
      grid-template-columns: repeat(${(props : MediaGridStyleProps) => props.columnCount}, 1fr);
      grid-gap: 1rem;
   }

   .grid-item {
      padding: 0.5rem;
   }
`

interface MediaUploadProps {
  title: string;
  id?: BaseKey;
}

type MediaGridStyleProps = {
  columnCount: number
}

const MediaUploadSection: React.FC<MediaUploadProps> = ({ title, id }) => {
  const { show : showUploadModal, close : closeUploadModal, modalProps : uploadModalProps } = useModal();
  const [showAll, setShowAll] = useState(false);
  const isMobile = useMediaQuery('(max-width: 800px)');
  const isTablet = useMediaQuery('(min-width: 800px) and (max-width: 1400px)');
  const isDesktop = useMediaQuery('(min-width: 1400px) and (max-width: 1800px)');
  const columnCount = isMobile ? 3 : isTablet ? 5 : isDesktop ? 8 : 10;

  const filters : CrudFilters = !showAll
    ? [
        {
          field: "originId",
          operator: "eq",
          value: id ?? 0,
        },
      ]
    : [];

  const { data, isError, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteList<IFile>({
    resource: FILE_RES_ID,
    pagination: {
        pageSize: 24,
    },
    sorters: [{ field: "createdAt", order: "desc" }],
    filters: filters
  });

  const files = data?.pages?.flatMap((page) => page.data) ?? [];

  if (isLoading) {
    return <Spin></Spin>;
  }

  if (isError) {
    return <p>Sidan kunde inte laddas</p>;
  }

  const onUploadModalFinish = async (data: any) => {
    closeUploadModal();
  }

  return (
    <>
      <CardWrapper columnCount={columnCount}>
        <div className="card-head">
          <div className="card-title">{title}</div>
          <div className="card-action">
            {/* <Checkbox onChange={(e) => setShowAll(e.target.checked)}>Visa alla</Checkbox> */}
            <Button type="text" icon={<UploadOutlined />} onClick={showUploadModal}>
              Ladda upp filer
            </Button>
            <Button type="primary" onClick={() => fetchNextPage()} disabled={isFetchingNextPage || !hasNextPage}>
                {isFetchingNextPage ? "Laddar fler..." : "Ladda fler"}
            </Button>
          </div>
        </div>
        {
          isFetchingNextPage && <div>Laddar fler...</div>
        }
        <div className="card-body">
          {files?.map((file, i) => {
            return <MediaCard key={i} file={file}></MediaCard>;
          })}
        </div>
      </CardWrapper>
      <UploadMediaModal
        modalProps={uploadModalProps}
        onFinish={onUploadModalFinish}
        itemId={id}
      ></UploadMediaModal>
    </>
  );
};

export default MediaUploadSection;
