import { HttpError, useList } from "@refinedev/core";
import { Modal, Button, Form } from "antd";
import TextArea from "antd/es/input/TextArea";
import { ISettings } from "interfaces";
import { SETTINGS_RES_ID } from "utility/constants";
import { useLocaleStore } from "stores/localeStore";

export const MultiGenerateTextModal: React.FC<any> = ({ modalProps, onFinish }) => {
    const localeState = useLocaleStore();
    const [form] = Form.useForm();

    const { data, isLoading, isError } = useList<ISettings, HttpError>({
      resource: SETTINGS_RES_ID,
      meta: { headers: { lang: localeState.locale } },
      pagination: {
        pageSize: 1,
      },
    });

    const handleFinish = (values : any) => {
        onFinish(values);
    };

    return (
      <>
        <Modal {...modalProps} width={600} onOk={() => form.submit()}>
          <h3>Instruktionerna nedan kommer att användas vid generering av texter</h3>
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              "context": data?.data[0]?.context,
            }}
            onFinish={handleFinish}
          >
            <Form.Item label="AI instruktioner vid översättning" name="context">
              <TextArea rows={10}></TextArea>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
};