import styled from "@emotion/styled";
import { BaseKey } from "@refinedev/core";
import { Select } from "antd/lib";
import { useState } from "react";

interface BaseOption {
  value: BaseKey;
  label: string;
}

type FlexibleOption = BaseOption & {
  [key: string]: any;
};

interface SearchablSelectProps {
  options: FlexibleOption[];
  onChange:
    | ((value: any, option: FlexibleOption | FlexibleOption[]) => void)
    | undefined;
  placeholder: string;
  isLoading?: boolean;
  isTableCell: boolean;
  widthRem?: number;
  isDisabled?: boolean;
  isOpen?: boolean;
  value?: BaseKey;
}

const SearchableSelectWrapper = styled.div<{ isTableCell?: boolean, widthRem?: number }>`
  .ant-select-selector {
    border: ${(props) => props.isTableCell ? "0px solid transparent !important" : undefined};
    border-radius: ${(props) => (props.isTableCell ? "0px " : undefined)};
  }
  .ant-select {
    ${(props) => props.widthRem && `width: ${props.widthRem}rem;`}
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
`;

const tableCellStyle: React.CSSProperties = {
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  width: "100%",
  height: "100%",
  color: "#9ba7b4",
  left: 0,
  top: 0,
};

const SearchableSelect = ({
  options,
  onChange,
  placeholder,
  isLoading,
  isTableCell,
  widthRem,
  isDisabled,
  isOpen,
  value,
}: SearchablSelectProps) => {

  const filteredOptions = (
    input: string,
    option?: { label: string; value: BaseKey;}
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filterSort:
    | ((
        optionA: {
          value: BaseKey;
          label: string;
        },
        optionB: {
          value: BaseKey;
          label: string;
        }
      ) => number)
    | undefined = (optionA, optionB) =>
    (optionA?.label ?? "")
      .toLowerCase()
      .localeCompare((optionB?.label ?? "").toLowerCase());
      
  return (
    <SearchableSelectWrapper isTableCell={isTableCell} widthRem={widthRem}>
      <Select
        style={isTableCell ? tableCellStyle : undefined}
        showSearch
        value={value}
        optionFilterProp="label"
        placeholder={placeholder}
        onChange={onChange}
        filterOption={filteredOptions}
        filterSort={filterSort}
        options={options}
        loading={isLoading}
        disabled={isDisabled}
        open={isOpen}
        autoFocus={true}
        defaultOpen={true}
      />
    </SearchableSelectWrapper>
  );
};

export default SearchableSelect;
