import { useEffect, useState } from 'react';
import axios, { AxiosInstance } from 'axios';
import { useApiUrl } from '@refinedev/core';
import { useLocaleStore } from 'stores/localeStore';
import { getUserInfoFromCache } from 'utility/helper';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

const useHttpClient = () => {
  const localeState = useLocaleStore();
  const url = useApiUrl();
  const userInfo = getUserInfoFromCache();

  const instance = axios.create({
    baseURL: url,
    headers: {
      'Content-Type': 'application/json',
      lang: localeState.locale,
      Authorization: `Bearer ${userInfo.token}`,
    },
  });

  const makeHttpRequest = async (
    method: HttpMethod,
    url: string,
    data: any = null,
    headers: any = {}
  ) => {
    if (!instance) return;
    try {
      let requestData = data;
      
      if (data instanceof FormData) {
        // If data is FormData, set the appropriate headers
        headers['Content-Type'] = 'multipart/form-data';
      } else {
        // If data is not FormData, stringify it as JSON
        requestData = JSON.stringify(data);
        headers['Content-Type'] = 'application/json';
      }

      const response = await instance?.({
        method,
        url,
        data: requestData,
        headers,
      });

      return response?.data;
    } catch (error : any) {
      // Handle error, log it, or throw it as needed
      console.error("HTTP request failed:", error);
      // const errorObject = error?.response?.data;
      const errorObject = error?.response;
      return { errorObject }; // Return error response
    }
  };

  const makeHttpRequestThrowErrors = async (
    method: HttpMethod,
    url: string,
    data: any = null,
    headers: any = {}
  ) => {
    try {
      let requestData = data;

      if (data instanceof FormData) {
        headers['Content-Type'] = 'multipart/form-data';
      } else {
        requestData = JSON.stringify(data);
        headers['Content-Type'] = 'application/json';
      }

      const response = await instance({
        method,
        url,
        data: requestData,
        headers,
      });

      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorObject = error.message || error.response?.data;
        console.error("HTTP request failed with AxiosError:", errorObject);
        throw errorObject;
      } else {
        console.error("HTTP request failed with unknown error:", error);
        throw error;
      }
    }
  };

  return { makeHttpRequest, makeHttpRequestThrowErrors };
};

export default useHttpClient;