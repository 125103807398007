import { HttpError, useList } from "@refinedev/core";
import { SPECIFICATION_LABEL_RES_ID } from '../../../utility/constants';
import type { IProductItem } from "interfaces";
import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import { use } from "i18next";
import { useProductVariantsStore } from "stores/productVariantsStore";
import { ISpecification, ISpecificationLabel, IProductVideo } from "interfaces";
import { useLocaleStore } from "stores/localeStore";

interface ProductSpecificationSearchProps {
  handleAddNewSearchSpecification: (specification: ISpecificationLabel) => void;
}

// const data: { data: ISpecificationLabel[] } = { data : [ { id: "1", name: "Specification 1", }, { id: "2", name: "Specification 2", }, { id: "3", name: "Specification 3", }, ]}
// const isFetching = false;

export const ProductSpecificationSearch: React.FC<ProductSpecificationSearchProps> = ({handleAddNewSearchSpecification}) => {
  const [searchValue, setSearchValue] = useState<string>();
  const localeState = useLocaleStore();

  const { data, isFetching, isLoading, isError } = useList<ISpecificationLabel, HttpError>({
    resource: SPECIFICATION_LABEL_RES_ID,
    filters: [
      {
        field: "fullSearch",
        operator: "contains",
        value: searchValue,
      },
    ],
    queryOptions: {
      enabled: !!searchValue?.trim(),
      retry: 1,
    },
    meta: { headers: { "lang": localeState.locale } },
  });
 
  const handleSelectedSpecification= (id: string) => {
    const selectedValue = data?.data?.find((d) => d?.id === id);

    if (!selectedValue) {
      return;
    }

    handleAddNewSearchSpecification(selectedValue);
    setSearchValue(undefined);
  };


  // Debounce the search value update
  const debouncedSetSearchValue = useCallback(debounce((searchValue) => {
    if (searchValue?.trim() === "") {
      setSearchValue(undefined);
    } else {
      setSearchValue(searchValue);
    }
  }, 500), []);


  return (
    <div>
      <Select
        filterOption={false}
        placeholder="Sök efter etiketter"
        showSearch={true}
        value={searchValue}
        size={"large"}
        onSearch={debouncedSetSearchValue}
        onSelect={handleSelectedSpecification}
        loading={isFetching}
        notFoundContent={null}
        allowClear={true}
        onClear={() => setSearchValue(undefined)}
        autoClearSearchValue={true}
        options={
          // Hide the options(previous searchresults) if the searchValue is empty or undefined
          // data?.data is not updated when the searchValue is empty or undefined
          searchValue?.trim() === "" || !searchValue
          ? undefined
          : (data?.data ?? []).map((d) => ({
              value: d?.id,
              label: d?.name,
              // disabled: productVariants?.some(node => node?.id === d?.id),
          }))
        }
      />
    </div>
  )
}


