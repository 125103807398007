import { create } from 'zustand'
import { persist } from 'zustand/middleware'

interface LocaleState {
  locale: string
  locales: string[]
  setLocale: (by: string) => void
  setLocales: (by: string[]) => void
}

export const useLocaleStore = create<LocaleState>()(
  persist(
    (set) => ({
      locale: "sv",
      locales: [],
      setLocale: (newLocale) => set((state) => ({ locale: newLocale })),
      setLocales: (locales) => set((state) => ({ locales: locales }))
    }),
    {
      name: "lang-data-storage",
    }
  )
);