import { DownCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import React from "react";
import { useState } from "react";

const ExpandableWrapper = styled("div")`
  /* overflow-y: auto; */
  position: relative;
  margin-bottom: 1rem;
  margin-top: 0.3rem;
  border-bottom: 1px solid #dcdcdc;

  .expandable-container {
    width: 100%;
    height: 70px;
    overflow: hidden;
    transition: all 0.2s ease;
  }

  .expandable-container.expanded {
    height: 210px;
    overflow-y: auto;
  }

  .expand-button {
    font-size: 30px;
    color: #195178;
    z-index: 200;
    position: absolute;
    left: 50%;
    bottom: 5px;
    transform: translateX(-50%);
    transition: transform 0.2s ease, background-color 0.2s ease;
  }

  .expand-button:hover {
    opacity: 0.8;
  }

  .expand-button.expanded {
    transform: translateX(-50%) rotate(180deg);
  }

  .expand-button.disabled {
    pointer-events: none;
    opacity: 0.1;
  }

  .overlay-closed {
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    pointer-events: none;
    transition: opacity 0.2s ease;
  }

  .overlay-open {
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 20px;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    pointer-events: none;
    transition: opacity 0.2s ease;
  }
`;

interface ExpandableContainerProps {
  children: React.ReactNode;
  isDisabled?: boolean;
}

const ExpandableContainer: React.FC<ExpandableContainerProps> = ({
  children,
  isDisabled,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <ExpandableWrapper>
      <div className={`expandable-container ${isExpanded ? "expanded" : ""}`}>
        <DownCircleOutlined
          className={`expand-button ${isExpanded ? "expanded" : ""} ${
            isDisabled ? "disabled" : ""
          }`}
          onClick={toggleExpansion}
        />
        <div className={`${isExpanded ? "overlay-open" : "overlay-closed"}`} />
        {children}
      </div>
    </ExpandableWrapper>
  );
};

export default ExpandableContainer;
