import React from "react";
import { useLogin } from "@refinedev/core";
import {
    Row,
    Col,
    Layout as AntdLayout,
    Card,
    Typography,
    Form,
    Input,
    Button,
    Checkbox,
} from "antd";
import "./styles.css";
import { Logo } from "components/layout/logo";

const { Text, Title } = Typography;
export interface ILoginForm {
    username: string;
    password: string;
    remember: boolean;
}

export const Login: React.FC = () => {
    const [form] = Form.useForm<ILoginForm>();

    const { mutate: login } = useLogin<ILoginForm>();

    const CardTitle = (
        <Title level={3} className="title">
            Verktygsboden PIM
        </Title>
    );

    return (
      <AntdLayout className="layout">
        <Row
          justify="center"
          align="middle"
          style={{
            height: "100vh",
          }}
        >
          <Col span={0} lg={12} style={{ height: "100%" }}>
            <div className="image-container"></div>
          </Col>
          <Col span={24} lg={12} style={{ height: "100%" }}>
            <div className="white-container">
              <div className="container">
                <div className="imageContainer">
                  <Logo collapsed></Logo>
                </div>
                <Title level={3} className="title">
                  Logga in i verktygslådan
                </Title>
                <Form<ILoginForm>
                  layout="vertical"
                  form={form}
                  onFinish={(values) => {
                    login(values);
                  }}
                  requiredMark={false}
                  initialValues={{
                    remember: false,
                  }}
                >
                  <Form.Item
                    name="email"
                    label="E-postadress"
                    rules={[{ required: true, type: "email" }]}
                  >
                    <Input size="large" placeholder="E-postadress" />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    label="Lösenord"
                    rules={[{ required: true }]}
                    style={{ marginBottom: "12px" }}
                  >
                    <Input
                      type="password"
                      placeholder="●●●●●●●●"
                      size="large"
                    />
                  </Form.Item>
                  <div style={{ marginBottom: "12px" }}>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                      <Checkbox
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        Kom ihåg mig
                      </Checkbox>
                    </Form.Item>
                  </div>
                  <Button type="primary" size="large" htmlType="submit" block>
                    Logga in
                  </Button>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </AntdLayout>
    );
};
