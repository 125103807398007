import { BaseKey, useApiUrl, useCustomMutation, useSelect } from '@refinedev/core';
import { getUserInfoFromCache } from 'utility/helper';

export const useTextRewrite = () => {
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();
  const userInfo = getUserInfoFromCache();
  
  const rewriteText = (productIds: React.Key[], context: string) => {
    mutate({
      url: `${apiUrl}/actions/text-rewrite`,
      method: "post",
      successNotification: (data, values) => {
        return {
          message: "",
          description: "Open AI text rewrite jobb skapades",
          type: "success",
        };
      },
      values: {
        ids: productIds,
        context: context
      },
      config: {
        headers: { Authorization: `Bearer ${userInfo.token}`}
      }
    });
  };

  return { rewriteText };
};