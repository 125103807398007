import { HttpError, useGetIdentity, usePermissions, useList } from "@refinedev/core";
import { ArrowDownOutlined, ArrowUpOutlined, DollarOutlined, RightOutlined } from '@ant-design/icons';
import { Row, Col, Card, Avatar, Typography, Space, Statistic } from "antd";
import Paragraph from "antd/es/typography/Paragraph";

const { Text, Title } = Typography;

type Dictionary<T> = { [key: string]: T };

export const DashboardPage: React.FC = () => {
    // const { data: identity } = useGetIdentity<{
    //     id: string;
    //     name: string;
    //     avatar: string;
    // }>();
    // const permissions = usePermissions<string[]>();

    // const { data : timeRows, isLoading, isError } = useList<ITimeRow, HttpError>({
    //   resource: APP_WRITE_TIME_ROW_ID,
    //   filters: [
    //     {
    //       field: "invoiced",
    //       operator: "eq",
    //       value: false,
    //     },
    //   ],
    //   pagination: {
    //     pageSize: 10,
    //   },
    // });

    // const totalAmount = timeRows?.data?.reduce((accumulator, item) => accumulator + (item.amount * item.price), 0);
    
    // const statsGroupedByUser : Dictionary<ITimeRow[]> = timeRows?.data?.reduce((accumulator : any, obj) => {
    //   const { userId, ...rest } = obj;

    //   if (!accumulator[userId]) {
    //     accumulator[userId] = [];
    //   }

    //   accumulator[userId].push(rest);

    //   return accumulator;
    // }, {});

    // const { userSelectProps } = UserSelectOptions();

    // if(isLoading){
    //   return <h1>Laddar...</h1>
    // }

    //transform: translate3d(0px, -2px, 0px);
    
    return (
      <Card bordered={false} className="criclebox h-full">
        <Row>
          <Col xs={24} md={12} sm={24} lg={12} xl={14} className="mobile-24">
            <div className="h-full col-content p-20">
              <div className="ant-muse">
                <Title level={5}>Statistik kommer finns här inom kort!</Title>
                {/* <Title level={5}>Verktygsboden PIM</Title>
                <Paragraph className="mb-36">
                  Trevligt att du hittat hit! Vi har byggt detta PIM för att göra det enklare för dig att hitta rätt produkter och information. Vi har även lagt till en del funktioner som vi tror kan vara till nytta för dig.
                </Paragraph>
                <Paragraph className="mb-36">
                  Vi hoppas att du kommer att trivas här!
                </Paragraph> */}
              </div>
              {/* <div className="card-footer">
                <a className="icon-move-right" href="#">
                  Läs mer
                  {<RightOutlined />}
                </a>
              </div> */}
            </div>
          </Col>
          <Col
            xs={24}
            md={12}
            sm={24}
            lg={12}
            xl={10}
            className="col-img"
          ></Col>
        </Row>
      </Card>
      // <Row gutter={20}>
      //     <Col span={6}>
      //         <Card
      //             title="Identity"
      //             style={{ height: "300px", borderRadius: "15px" }}
      //             headStyle={{ textAlign: "center" }}
      //         >
      //             <Space align="center" direction="horizontal">
      //                 <Avatar size="large" src={identity?.avatar} />
      //                 <Text>{identity?.name}</Text>
      //             </Space>
      //         </Card>
      //     </Col>
      //     <Col span={6}>
      //         <Card
      //             title="Permissions"
      //             style={{ height: "300px", borderRadius: "15px" }}
      //             headStyle={{ textAlign: "center" }}
      //         >
      //             <Text>{permissions.data}</Text>
      //         </Card>
      //     </Col>
      // </Row>
      // <Row gutter={16}>
      //   <Col span={12}>
      //     <Card bordered={false}>
      //       <Statistic
      //         title="Total faktureringsbartid "
      //         value={totalAmount}
      //         valueStyle={{ color: "#3f8600" }}
      //         prefix={<DollarOutlined />}
      //         suffix="kr exkl. moms"
      //       />
      //     </Card>
      //   </Col>
      //   <Col span={12}>
      //     <Card bordered={false}>
      //       <Space direction="vertical">
      //         {Object.entries(statsGroupedByUser).map(([userId, timeRows]) => {
      //           const amountPerUser = timeRows?.reduce(
      //             (accumulator: any, item: any) =>
      //               accumulator + item.amount * item.price,
      //             0
      //           );
      //           return (
      //             <Statistic
      //               title={
      //                 userSelectProps.options?.find((u) => u.value === userId)
      //                   ?.label
      //               }
      //               value={amountPerUser}
      //               valueStyle={{ color: "#3f8600" }}
      //               prefix={<DollarOutlined />}
      //               suffix="kr exkl. moms"
      //             />
      //           );
      //         })}
      //       </Space>
      //     </Card>
      //   </Col>
      // </Row>
    );
};
