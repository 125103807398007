import { Modal } from "antd";

interface LockedMenuOverlayProps {
  isModalVisible: boolean;
  confirmUnlock: () => void;
  cancelUnlock: () => void;
}

const LockedMenuModal = ({isModalVisible, confirmUnlock, cancelUnlock}: LockedMenuOverlayProps) => {
  return (
    <Modal
      title="Varugruppsmenyn är låst"
      open={isModalVisible}
      onOk={confirmUnlock}
      onCancel={cancelUnlock}
      okText="Lås upp varugruppsmeny"
      cancelText="Avbryt"
    >
      <p>
        Varugruppsmenyn är låst på grund av att du har osparade änringar i din
        tabelldata. Byter du varugrupp kommer dina ändringar inte sparas.
      </p>
    </Modal>
  );
};

export default LockedMenuModal;
