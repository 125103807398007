import { IProduct, IProductItem } from 'interfaces';
import { create } from 'zustand';

// Define the store
interface ProductStore {
  product: IProduct | undefined;
  setActiveProduct: (newProduct: IProduct) => void;
}

// Create the store
export const useProductStore = create<ProductStore>((set) => ({
  product: undefined,
  setActiveProduct: (newProduct: IProduct) => set({ product: newProduct }),
}));