import React from 'react';
import { Divider } from 'antd';
import { DividerProps } from 'antd/lib/divider';

interface DividerLineProps extends DividerProps {
  lineIntensity?: "light" | "medium" | "strong";
}

const getLineColorStyle = (lineIntensity?: "light" | "medium" | "strong") => {
  switch (lineIntensity) {
    case "light":
      // Dont set any style, keep antd default style
      return undefined;
    case "medium":
      return { borderBlockStart: "1px solid rgba(72, 48, 24, 0.1)" };
    case "strong":
      return { borderBlockStart: "1px solid rgba(72, 48, 24, 0.4)" };
    default:
      return { borderBlockStart: "1px solid rgba(72, 48, 24, 0.1)" };
  }
};

/**
 * @param {"light" | "medium" | "strong"} lineColor - The color intensity of the divider line. Defaults to "medium".
 */
export const DividerLine: React.FC<DividerLineProps> = ({lineIntensity, ...props}) => {
  const lineColorStyle = getLineColorStyle(lineIntensity);
  return (
    <Divider {...props} style={{...props?.style, ...lineColorStyle}} />
  );
};