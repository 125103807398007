import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react';
import {
    Input,
    Space,
    Layout as AntdLayout,
    Button,
    Modal,
    Form,
} from "antd";
import { UserMenu } from './userMenu';
import { TenantDropdown } from './tenantDropdown';
import { LangDataDropdown } from './languageDataDropdown';
import { Notifications } from '../Notifications/notifications';
import { Logo } from 'components/layout/logo';
import useAuthCheck from 'components/hooks/AuthCheck';
import useMetadata from 'components/hooks/useMetadata';

const { Header: AntdHeader } = AntdLayout;

const HeaderWrapper = styled(AntdHeader)`
    height: 64;
    line-height: 64px;
    background: white;
    display: flex;
    justify-content: space-between;
    padding: 0px 24px;
    box-shadow: 0 0 3px rgba(0,0,0,0.18);
    z-index: 1000;
`

export const Header: React.FC = () => {
  useAuthCheck();
  useMetadata();
  
  return (
    <>
      <HeaderWrapper>
        <div style={{width: '285px'}}>
          <Logo collapsed></Logo>
        </div>
        <Space size={"middle"}>
          <LangDataDropdown></LangDataDropdown>
          <TenantDropdown></TenantDropdown>
          <Notifications></Notifications>
          <UserMenu></UserMenu>
        </Space>
      </HeaderWrapper>
    </>
  );
};