import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled'
import { Button } from 'antd';
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons';
import RelationCard from 'components/cards/relationCard';

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap
} from "react-grid-dnd";
import { useMediaQuery } from '@react-hook/media-query';
import { RelationAddModal } from 'components/modals/relationAdd';
import { useModal } from '@refinedev/antd';
import ModalMediaCard from 'components/cards/modalMediaCard';
import { BaseKey, useCreate, useCreateMany, useUpdate } from '@refinedev/core';
import { RELATION_RES_ID } from 'utility/constants';
import { RelationType } from 'utility/helper';
import useRelation from 'components/hooks/relationHook';

const CardWrapper = styled('div')`
    .card-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-height: 56px;
        padding: 0px 24px;
        background: #FAFAFA;
        border-radius: 8px 8px 0 0;
        border-bottom: 1px solid #f0f0f0;
   }

   .card-head .card-title {
      font-weight: 500;
      font-size: 16px;
    }

   .card-action .ant-btn-icon {
      transform: rotate(90deg);
   }

   .card-body {
      padding: 24px;
      background: white;
      border-radius: 0 0 8px 8px;
   }

   .grid-item {
      padding: 0.5rem;
   }
`

interface RelationSectionProps {
  id: BaseKey;
  title: string;
  type: RelationType;
  refetchRelationEdit?: () => void;
}

const RelationSection: React.FC<RelationSectionProps> = ({ id, title, type, refetchRelationEdit }) => {
  const { items, setItems } = useRelation(type);
  const [cardHeight, setCardHeight] = useState<number>(82);
  const cardRef = useRef<HTMLDivElement>(null);
  const { show : showEditModal, close : closeEditModal, modalProps : editModalProps } = useModal();

  const { mutate : relationCreate } = useCreate();
  const { mutate : relationOrderUpdate } = useUpdate();

  const isMobile = useMediaQuery('(max-width: 800px)');
  const isTablet = useMediaQuery('(min-width: 800px) and (max-width: 1400px)');
  const isDesktop = useMediaQuery('(min-width: 1400px)');

  const itemsCount = items?.length ?? 0;
  const boxesPerRow = isMobile ? 2 : isTablet ? 3 : isDesktop ? 4 : 1;
  const containerHeight = (Math.ceil(itemsCount / boxesPerRow)) * (cardHeight + 8);

  useEffect(() => {
    if (cardRef.current) {
      const height = cardRef.current.clientHeight;
      setCardHeight(height);
    }
  }, []);

  const reFetchData = () => {
    if (refetchRelationEdit) {
      setTimeout(() => {
        refetchRelationEdit();
      }, 300); // Delay , keep drop/move animation smooth
    }
  };


  const onOrderChange = (sourceId: string, sourceIndex: number, targetIndex: number) => {
    const previousState = items ?? []
    const nextState = swap(items ?? [], sourceIndex, targetIndex);
    setItems(nextState);
    relationOrderUpdate({
      resource: RELATION_RES_ID,
      // Use this instead of passing refetch triggered in onError/onSuccess
      // invalidates: ["all"],
      id,
      values: {
        destIds : nextState.map((item) => item.id),
        relationType: type
      },
      },
      {
        onError: (error, variables, context) => {
          // Move back the items on Error
          setItems(previousState)
        },
        onSuccess: (data, variables, context) => {
        // Refetch useOne<IProduct> resource: PRODUCT_RES_ID, in parent to keep the listOrder in sync with the server
        reFetchData();
        },
      }
    ,);
  };

  const onRelationAddFinish = async (selectedIds: number[]) => {
    relationCreate({
      resource: RELATION_RES_ID,
      values: {
        productId: Number(id),
        destIds: selectedIds,
        relationType: type
      },
    });
    closeEditModal();
  }

  return (
    <>
      <CardWrapper>
        <div className="card-head">
          <div className="card-title">{title}</div>
          <div className="card-action">
            <Button type="text" icon={<PlusOutlined />} onClick={showEditModal}>
              Lägg till
            </Button>
          </div>
        </div>
        <div className="card-body">
          <GridContextProvider onChange={onOrderChange}>
            <GridDropZone
              id="items"
              boxesPerRow={boxesPerRow}
              rowHeight={cardHeight + 8}
              style={{ height: `${containerHeight}px` }}
            >
              {items?.map((item, i) => {
                if (i == 0) {
                  return (
                    <GridItem key={item.id} className="grid-item">
                      <div ref={cardRef}>
                        <RelationCard
                          key={i}
                          item={item}
                          productItemId={id}
                          type={type}
                          refetchRelationEdit={reFetchData}
                        ></RelationCard>
                      </div>
                    </GridItem>
                  );
                }
                return (
                  <GridItem key={item.id} className="grid-item">
                    <RelationCard
                      key={i}
                      item={item}
                      productItemId={id}
                      type={type}
                      refetchRelationEdit={reFetchData}
                    ></RelationCard>
                  </GridItem>
                );
              }) ?? []}
            </GridDropZone>
          </GridContextProvider>
        </div>
      </CardWrapper>
      <RelationAddModal
        modalProps={editModalProps}
        cardComp={ModalMediaCard}
        onFinish={onRelationAddFinish}
        type={type}
        title={title}
      ></RelationAddModal>
    </>
  );
};

export default RelationSection;
