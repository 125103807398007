import { AuthBindings } from "@refinedev/core";
import axios from 'axios';
import { IUser } from "interfaces";
import { USERS_RES_ID } from "utility/constants";
import { decodeTokenAndSave, getUserInfoFromCache } from "utility/helper";

const API_URL = process.env.REACT_APP_API_URL ?? "http://localhost/v1";

export const authProvider: AuthBindings = {
    login: async ({ email, password }) => {
        
        const loginRequest = {
            username: email,
            password: password
        }

        try {
            const response = await axios.post(`${API_URL}/login`, loginRequest);
            decodeTokenAndSave(response);
            return {
                success: true,
                redirectTo: "/",
            };
        } catch (error : any) {
            const message = error?.response?.data?.message ?? "Something went wrong";
            return {
                success: false,
                error: {
                    message,
                    name: `${error?.response?.status} - ${error?.response?.statusText}`,
                },
            };
        }
    },
    logout: async () => {
        try {
            localStorage.removeItem("user");
        } catch (error: any) {
            return {
                success: false,
                error,
            };
        }
        return {
            success: true,
            redirectTo: "/login",
        };
    },
    onError: async (error) => {
        console.error(error);
        return { error };
    },
    //This also refreshes token if less than 1 hour remains only on refresh
    check: async () => {
        try {
            const userInfo = getUserInfoFromCache();
            const response = await axios.put(`${API_URL}/check-token`, {token: userInfo.token}, {headers: {Authorization: `Bearer ${userInfo.token}`}});
            decodeTokenAndSave(response);
        }catch(error : any){
            console.log(error)
            return {
                authenticated: false,
                error: {
                    message: "Check failed",
                    name: "Token not valid",
                },
                logout: true,
                redirectTo: "/login"
            };
        }
        
        return {
            authenticated: true
        };

    },
    updatePassword: async ({password: oldPassword, confirmPassword: newPassword}) => {
        try {
            const userInfo = getUserInfoFromCache();
            const response = await axios.put(`${API_URL}/change-password`, {newPassword: newPassword, oldPassword: oldPassword}, {headers: {Authorization: `Bearer ${userInfo?.token}`}});
            
            let message = "Password changed successfully.";
            
            if (response?.data?.message) {
                message = response?.data?.message
            }

            return {
                success: true,
                responseInfo: {
                    name: "Update Password Success",
                    message
                }
            };

        } catch (error: any) {
            console.log(error)
            let message = "Failed to update password.";
            if (error?.response?.data?.message) {
                message = error?.response?.data?.message;
            }

            return {
                success: false,
                responseInfo: {
                    name: "Update Password Error",
                    message,
                },
            };
        }
    },

    getPermissions: async () => {
        // const user = await account.get();
        // return user?.prefs?.role;
    },
    getIdentity: async () => {
        const userInfo = getUserInfoFromCache();
        try {
            const response = await axios.get(`${API_URL}/${USERS_RES_ID}/${userInfo?.userId}`, {headers: {Authorization: `Bearer ${userInfo.token}`}});
            return response?.data;
        } catch (error) {
            console.error(error);
        }
        return null;
    },
};
