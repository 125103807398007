import React, { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { BaseKey, useApiUrl } from '@refinedev/core';
import { getUserInfoFromCache } from 'utility/helper';
import axios from "axios";
import { IBase } from 'interfaces';
import { useLocaleStore } from 'stores/localeStore';

interface VbSelectProps<T> {
    placeholder?: string;
    selectedIds?: BaseKey[];
    labelExpression: (item: T) => string;
    keyExpression: (item: T) => string;
    resource: string;
    isMultiSelect?: boolean;
    onChange: (selectedIds: BaseKey[]) => void;
}

interface ISelectOption {
    value: string;
    label: string;
}

const VbSelect = <T extends IBase>({
    placeholder,
    selectedIds,
    labelExpression,
    keyExpression,
    resource,
    isMultiSelect,
    onChange
}: VbSelectProps<T>) => {
    const [options, setOptions] = useState<ISelectOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<BaseKey[]>([]);
    const [searchIsActive, setSearchIsActive] = useState<boolean>(false);
    const [isloading, setIsloading] = useState<boolean>(true);

    const localeState = useLocaleStore();
    const apiUrl = useApiUrl();
    const userInfo = getUserInfoFromCache();

    useEffect(() => {
        const fetchOptions = async () => {
            try {
                setIsloading(true);
                const data = await FetchData<T>({
                    apiUrl,
                    userInfo,
                    locale: localeState?.locale,
                    labelExpression,
                    keyExpression,
                    resource,
                    selectedIds
                });
                setSelectedOptions(data);
            } catch (error) {
                console.error('Error fetching options:', error);
            } finally {
                setIsloading(false);
            }
        };
        selectedOptions?.length === 0 && fetchOptions();
    }, [selectedIds]);

    return (
        <Select
            loading={isloading}
            value={selectedOptions}
            showSearch={true}
            mode={isMultiSelect ? "multiple" : undefined}
            placeholder={placeholder}
            style={{ width: '100%' }}
            options={options}
            filterOption={false}
            onFocus={async () => {
                if(searchIsActive){
                    return;
                }
                const data = await FetchData<T>({
                    apiUrl,
                    userInfo,
                    locale: localeState?.locale,
                    labelExpression,
                    keyExpression,
                    resource,
                    selectedIds: []
                });
                setOptions(data);
            }}
            onChange={(values) => {
                onChange(ensureArray(values));
                setSelectedOptions(values);
            }}
            onSearch={ async (value) => {
                const data = await FetchData<T>({
                    apiUrl,
                    userInfo,
                    locale: localeState?.locale,
                    labelExpression,
                    keyExpression,
                    resource,
                    searchValue: value
                });
                setOptions(data);
                setSearchIsActive(true);
            }}
        />
    );
};

function ensureArray(data: any): any[] {
    return Array.isArray(data) ? data : [data];
}

interface FetchInterface<T> {
    apiUrl: string;
    userInfo: any;
    locale: string;
    labelExpression: (item: T) => string;
    keyExpression: (item: T) => string;
    resource: string;
    selectedIds?: BaseKey[];
    searchValue?: string;
}

async function FetchData<T>({
  apiUrl,
  userInfo,
  locale,
  labelExpression,
  keyExpression,
  resource,
  selectedIds,
  searchValue
}: FetchInterface<T>) {
  try {
    const urlWithParams = new URL(`${apiUrl}/${resource}`);
    for(const id of selectedIds ?? []){
        if(!id) continue;
        urlWithParams.searchParams.append('ids', id.toString());
    }
    if(searchValue){
        urlWithParams.searchParams.append('filters', `fullSearch_like=${searchValue}`);
    }
    const finalUrl: string = urlWithParams.href;
    const response = await axios.get(finalUrl, {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
        Lang: locale,
      },
    });
    const data =
      response?.data?.data?.map((item: T) => {
        return {
          label: labelExpression(item),
          value: keyExpression(item),
        };
      }) ?? [];
    return data;
  } catch (error) {
    console.error("Error fetching options:", error);
  }
}

export default VbSelect;