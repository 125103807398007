import { useEffect } from "react";
import useDataGridStore from "stores/dataGridStore";

const useHandleDataGridExit = () => {
  const { clearDataGridStoreOnLeave } = useDataGridStore();

  useEffect(() => {
    // Clear data grid store on component unmount
    return () => {
      clearDataGridStoreOnLeave();
    };
  }, []);
};

export { useHandleDataGridExit };

