import useDataGridStore, { SaveStackDataResult } from "stores/dataGridStore";
import useHttpClient from "../useHttpClient";
import { useEffect, useRef, useState } from "react";
import { BaseKey } from "@refinedev/core";
import { notification } from "antd";
import { FlexibleIProductUpdatePayload } from "interfaces";

interface PreparedTableDataToSave {
  id: BaseKey;
  payload: FlexibleIProductUpdatePayload;
}

const useSaveStackData = () => {
  const { setUpdateDataSourceIsLoading, setUpdateDataSourceError, setReportsSummary ,setIsSaveReportModalVisible: setIsModalVisible } = useDataGridStore();
  const { makeHttpRequestThrowErrors } = useHttpClient();

  const saveStackData = async (patchData: PreparedTableDataToSave[]) => {
    if (!patchData || patchData?.length === 0) return;
    // if (!Array.isArray(patchData) || patchData.length === 0) return;
    setUpdateDataSourceIsLoading(true);

    try {
      const chunkReports: SaveStackDataResult[] = [];
      const batchSize = patchData.length > 10 ? 10 : patchData.length;
      const patchDataChunks = [];

      for (let i = 0; i < patchData.length; i += batchSize) {
        const chunk = patchData.slice(i, i + batchSize);
        patchDataChunks.push(chunk);
      }

      for (const chunk of patchDataChunks) {
        const patchPromises = chunk.map(async ({ id, payload }) => {
          if (id && payload) {
            try {
              const response = await makeHttpRequestThrowErrors(
                "PATCH",
                `/products/${id}`,
                payload,
              );
              return { status: 'fulfilled', id };
            } catch (error) {
              return { status: 'rejected', id, reason: error };
            }
          }
          return { status: 'invalid', id, reason: 'Invalid ID or payload' };
        });

        const results: PromiseSettledResult<{ status: string; id: BaseKey; reason?: any }>[] = await Promise.allSettled(patchPromises);

        const chunkReport = results.reduce((acc: SaveStackDataResult, result, index) => {
          if (result.status === 'fulfilled') {
            if (result.value.status === 'fulfilled') {
              acc.success.push(result.value.id);
            } else {
              acc.failure.push({ id: result.value.id, reason: result.value.reason });
            }
          } else {
            acc.failure.push({ id: chunk[index].id, reason: result.reason });
          }
          return acc;
        }, { success: [], failure: [] });

        chunkReports.push(chunkReport);
      }

      const reportsSummary = chunkReports.reduce((acc, report) => {
        acc.success = acc.success.concat(report.success);
        acc.failure = acc.failure.concat(report.failure);
        return acc;
      }, { success: [], failure: [] });

      setReportsSummary(reportsSummary);
  

    } catch (error: any) {
      notification.error({
        message: "Unexpected Error",
        description: error?.message || 'Something went wrong.'
      });
      setUpdateDataSourceError({ isError: true, errorMessage: "", error });
    } finally {
      setUpdateDataSourceIsLoading(false);
      setIsModalVisible(true);
    }
  };

  return { saveStackData };
};

const useFetchProductsByGroup = () => {
  const { setDataSource, setDataSourceIsLoading, setDataSourceError, clearDataGridStoreOnFetch, groupId } = useDataGridStore();
  const { makeHttpRequest } = useHttpClient();
  const fetchProducts = async (fetchGroupId?: BaseKey) => {
    if (!fetchGroupId) return;
    clearDataGridStoreOnFetch();
    setDataSourceIsLoading(true);
    try {
      let fetchedProducts = [];
      const pageSize = 100;
      
      const initialResponse = await makeHttpRequest(
        "GET",
        // "/products?ids=260&ids=0&_end=10&_start=0&_sort=id&_order=asc", // for testing
        `/products?productGroupId=${fetchGroupId}&_end=100&_start=0&_sort=id&_order=asc`,
        null,
        { "includes": "Categories,RelatedProductLookup" }
      );

      if (initialResponse?.errorObject) throw initialResponse.errorObject;
      
      const total = Math.ceil((initialResponse?.total ?? 0) / pageSize);

      fetchedProducts = initialResponse?.data ? [...initialResponse.data] : [];

      for (let i = 1; i < total; i++) {
        const response = await makeHttpRequest(
          "GET",
          // "/products/260&_end=100&_start=0&_sort=id&_order=asc", // for testing
          `/products?productGroupId=${fetchGroupId}&_end=${(i + 1) * pageSize}&_start=${i * pageSize}&_sort=id&_order=asc`,
          null,
          { "includes": "Categories,RelatedProductLookup" }
        );
        fetchedProducts = [...fetchedProducts, ...response?.data];
      }
      setDataSource(fetchedProducts);
    } catch (error: any) {
      setDataSourceError({ isError: true, errorMessage: error?.message, error });
      notification.error({
        message: "Error",
        description: error?.statusText || 'Something went wrong fetching products.'
      });
    } finally {
      setDataSourceIsLoading(false);
    }
  };

  return { fetchProducts, groupId };
};


// Extend the useFetchProductsByGroup hook to refetch products when the groupId changes
const useFetchProductsByGroupChange = () => {
  const { fetchProducts, groupId } = useFetchProductsByGroup();
  const prevGroupId = useRef<BaseKey | undefined>();

  useEffect(() => {
    if (!groupId || groupId === prevGroupId.current) return;
    fetchProducts(groupId);
    prevGroupId.current = groupId;
  }, [groupId, fetchProducts]);

  return { refetchProducts: fetchProducts };
};

export { useFetchProductsByGroup, useFetchProductsByGroupChange, useSaveStackData };



// useSaveStackData with non working error handling, using original makeHttpRequest with errorObject reurn instead of throwing error
// interface PreparedTableDataToSave {
//   id: BaseKey;
//   payload: FlexibleIProductUpdatePayload;
// }

// interface SaveStackDataResult {
//   success: BaseKey[];
//   failure: { id: BaseKey; reason: any }[];
// }

// const useSaveStackData = () => {
//   const { setUpdateDataSourceIsLoading, setUpdateDataSourceError, groupId } = useDataGridStore();
//   const { fetchProducts } = useFetchProductsByGroup();
//   const { makeHttpRequest } = useHttpClient();

//     const saveStackData = async (patchData: PreparedTableDataToSave[]) => {
//     console.log("Save data event triggered, not a real request, patchData to send:", patchData);
    
//     if (patchData?.length === 0) return;

//     setUpdateDataSourceIsLoading(true);
//     const chuncReports: SaveStackDataResult[] = [];
//     const batchSize = patchData.length > 1 ? 1 : patchData.length;
//     const patchDataChunks = [];
//     for (let i = 0; i < patchData.length; i += batchSize) {
//       const chunk = patchData.slice(i, i + batchSize);
//       patchDataChunks.push(chunk);
//     }

//     for (const chunk of patchDataChunks) {
//       try {
//         const patchPromises = chunk.map(async ({ id, payload }) => {
//           if (id && payload) {
//             try {
//               const response = await makeHttpRequest(
//                 "PATCH",
//                 "/products/" + id,
//                 payload,
//                 { "includes": "Categories,ProductItems" }
//               );
            
//             // console.log("Response from patch request try in chunk:", response);
//               // if (response?.errorObject) throw response.errorObject;

//               return ({ status: 'fulfilled', id });
//             } catch (error) {
//               console.log("Catch in chunk:", error);
//               return ({ status: 'rejected', id, reason: error });
//             }
//           }
//           return Promise.resolve({ status: 'invalid', id, reason: 'Invalid ID or payload' }); // Return a resolved promise for invalid patch data
//         });
  
//         const results = await Promise.allSettled(patchPromises);

//         console.log("Patch requests report:", results)

//         const chuncReport = results?.reduce((acc: SaveStackDataResult, result, index) => {
//           if (result.status === 'fulfilled') {
//             acc.success.push(patchData[index].id);
//           } else {
//             acc.failure.push({ id: patchData[index].id, reason: result.reason });
//           }
//           return acc;
//         }, { success: [], failure: [] });

//         chuncReports.push(chuncReport);
//       } catch (error: any) {
//         console.error("Erro r in patchPromises:", error);
//       } finally {
//         setUpdateDataSourceIsLoading(false);
//       }
//     }

//     const reportsSummary = chuncReports.reduce((acc, report) => {
//       acc.success = acc.success.concat(report.success);
//       acc.failure = acc.failure.concat(report.failure);
//       return acc;
//     }, { success: [], failure: [] })

//     console.log("Patch requests report final:", reportsSummary);
//   };

//   return { saveStackData };
// };