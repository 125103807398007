import React from 'react';
import { Button, Popconfirm } from 'antd'
import styled from '@emotion/styled'
import { ExportImageIcon, SaveIcon } from 'components/icons/iconsVB';
import { ButtonProps } from 'antd/lib/button';
import { useSyncJetshopImages } from 'components/hooks/UpdateJetshopImages';

const StickySaveButtonsWrapper = styled('div')`
  position: sticky;
  top: 1rem;
  height: 0px;
  width: 0px;
  float: right; 
  z-index: 10;
  & .sticky-content {
    position: absolute;
    top: 0;
    right: 0;
  }
  & .save-icons {
    display: flex;
    gap: 0.7rem;
  }
  .save-text-position {
    position: absolute;
    right: 0;
    width: 5px;
    top: 9px;
  }
  .save-text-vertical {
    transform: rotate(90deg);
    font-size: 0.7rem;
    display: inline-block;
  }
`;

interface StickySaveButtonsProps {
  formLoading: boolean;
  productId: React.Key;
  isInitalLoading: boolean;
  saveButtonProps: ButtonProps & {
    onClick: () => void;
  };
}

const StickySaveButtons: React.FC<StickySaveButtonsProps> = ({
  formLoading,
  productId,
  isInitalLoading,
  saveButtonProps,
}) => {

  const { syncJetshopImages } = useSyncJetshopImages();

  const exportImages = (e :  React.MouseEvent<HTMLElement> | undefined) => {
    syncJetshopImages([productId]);
  }

  return (
    <StickySaveButtonsWrapper>
      <div className="sticky-content">
        <div className="save-icons">
          <Popconfirm
            title="Uppdatera bilder i Norce"
            description={() => <><span>Denna handling kan innebära manuellt jobb i Norce.</span><br></br><span>Vill du fortsätta?</span></>}
            onConfirm={exportImages}
            okText="Ja"
            cancelText="Nej"
          >
            <Button
              title=""
              type="primary"
              shape="circle"
              size="large"
              icon={<ExportImageIcon pathStroke="white" />}
              // disabled={formLoading}
            />
          </Popconfirm>
          <Button
            title="Spara"
            type="primary"
            shape="circle"
            size="large"
            {...saveButtonProps}
            icon={<SaveIcon pathStroke="white" />}
            loading={formLoading && !isInitalLoading}
            disabled={formLoading && !isInitalLoading}
          />
          <span className="save-text-position">
            <span
              className="save-text-vertical"
              style={{ color: formLoading ? "gray" : "#195178" }}
            >
              {formLoading ? "LADDAR" : "SPARA"}
            </span>
          </span>
        </div>
      </div>
    </StickySaveButtonsWrapper>
  );
};

export default StickySaveButtons;
