import React, { useState } from 'react';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Avatar, Dropdown, message, Select, Space } from 'antd';
import styled from '@emotion/styled'
import { HttpError, useApiUrl, useGetIdentity, useOne } from "@refinedev/core";
import { decodeTokenAndSave, getUserInfoFromCache } from 'utility/helper';
import { ITenant, IUser } from 'interfaces';
import { USERS_RES_ID } from 'utility/constants';
import axios from 'axios';

const UserLabel = styled('span')`
    font-weight: 600;
    color: #000;
    font-size: 16px;
`

const UserAvatar = styled(Avatar)`
    margin-bottom: 2px;
`

export const TenantDropdown: React.FC = () => {
  const [selectedTenantId, setSelectedTenantId] = useState(getUserInfoFromCache().tenant);

  const apiUrl = useApiUrl();
  
  const { data, isLoading, isError } = useOne<IUser, HttpError>({
    resource: USERS_RES_ID,
    id: getUserInfoFromCache()?.userId
  });

  const options = data?.data?.allowedTenants?.map((tenant : ITenant) => {
    return {label: tenant.name, value: tenant.id?.toString()}
  })

  const handleChange = async (value: string) => {
    const { token } = getUserInfoFromCache();
    
    try {
      const response = await axios.put(
        `${apiUrl}/change-tenant`,
        {
          tenantId: value,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      decodeTokenAndSave(response)
      setSelectedTenantId(getUserInfoFromCache().tenant)
    }catch(e) {
      console.log(e)
    }
  };

  return (
    <Select
      size={"small"}
      value={selectedTenantId}
      style={{ width: 150 }}
      onChange={handleChange}
      options={options}
    />
  );
};