import React, { createContext, useContext, useState, ReactNode } from 'react';

interface CategoryNode {
  title: string;
  // slug?: string;
  key: number;
}

// Interface for the context type, reflecting the renaming and new method
interface ProductCategoriesSelectContextType {
  selectedCategoryNodes: CategoryNode[];
  addCategoryNode: (node: CategoryNode) => void;
  deleteCategoryNode: (key: number) => void;
  replaceCategoryNodes: (nodes: CategoryNode[]) => void;
}

const ProductCategoriesSelectContext = createContext<ProductCategoriesSelectContextType | undefined>(undefined);

interface ProductCategoriesSelectProviderProps {
  children: ReactNode;
}

export const ProductCategoriesSelectProvider: React.FC<ProductCategoriesSelectProviderProps> = ({ children }) => {
  const [selectedCategoryNodes, setSelectedCategoryNodes] = useState<CategoryNode[]>([]);

  const addCategoryNode = (node: CategoryNode) => {
    const exists = selectedCategoryNodes.some((item) => item.key === node.key);
    if (!exists) {
      setSelectedCategoryNodes((prev) => [...prev, node]);
    } else {
      console.warn("Category already exists in the list");
    }
  };

  const deleteCategoryNode = (key: number) => {
    setSelectedCategoryNodes((prev) => prev.filter((node) => node.key !== key));
  };

  const replaceCategoryNodes = (nodes: CategoryNode[]) => {
    setSelectedCategoryNodes(nodes);
  };

  return (
    <ProductCategoriesSelectContext.Provider value={{ selectedCategoryNodes, addCategoryNode, deleteCategoryNode, replaceCategoryNodes }}>
      {children}
    </ProductCategoriesSelectContext.Provider>
  );
};

// Custom hook to use the context, ensuring consumers are within the provider
export const useProductCategoriesSelectContext = (): ProductCategoriesSelectContextType => {
  const context = useContext(ProductCategoriesSelectContext);
  if (context === undefined) {
    throw new Error('useProductCategoriesSelectContext must be used within a ProductCategoriesSelectProvider');
  }
  return context;
};
