import { useNavigate } from "react-router-dom";

const useNavigateOnClassName = () => {
  const navigate = useNavigate();
  /**
   * This function is used to navigate to a specific page when *navigationTriggerClass
   * is present in the classList of the clicked element.
   * @param event
   * @param navigationTriggerClass to trigger navigation
   * @param path to navigate to
   * @param id optional id to append to the path
   */
  const handleNavigationOnElementClick = (event: React.MouseEvent<HTMLElement>, navigationTriggerClass: string, path: string, id: number | string) => {
    // Early return if recordId is falsy. The regex allows 0 and "0" as a valid recordId which will otherwise be falsy.
    if (!id && !/^0+$/.test(id + "")) { return }
    // Ensure tevent target is an HTMLElement and has classList available.
    if (!(event.nativeEvent.target instanceof HTMLElement) || !event.nativeEvent.target.classList) { return }
    // Only navigate if the clicked element is exactly navigationTriggerClass, ignoring clicks on its children.
    if (event.nativeEvent.target.classList.contains(navigationTriggerClass)) {
      navigate(`${path}${id ?? ""}`);
    } else {
      // console.log('No navigation');
    }
  };
  return handleNavigationOnElementClick;
};

export default useNavigateOnClassName;