import { HttpError, useList } from "@refinedev/core";
import { PRODUCT_ITEM_RES_ID } from '../../../utility/constants';
import type { IProductItem } from "interfaces";
import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import { use } from "i18next";
import { useProductVariantsStore } from "stores/productVariantsStore";
import { useLocaleStore } from "stores/localeStore";

export const ProductVariantsSearch = () => {
  const { addProductVariant, productVariants} = useProductVariantsStore();
  const [searchValue, setSearchValue] = useState<string>();
  const localeState = useLocaleStore();

  const { data, isFetching, isLoading, isError } = useList<IProductItem, HttpError>({
    resource: PRODUCT_ITEM_RES_ID,
    filters: [
      {
        field: "fullSearch",
        operator: "contains",
        value: searchValue,
      },
    ],
    queryOptions: {
      enabled: !!searchValue?.trim(),
      retry: 1,
    },
      meta: { headers: { "lang": localeState.locale } },
  });


  const handleSelectVariant = (id: string) => {
    const selectedValue = data?.data?.find((d) => d.id === id);

    if (!selectedValue) {
      return;
    }

    const newVariantsNode = {
      id: selectedValue?.id,
      itemNo: selectedValue?.itemNo,
      name: selectedValue?.name,
    } as IProductItem;

    addProductVariant(newVariantsNode);
    setSearchValue(undefined);
  };


  // Debounce the search value update
  const debouncedSetSearchValue = useCallback(debounce((searchValue) => {
    if (searchValue?.trim() === "") {
      setSearchValue(undefined);
    } else {
      setSearchValue(searchValue);
    }
  }, 500), []);

  const variantName = (name: string | undefined, artNr: string | undefined): string => {
    if (name && artNr) {
      return `${name} (${artNr})`;
    } else if (artNr) {
      return `${artNr}`;
    } else if (name) {
      return `${name}`;
    }
    return 'No name or artNr';
  };

  return (
    <div>
      <Select
        filterOption={false}
        placeholder="Sök efter varianter"
        showSearch={true}
        value={searchValue}
        size={"large"}
        onSearch={debouncedSetSearchValue}
        onSelect={handleSelectVariant}
        loading={isFetching}
        notFoundContent={null}
        allowClear={true}
        onClear={() => setSearchValue(undefined)}
        autoClearSearchValue={true}
        options={
          // Hide the options(previous searchresults) if the searchValue is empty or undefined
          // data?.data is not updated when the searchValue is empty or undefined
          searchValue?.trim() === "" || !searchValue
          ? undefined
          : (data?.data ?? []).map((d) => ({
              value: d?.id,
              label: `${variantName(d?.name, d?.itemNo)}`,
              disabled: productVariants?.some(node => node?.id === d?.id),
            }))
        }
      />
    </div>
  )
}