import NoImage from "assets/no-image.png";
import { Image } from "antd";
import { IFile } from "interfaces";

const GridCellImagesInfo = ({ value }: { value: IFile[] }) => {
  const imageCount = value?.length;
  const previewUrl = value[0]?.previewUrl;
  const showImage = value.length > 0;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {showImage && (
        <div
          style={{
            height: "28px",
            width: "28px",
            overflow: "hidden",
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            border: "1px solid #dcdcdc",
          }}
        >
          <Image
            preview={false}
            fallback={NoImage}
            src={previewUrl}
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              objectPosition: "center center",
            }} // Ensure the image fits within the container while maintaining aspect ratio
          />
        </div>
      )}
      <span style={{ marginLeft: "10px" }}>
        {imageCount === 0 ? "-" : imageCount}
      </span>
    </div>
  );
};

export default GridCellImagesInfo;
