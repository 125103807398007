import { CrudFilters, HttpError, useMany, useExport } from "@refinedev/core";
import React, { useState } from 'react'

import { EditButton, List, useTable } from "@refinedev/antd";
import { Button, Card, Checkbox, Drawer, Form, Input, Select, Space, Table } from "antd";
import { IUser } from "interfaces";
import { USERS_RES_ID } from "utility/constants";
import styled from '@emotion/styled'
import { stringToColor } from "utility/helper";

const TableHeader= styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
interface ISearch {
    name: string;
    customer: string;
    status: string;
}

export const UsersList: React.FC = () => {
    const { tableProps, searchFormProps } = useTable<IUser, HttpError, ISearch>(
        {
            resource: USERS_RES_ID,
            syncWithLocation: false
        }
    );

    return (
      <Card bordered={false} className="criclebox h-full"> 
        <List title={"Användare"}>
          <Table
            {...tableProps}
            rowKey="id"
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "50", "100"],
            }}
          >
            <Table.Column<IUser> dataIndex="id" title="Id" />
            <Table.Column<IUser> dataIndex="username" title="Email" sorter/>
            <Table.Column<IUser>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton
                    hideText
                    size="small"
                    recordItemId={record.id}
                  />
                  {/* <DeleteButton hideText size="small" recordItemId={record.id} disabled={true}/> */}
                </Space>
              )}
            />
          </Table>
        </List>
      </Card>
    );
};