import { HttpError, IResourceComponentsProps, useOne, useUpdate, useTranslate } from "@refinedev/core";
import {
    Form,
    Input,
    Tabs,
    Card,
    Space,
    Divider,
    Typography,
    Button,
    Flex,
    Checkbox
} from "antd";
import { ISpecification, ISpecificationLabel,  } from "interfaces";
import { useLocaleStore } from "stores/localeStore";
import { getDescriptionFromLocale } from "utility/helper";
import RelationCard from "components/cards/relationCard";
import RelationSection from "components/productItems/sections/relationSection";
import useProductTabs from "components/productItems/useProductTabs";
import { SPECIFICATION_RES_ID } from "utility/constants";
import styled from '@emotion/styled'
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Text } from "components/common/text";
import { useReducer } from "react";
import { EditProductInfoSpecifications } from "components/common/productSpecifications/editProductInfoSpecifications/editProductInfoSpecifications";
import { EditProductVideoSpecifications } from "components/common/productSpecifications/editVideoSpecifications/editProductVideoSpecifications";
const { Title } = Typography;

const SpecificationCard = styled(Card)`
  .form-label {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  .ant-input {
    /* margin: 0.5rem 0; */
  }
`;

export const ProductItemSpecificationEdit: React.FC<IResourceComponentsProps> = () => {
    const { productItemTabs, onTabChange, activeKey, id } = useProductTabs();
    return (
      <>
        <Tabs
          activeKey={activeKey}
          type="card"
          items={productItemTabs}
          onChange={onTabChange}
        />
      <Space
          wrap
          direction="vertical"
          size="large"
          style={{ display: "flex" }}
        >
        <EditProductInfoSpecifications id={id} />
        <EditProductVideoSpecifications productId={id} />
      </ Space>
      </>
    );
};

