import React from 'react';
import { Spin } from 'antd';

type ContentLoaderProps = {
  loading: boolean;
};

const ContentLoader: React.FC<ContentLoaderProps> = ({ loading }) => {
  if (!loading) {
    return <></>;
  }
  return (
    <div
      style={{
        position: 'absolute',
        top: '25vh',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 1000,
      }}
    >
      <Spin size="large" />
    </div>
  );
};

export default ContentLoader;
