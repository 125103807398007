import React from 'react';
import styled from '@emotion/styled'
import {
  Typography,
} from "antd";

const SectionHeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.7rem;
  margin-bottom: 1.2rem;
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
`;

interface SectionHeaderProps {
  title: string;
  icon: React.ReactNode;
}

export const SectionHeader: React.FC<SectionHeaderProps> = ({ title, icon, ...props }) => {
  return (
    <SectionHeaderWrapper {...props}>
      {icon}
      <Typography.Title level={4} color="#626262">{title}</Typography.Title>
    </SectionHeaderWrapper>
  );
}