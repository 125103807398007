import { MinusOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, DatePickerProps, Input, InputNumber, Select, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { RangePickerProps } from 'antd/es/date-picker';
import { IProperty } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { formatDate } from 'utility/helper';

interface FilterSectionProps {
    filterProperties: IProperty[];
    activeFilters: any[];
    updateFilterState: (filters : any[]) => void;
}

function parseUrlFilters() {
    const params = new URLSearchParams(window.location.search);
    const filterRegex = /^filters\[(\d+)\]\[(field|operator|value)\]$/;

    const filters : any[] = [];

    params.forEach((value, key) => {
        const match = key.match(filterRegex);

        if (match) {
        const index = parseInt(match[1], 10);
        const property = match[2];

        if (!filters[index]) {
            filters[index] = {};
        }

        filters[index][property] = value;
        }
    });

    return filters;
}

const FilterSection: React.FC<FilterSectionProps> = ({ filterProperties, activeFilters, updateFilterState}) => {

    const stringOperatorOptions = [{label: 'Innehåller', value: 'contains'}, {label: 'lika med', value: 'eq'}, {label: 'inte lika med', value: 'ne'}]
    const defaultOperatorOptions = [{label: 'lika med', value: 'eq'}, {label: 'inte lika med', value: 'ne'}, {label: 'mindre än', value: 'lt'}, {label: 'större än', value: 'gt'}]
    const booleanOperatorOptions = [{label: 'lika med', value: 'eq'}, {label: 'inte lika med', value: 'ne'}]
    
    useEffect(() => {
        const urlFilters = parseUrlFilters();
        if (urlFilters.length <= 0) {
            return;
        }
        let tempFilters : any[] = [];
        urlFilters.forEach((urlFilter) => {
            const index = filterProperties.findIndex((filter) => filter.name === urlFilter.field);
            if (index !== -1) {
                tempFilters.push(urlFilter);
            }
        });
        if(tempFilters && tempFilters.length > 0) {
            updateFilterState(tempFilters);
        }
    }, []);

    const fieldOptions = filterProperties?.map((prop) => ({
        value: prop.name,
        label: prop.displayName
    }));

    const getFieldType = (field : string) => {
        const property = filterProperties?.find((prop) => prop.name === field);
        return property?.type;
    }

    const getEnumValues = (field : string) => {
        const property = filterProperties?.find((prop) => prop.name === field);
        return property?.enumValues;
    }

    const removeFilter = (index : number) => {
        const newFilterState = [...activeFilters];
        newFilterState.splice(index, 1);
        updateFilterState(newFilterState);
    };

    const numberChanged = (newValue : number, index : number) => {
        const newFilterState = [...activeFilters];
        newFilterState[index].value = newValue;
        updateFilterState(newFilterState);
      };
  
      const valueChanged = (event : any, index : number) => {
        const newValue = event.target.value;
        const newFilterState = [...activeFilters];
        newFilterState[index].value = newValue;
        updateFilterState(newFilterState);
      };
  
      const checkboxChanged = (event : CheckboxChangeEvent, index : number) => {
        const value = event.target.checked ? 'true' : 'false';
        const newFilterState = [...activeFilters];
        newFilterState[index].value = value;
        updateFilterState(newFilterState);
      };
  
      const dateChanged = (value: DatePickerProps['value'] | RangePickerProps['value'], index : number) => {
        const newFilterState = [...activeFilters];
        newFilterState[index].value = formatDate(value?.toString() ?? "");
        updateFilterState(newFilterState);
      };
  
      const operatorChanged = (value : any, index : number) => {
        const newFilterState = [...activeFilters];
        newFilterState[index].operator = value;
        updateFilterState(newFilterState);
      };

      const enumChanged = (value : number, index : number) => {
        const newFilterState = [...activeFilters];
        newFilterState[index].value = value;
        updateFilterState(newFilterState);
      };

      const fieldChanged = (value : any, index : number) => {
        const newFilterState = [...activeFilters];
        newFilterState[index].field = value;
        newFilterState[index].value = null;
        updateFilterState(newFilterState);
      };
  
      const getOperatorOptions = (field : string) => {
        const property = filterProperties?.find((prop) => prop.name === field);
        if (property?.type == 'String') {
          return stringOperatorOptions;
        } else if(property?.type == 'Boolean' || property?.type == 'Enum') {
            return booleanOperatorOptions;
        }else {
          return defaultOperatorOptions;
        }
      }

    return (
      <>
        {activeFilters?.map((filter, index) => {
          return (
            <Space
              key={index}
              style={{
                marginBottom: `${
                  index != activeFilters?.length - 1 && "0.5rem"
                }`,
              }}
            >
              <Select
                value={filter?.field}
                showSearch
                optionFilterProp="label"
                allowClear
                onChange={(e) => fieldChanged(e, index)}
                placeholder="Fält"
                style={{ width: 140 }}
                options={fieldOptions}
              />
              <Select
                value={filter?.operator}
                showSearch
                optionFilterProp="label"
                allowClear
                onChange={(e) => operatorChanged(e, index)}
                placeholder="Operator"
                style={{ width: 140 }}
                options={getOperatorOptions(filter?.field)}
              />
              {getFieldType(filter?.field) === "Boolean" && (
                <Checkbox onChange={(e) => checkboxChanged(e, index)}>
                  Värde
                </Checkbox>
              )}
              {(getFieldType(filter?.field) === "Double" ||
                getFieldType(filter?.field) === "Int32") && (
                <InputNumber
                  value={filter?.value}
                  placeholder="Värde"
                  min={0}
                  onChange={(v) => numberChanged(v, index)}
                />
              )}
              {getFieldType(filter?.field) === "String" && (
                <Input
                  value={filter?.value}
                  placeholder="Värde"
                  onChange={(e) => valueChanged(e, index)}
                />
              )}
              {getFieldType(filter?.field) === "DateTime" && (
                <DatePicker showTime onOk={(v) => dateChanged(v, index)} />
              )}
              {getFieldType(filter?.field) === "Enum" && (
                <Select
                  value={filter?.value}
                  showSearch
                  optionFilterProp="label"
                  allowClear
                  onChange={(v) => enumChanged(v, index)}
                  placeholder="Välj värde"
                  style={{ width: 140 }}
                  options={getEnumValues(filter?.field)}
                />
              )}
              <Button
                type="primary"
                size="small"
                icon={<MinusOutlined />}
                onClick={() => removeFilter(index)}
              />
            </Space>
          );
        })}
      </>
    );
};

export default FilterSection;
