import { OpenAIOutlined, TranslationOutlined } from "@ant-design/icons";
import { useModal } from "@refinedev/antd";
import { MenuProps } from "antd";
import { Dropdown } from "antd/lib";
import { useTextRewrite } from "components/hooks/textRewrite";
import { MultiGenerateTextModal } from "components/modals/multiGenerateText";
import useDataGridStore from "stores/dataGridStore";

export const useMultiGridMenuItems = () => {
  const items: MenuProps["items"] = [
    {
      label: "Generera texter",
      key: 1,
      icon: <OpenAIOutlined />,
    },
    {
      label: "Översätt texter",
      key: 2,
      icon: <TranslationOutlined />,
    },
  ];

  return { menuItems: items };
};

export const SelectedRowsActions = () => {
  const { rewriteText } = useTextRewrite();
  const { menuItems } = useMultiGridMenuItems();
  const { show: showTranslateContextModal, close: closeTranslateContextModal, modalProps: translateContextModalProps } = useModal();
  const { checkboxColumnActive, selectedRows, setSelectedRows } = useDataGridStore()
  const selectedRowsCount = selectedRows ? Object.keys(selectedRows)?.length : 0;

  const onMultiButtonClick = (e: any) => {
    if (e.key == 1) {
      showTranslateContextModal();
    } else if (e.key == 2) {
      console.log("Översätt texter modal");
    }
  };

  const onTextRewriteFinish = async (data: any) => {
    closeTranslateContextModal();
    rewriteText(Object.keys(selectedRows ?? {}), data?.context);
    setSelectedRows({});
  }

  return (
    <>
      <Dropdown.Button
        menu={{ items: menuItems, onClick: onMultiButtonClick }}
        disabled={!checkboxColumnActive || selectedRowsCount === 0}
        onClick={() => showTranslateContextModal()}
      >
        Generera texter
      </Dropdown.Button>
      <MultiGenerateTextModal
        modalProps={translateContextModalProps}
        onFinish={onTextRewriteFinish}
      ></MultiGenerateTextModal>
    </>
  );
};
