import { HttpError, useGetIdentity, usePermissions, useList } from "@refinedev/core";
import { ArrowDownOutlined, ArrowUpOutlined, DollarOutlined, GlobalOutlined, LockOutlined } from '@ant-design/icons';
import { Alert, Row, Col, Card, Avatar, Popover, Typography, Space, Statistic, Form, TabsProps, Tabs, Flex, Divider, Button, Input } from "antd";
import { SETTINGS_RES_ID } from "utility/constants";
import { LangDropdown } from "components/layout/header/languageDropdown";
import { useLocaleStore } from "stores/localeStore";
import { ISettings } from "interfaces";
import TextArea from "antd/es/input/TextArea";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import { useUpdatePassword } from "@refinedev/core";
import type {UpdatePasswordFormTypes} from "@refinedev/core/src/interfaces/auth";
import Paragraph from "antd/es/typography/Paragraph";
import { useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

const GlobalSettings: React.FC = () => {
  const localeState = useLocaleStore();

  const { data, isLoading, isError } = useList<ISettings, HttpError>({
    resource: SETTINGS_RES_ID,
    meta: { headers: { lang: localeState.locale } },
    pagination: {
      pageSize: 10,
    },
  });

  const { formProps, saveButtonProps } = useForm<ISettings>({
    meta: { headers: { lang: localeState.locale } },
    action: "edit",
    id: data?.data[0]?.id,
    resource: SETTINGS_RES_ID,
  });

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      breadcrumb={<></>}
      recordItemId={data?.data[0]?.id}
      title="Redigera"
      headerButtons={({ defaultButtons }) => (
        <>
          {defaultButtons}
          {/* <Button type="primary">Custom Button</Button> */}
        </>
      )}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
        }}
      >
        <Form.Item label="AI instruktioner vid översättning" name="context">
          <TextArea rows={10}></TextArea>
        </Form.Item>
      </Form>
    </Edit>
  );
};

// 
export interface IUpdatePasswordForm {
  oldPassword: string;
  newPassword: string;
}

export interface IUpdatePasswordResponse {
  success: boolean;
  responseInfo?: {
    name?: string;
    message?: string;
  };
}

const PersonalSettings: React.FC = () => {
  const [form] = Form.useForm<IUpdatePasswordForm>();
  const [updatePasswordAlert, setUpdatePasswordAlert] = useState<JSX.Element| undefined | null>(<></>);
  const { mutate: updatePassword, isLoading } = useUpdatePassword<UpdatePasswordFormTypes>();
  
  const handleUpdatePassword = (oldPassword: string, newPassword: string) => {
    updatePassword(
      {password: oldPassword, confirmPassword: newPassword},
      {
        onSuccess: (data: IUpdatePasswordResponse) => {
          if (!data.success) {
            setUpdatePasswordAlert(
            <Alert
              style={{marginTop: "1rem"}}
              message={data?.responseInfo?.name ?? "Error"}
              type="error"
              description={data?.responseInfo?.message ?? "Failed to update password."}
              showIcon
              closable
              onClose={() => setUpdatePasswordAlert(<></>)}
            />
            )
          } else {
            setUpdatePasswordAlert(
              <Alert
                style={{marginTop: "1rem"}}
                message={data?.responseInfo?.name ?? "Success"}
                type="success"
                description={data?.responseInfo?.message ?? "Failed to update password."}
                showIcon
                closable
                onClose={() => setUpdatePasswordAlert(<></>)}
              />
            )
          }
        },
      },
    );
  };

  const newPasswordContentInfo = (
    <Text>Ditt lösenord måste innehålla minst 8 tecken, inkludera både stora och små bokstäver, ett nummer och ett specialtecken (exempel: @, #, $)</Text>
  );
  
  return (
    <Space direction="vertical" size="large" style={{ display: 'flex' }}>

      <Card bordered={false} className="criclebox h-full">
          <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Flex wrap="wrap" gap="large" className="align-center">
              <GlobalOutlined style={{ fontSize: '30px', color:"#626262" }} />
              <div>
                <Title level={4} style={{marginBottom: "0px"}} >Språk</Title>
                <Text style={{color:"#626262"}}>Gör ditt språkval</Text>
              </div>
            </Flex>
            <LangDropdown />
          </Space>
      </Card>

      <Card bordered={false} className="criclebox h-full">
          <Space direction="vertical" size="large" style={{ display: 'flex' }}>
            <Flex wrap="wrap" gap="large" className="align-center">
              <LockOutlined style={{ fontSize: '30px', color:"#626262"}}/>
              <div>
                <Title level={4} style={{marginBottom: "0px"}} >Lösenord</Title>
                <Text style={{color:"#626262"}}>Ändra lösenord </Text>
              </div>
            </Flex>
            <Form<IUpdatePasswordForm>
            layout="vertical"
            form={form}
            onFinish={(values) => {
              handleUpdatePassword(values.oldPassword, values.newPassword);
            }}
            requiredMark={true}
            initialValues={{ remember: false}}
          >
            <Form.Item
              name="oldPassword"
              label="Nuvarande lösenord"
              rules={[{ required: true }]}
            >
              <Input
                type="password"
                placeholder="●●●●●●●●"
                size="large"
              />
              </Form.Item>
              <Form.Item
                name="newPassword"
                label={<><Text>Nytt lösenord</Text><Popover content={newPasswordContentInfo}><ExclamationCircleOutlined style={{marginLeft: "0.5rem"}}/></Popover></>}
                rules={[{ required: true }]}  
              >
                <Input
                  type="password"
                  placeholder="●●●●●●●●"
                  size="large"
                />
              </Form.Item>
                <Button type="primary" htmlType="submit" disabled={isLoading} loading={isLoading}>
                  Ändra lösenord
                </Button>
            </Form>
          </Space>
          {updatePasswordAlert}
      </Card>
  </Space>
  )
}

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Personliga inställningar',
    children: <PersonalSettings></PersonalSettings>,
  },
  {
    key: '2',
    label: 'Globala inställningar',
    children: <GlobalSettings></GlobalSettings>,
  }
];

export const SettingsPage: React.FC = () => {
  
    return (
      <>
        <Tabs defaultActiveKey="1" type="card" items={items} />
      </>
    );
};
