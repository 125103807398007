import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Create } from "@refinedev/antd";
import { Form, Input, Select } from "antd";
import { useForm, useSelect } from "@refinedev/antd";

import { ITenant, IUser } from "../../interfaces";
import { TENANT_RES_ID } from "utility/constants";

export const TenantCreate: React.FC<IResourceComponentsProps> = () => {
    const { formProps, saveButtonProps, onFinish, redirect,  } = useForm<ITenant>({
      resource: "tenants",
      action: "create",
      redirect: "list"
    });
    
    const { selectProps: tenantProps } = useSelect<ITenant>({
        resource: TENANT_RES_ID,
        optionLabel: "name",
        optionValue: "id",
    });

    // const onHandleFinish = async (values: any) => {
    //     await onFinish(values);
    //     window.location.href = "/users"
    // };
    
    return (
        <Create saveButtonProps={saveButtonProps} title="Skapa ny Organsiation">
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Namn"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Create>
    );
};