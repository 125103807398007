import { TENANT_RES_ID, USERS_RES_ID } from 'utility/constants';
import { getUserInfoFromCache } from 'utility/helper';

export const customAccessControlProvider = {
  can: async ({ resource, action, params }: any) => {
    const user = getUserInfoFromCache();
    var roles = user?.role.split(",").map(String);
    if (roles.includes("Admin") || roles.includes("SuperAdmin")) {
        // if(resource == "dashboard"){
        //     return { can: false };
        // }
        return { can: true };
    } else {
      if (resource == USERS_RES_ID || resource == TENANT_RES_ID) {
        return {
            can: false,
            reason: "Unauthorized",
        };
      }
    }
    return { can: true };
  },
};
