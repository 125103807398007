import React from 'react';
import { List, Typography } from 'antd';

interface ChangeLogSectionProps {
    title: string;
    changes: string[];
}

const ChangeLogSection: React.FC<ChangeLogSectionProps> = ({ title, changes }) => {
    return (
        <div>
            <Typography.Title level={5}>{title}</Typography.Title>
            <List
                dataSource={changes}
                renderItem={(change) => (
                    <List.Item>
                            <ul>
                                <li>{change}</li>
                            </ul>
                    </List.Item>
                )}
            />
        </div>
    );
};

export default ChangeLogSection;