import { HttpError, useList } from "@refinedev/core";
import { CATEGORY_RES_ID } from '../../../utility/constants';
import type { ICategory } from "interfaces";
import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import debounce from 'lodash/debounce';
import { use } from "i18next";
import { useProductCategoriesSelectContext } from "components/common/productCategories/context/productCategoriesContext";
import { useLocaleStore } from "stores/localeStore";

export const ProductCategoriesSearch = () => {
  const { selectedCategoryNodes, addCategoryNode, deleteCategoryNode, replaceCategoryNodes } = useProductCategoriesSelectContext();
  const [searchValue, setSearchValue] = useState<string>();
  const localeState = useLocaleStore();

  // TO DO: Handle possible error in the future
  const { data, isFetching, isLoading, isError } = useList<ICategory, HttpError>({
    resource: CATEGORY_RES_ID,
    filters: [
      {
        field: "fullSearch",
        operator: "contains",
        value: searchValue,
      },
    ],
    queryOptions: {
      enabled: !!searchValue?.trim(),
      retry: 1,
    },
    meta: { headers: { "lang": localeState.locale } },
  });

  // handleSelectCategory args (id: string, option: any)
  const handleSelectCategory = (id: string, option: any) => {
    const selectedValue = data?.data?.find((d) => d.id === id);

    if (!selectedValue) {
      return;
    }

    const newCategoryNode = {
      title: `${selectedValue?.name} (${selectedValue?.externalId})`, 
      key: selectedValue?.id,
      slug: selectedValue?.slug,
    }

    addCategoryNode(newCategoryNode);
    setSearchValue(undefined);
  };


  // Debounce the search value update
  const debouncedSetSearchValue = useCallback(debounce((searchValue) => {
    if (searchValue?.trim() === "") {
      setSearchValue(undefined);
    } else {
      setSearchValue(searchValue);
    }
  }, 500), []);


  // TO DO: Handle possible error
  // Show error message on failed fetch
  return (
    <div>
      <Select
        filterOption={false}
        placeholder="Sök på namn eller Jetshop id"
        showSearch={true}
        value={searchValue}
        size={"large"}
        onSearch={debouncedSetSearchValue}
        onSelect={handleSelectCategory}
        loading={isFetching}
        notFoundContent={null}
        allowClear={true}
        onClear={() => setSearchValue(undefined)}
        autoClearSearchValue={true}
        options={
          // Hide the options(previous searchresults) if the searchValue is empty or undefined
          // data?.data is not updated when the searchValue is empty or undefined
          searchValue?.trim() === "" || !searchValue
            ? undefined
            : (data?.data ?? []).map((d) => ({
                value: d?.id,
                label: (
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      columnGap: "0.3rem",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: "HK Grotesk Bold",
                        fontWeight: "bold",
                      }}
                    >
                      {d?.name} {d?.externalId}
                    </span>
                    <span style={{opacity: "0.85"}}>{d?.slug}</span>
                  </div>
                ),
                // label: `${d?.name} (${d?.externalId}) ${d?.slug}`,
                disabled: selectedCategoryNodes.some(
                  (node) => node?.key === d?.id
                ),
              }))
        }
      />
    </div>
  );
}

