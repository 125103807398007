import React, { useEffect, useState } from 'react';
import { Modal, Spin, Table, TableColumnsType } from 'antd';
import styled from '@emotion/styled'
import useHttpClient from 'components/hooks/useHttpClient';
import { BaseKey } from '@refinedev/core';
import { IProduct } from 'interfaces/index.js';

interface SpecStatsModalProps {
    onClose: () => void;
    specId: BaseKey | undefined;
}

interface LabelSpecData {
    productCount: number;
    products: IProduct[];
}

interface TableData {
  key: React.Key;
  mainItemNo: string;
}

const columns: TableColumnsType<TableData> = [
  {
    title: "Artikelnummer",
    dataIndex: "mainItemNo",
    width: 150,
  },
  {
    title: "Senast ändrard",
    dataIndex: "modifiedAt",
    width: 150,
  }
];

const TableWrapper = styled.div`
    .ant-table-cell {
        padding: 20px 0px !important;
    }
    td .ant-table-cell {
        cursor: pointer;
    }
`

const SpecStatsModal: React.FC<SpecStatsModalProps> = ({
  onClose,
  specId
}) => {
  const { makeHttpRequest } = useHttpClient();
  const [labelSpecData, setLabelSpecData] = useState<LabelSpecData | undefined>();
  const [tableData, setTableData] = useState<TableData[] | undefined>();
  const [isLoading, setIsloading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsloading(true);
        const response = await makeHttpRequest("GET", `/specificationLabels/${specId}/products`);
        setLabelSpecData(response);
        const data = response.products.map((product : IProduct) => {
            return {key: product.mainItemNo, ...product}
        });
        setTableData(data);
        console.log(response);
      } catch (error) {
        console.log(error);
      } finally {
        setIsloading(false);
      }
    };
    if(!specId) return;
    fetchData();
  }, [specId]);

  return (
    <Modal
      title="Etikett Statistik"
      open={specId !== undefined}
      onCancel={onClose}
      footer={null}
    >
      <span>
        Används av totalt <strong>{isLoading ? "..." : labelSpecData?.productCount}</strong> produkter
      </span>
      <br></br>
      <span>
        Nedan är en snabb överblick där de första 1000 produkterna visas
      </span>
      {isLoading ? (
        <div style={{display: 'flex', justifyContent: 'center'}}>
        <br></br>
        <Spin style={{marginTop: '32px', marginBottom: '32px'}}></Spin>
        </div>
      ) : (
        <TableWrapper>
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={{ pageSize: 200 }}
            onRow={(record, rowIndex) => {
              console.log("record", record)
              return {
                onClick: (event) => {
                  console.log(record);
                },
              };
            }}
            scroll={{ y: 240 }}
          />
        </TableWrapper>
      )}
    </Modal>
  );
};

export default SpecStatsModal;