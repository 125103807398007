import React, { FC, SVGProps } from 'react';

interface VBIconProps extends SVGProps<SVGSVGElement> {
  width?: string;
  height?: string;
  pathStroke?: string;
}

export const ExportImageIcon: FC<VBIconProps> = ({
  width = "24",
  height = "24",
  pathStroke = "black",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.9999 21.6004L16.1999 12.0004L20.9999 16.8004M5.9999 21.6004H17.9999C19.9881 21.6004 21.5999 19.9886 21.5999 18.0004V12.0004M5.9999 21.6004C4.01168 21.6004 2.3999 19.9886 2.3999 18.0004V6.00039C2.3999 4.01217 4.01168 2.40039 5.9999 2.40039H13.7999M16.7999 4.84297L19.2542 2.40039M19.2542 2.40039L21.5999 4.73255M19.2542 2.40039V8.40039M9.5999 7.80039C9.5999 8.7945 8.79401 9.60039 7.7999 9.60039C6.80579 9.60039 5.9999 8.7945 5.9999 7.80039C5.9999 6.80628 6.80579 6.00039 7.7999 6.00039C8.79401 6.00039 9.5999 6.80628 9.5999 7.80039Z"
      stroke={pathStroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SaveIcon: FC<VBIconProps> = ({
  width = "24",
  height = "24",
  pathStroke = "black",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.5999 20.4004V15.0004C6.5999 14.3376 7.13716 13.8004 7.7999 13.8004H16.1999C16.8626 13.8004 17.3999 14.3376 17.3999 15.0004V21.0004M17.3999 3.60039V6.00039C17.3999 6.66313 16.8626 7.20039 16.1999 7.20039L7.7999 7.20039C7.13716 7.20039 6.5999 6.66313 6.5999 6.00039L6.5999 2.40039M20.9974 6.59786L17.4024 3.00292C17.0166 2.61713 16.4934 2.40039 15.9478 2.40039H4.45705C3.3209 2.40039 2.3999 3.32139 2.3999 4.45753V19.5432C2.3999 20.6794 3.3209 21.6004 4.45705 21.6004H19.5428C20.6789 21.6004 21.5999 20.6794 21.5999 19.5432V8.05248C21.5999 7.50689 21.3832 6.98365 20.9974 6.59786Z"
      stroke={pathStroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);


export const OverviewIcon: FC<VBIconProps> = ({
  width = "24",
  height = "24",
  pathStroke = "black",
  ...props
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.7999 21.6003H4.79989C3.47441 21.6003 2.39989 20.5258 2.3999 19.2003L2.4 4.80038C2.4 3.4749 3.47452 2.40039 4.79999 2.40039H15.6003C16.9257 2.40039 18.0003 3.47491 18.0003 4.80039V9.60039M17.3999 17.3494V17.2862M6.60028 7.20039H13.8003M6.60028 10.8004H13.8003M6.60028 14.4004H10.2003M21.5999 17.4004C21.5999 17.4004 20.6037 20.3401 17.3999 20.2887C14.1961 20.2374 13.1999 17.4004 13.1999 17.4004C13.1999 17.4004 14.1557 14.4094 17.3999 14.4094C20.6441 14.4094 21.5999 17.4004 21.5999 17.4004Z"
      stroke={pathStroke}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
