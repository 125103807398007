import Icon, { OpenAIOutlined, EditFilled, RetweetOutlined, RedoOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';

export const useMultiMenuItems = () => {

  const items: MenuProps['items'] = [
    {
      label: 'Generera texter',
      key: 1,
      icon: <OpenAIOutlined />,
    },
    {
      label: 'Redigera',
      key: 2,
      icon: <EditFilled />,
    },
    {
      label: 'Uppdatera från Kassanova',
      key: 3,
      icon: <RedoOutlined />
    }
  ];

  return { menuItems: items};
};
