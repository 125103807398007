import React, { useEffect, useState } from 'react';
import { Card, Menu, Dropdown, Typography, Button, MenuProps, Divider, Image, Checkbox, Flex, Tag } from 'antd';
import { DeleteOutlined, EditOutlined, EllipsisOutlined, ExportOutlined, SelectOutlined } from '@ant-design/icons';
import styled from '@emotion/styled'
import { IFile, IProduct } from 'interfaces';
import PdfIcon from '../../assets/pdf-icon.png';
import NoImage from '../../assets/no-image.png';
import { useDelete } from '@refinedev/core';
import { FILE_RES_ID } from 'utility/constants';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { use } from 'i18next';
import { Text as TextCustom } from "../../components/common/text";
const { Text } = Typography;

const MediaCardWrapper = styled('div')`
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 8px;
    position: relative;
    
    img {
      object-fit: contain !important;
      height: 110px !important;
      cursor: pointer;
    }

    .ant-image {
      width: 100%;
      border-bottom: 1px solid #Fefe;
      padding-bottom: 0.5rem;
    }

    .media-card-body {
      padding: 2px 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .select-marker {
      position: absolute;
      top: 0;
      right: 2px;
      z-index: 2;
    }

    .article-nr {
      position: absolute;
      top: -6px;
      left: -5px;
      z-index: 1;
      -webkit-user-select: all; /* Safari */
      -moz-user-select: all; /* Firefox */
      -ms-user-select: all; /* Internet Explorer/Edge 16 and below */
      user-select: all; /* Standard syntax */
    }

    .media-card-body .ant-typography {
      font-weight: 500;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      padding: 0px 8px;
      font-size: 12px;
    }

    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
`

const MenuDivider = styled(Divider)`
    margin: 4px 0px !important;
`

interface RelationCardProps {
  item: any;
  onSelect: (value: number, status: boolean) => void;
  selected: boolean;
}

const ModalMediaCard: React.FC<RelationCardProps> = ({ item, selected, onSelect }) => {
  const [isSelected, setIsSelected] = useState(false);
  let name, url, extension, mainItemNo : string;
  let id : number;

  if(item?.hasOwnProperty('url')) {
    //Is a file
    mainItemNo = "";
    id = item.id;
    name = item.name;
    url = item?.previewUrl ?? item.url;
    extension = item.extension;
  }else{
    //Is a product
    const image = item?.images?.length > 0 ? item?.images[0] : null;
    mainItemNo = item?.mainItemNo;
    id = item.id;
    name = item.name;
    url = image?.previewUrl ?? image?.url;
    extension = image?.extension;
  }

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  const handleSelect = () => {
    onSelect(id, !isSelected);
    setIsSelected(!isSelected);
  };

  return (
    <MediaCardWrapper onClick={handleSelect}>
      { mainItemNo && 
        <span className="article-nr" onClick={(event: React.MouseEvent) => event.stopPropagation()}>
          <Tag className="tag" style={{fontSize: "11px" ,color:"gray" , backgroundColor: "rgba(255, 255, 255)"}}>{mainItemNo}</Tag>
        </span>
      }
      {extension === ".pdf" ? (
        <Image src={PdfIcon} alt={name} preview={false} fallback={NoImage}/>
      ) : (
        <Image src={url} alt={name} preview={false} fallback={NoImage}/>
      )}
      <Checkbox checked={isSelected} className='select-marker'></Checkbox>
      <div className="media-card-body">
        <Text title={name}>{name}</Text>
      </div>
    </MediaCardWrapper>
  );
};

export default ModalMediaCard;
