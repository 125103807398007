import { useLink } from "@refinedev/core";
import { theme } from "antd";
import {ReactComponent as VBPIMLogo} from '../../../assets/vb-pim-logo-login.svg';

const { useToken } = theme;

type LogoProps = {
    collapsed: boolean;
};

export const Logo: React.FC<LogoProps> = ({ collapsed }) => {
    const { token } = useToken();
    const Link = useLink();

    return (
    <Link to="/" style={{width: '100%'}}>
        <VBPIMLogo style={{width: '100%'}}>Hyperloop</VBPIMLogo>
    </Link>
    );
};
