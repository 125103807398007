import { BaseKey, useApiUrl, useCustomMutation, useSelect } from '@refinedev/core';
import { getUserInfoFromCache } from 'utility/helper';

interface KassanovaSyncData {
  productItemIds?: BaseKey[];
  productItemNos?: BaseKey[];
}

export const useKassanovaSync: () => { kassanovaSyncProductItems: (input: KassanovaSyncData) => void } = () => {
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();
  const userInfo = getUserInfoFromCache();
  
  const kassanovaSyncProductItems = (input: KassanovaSyncData) => {
    mutate({
      url: `${apiUrl}/actions/kassanova-synchronize`,
      method: "post",
      successNotification: (data, values) => {
        return {
          message: "",
          description: "Kassanova bakgrundsjobb skapades",
          type: "success",
        };
      },
      values: {
        ids: input?.productItemIds ?? [],
        articleNumbers: input?.productItemNos ?? [],
      },
      config: {
        headers: { Authorization: `Bearer ${userInfo.token}`}
      }
    });
  };

  return { kassanovaSyncProductItems };
};