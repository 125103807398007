import { useSelect } from '@refinedev/antd';
import { BaseOption } from '@refinedev/core';
import { Select, Spin } from 'antd';
import { SelectProps } from 'antd/lib';
import { use } from 'i18next';
import { IResource } from 'interfaces';
import React, { useEffect, useState } from 'react';


interface CustomSelectProps extends SelectProps {
    selectedId: number | null;
    resource: string;
    entity: string;
    selectedLabel?: string;
}

const handleSelectProps = (selectProps: SelectProps<BaseOption>, selectedBrandItem: IResource | undefined) => { 
  // Dont update options if selectProps is undefined
  if (!selectProps) {
    return selectProps
  }
  // Dont update options if selectedBrandItem is undefined
  if (!selectedBrandItem) {
    return selectProps
  }
  
  // Copy options from selectProps, if options is undefined, set it to an empty array
  let options = [...selectProps?.options ?? []];
  
  // Check if selectedBrandItem is in options, if not, add it to the beginning of the array
  if (selectedBrandItem) {
    const selectedBrand = options.find((item: any) => item?.value === selectedBrandItem?.id)

    if (!selectedBrand) {
      options = [{ label: selectedBrandItem?.name, value: selectedBrandItem?.id }, ...options];
    }
  }
  // Return selectProps with updated options
  return { ...selectProps, options };
}

const CustomSelect: React.FC<CustomSelectProps> = ({ selectedId, resource, entity, selectedLabel, ...rest }) => {
    const { queryResult, selectProps } = useSelect<IResource>({
        resource: resource,
        optionLabel: "name",
        optionValue: "id",
        pagination: {
            mode: "server",
            pageSize: 10,
        },
        sorters: [
            {
            field: "name",
            order: "asc",
            },
        ],
        onSearch: (value) => [
            {
              field: "name",
              operator: "contains",
              value,
            },
        ],
        meta: { headers: { "resource": entity } },
    });

    const formattedSelectProps = handleSelectProps(selectProps, {id: selectedId ?? 0, name: selectedLabel ?? ""})

    return (
      <>
        {queryResult.isLoading ? (
          <Spin></Spin>
        ) : (
          <Select {...formattedSelectProps} allowClear {...rest}></Select>
        )}
      </>
    );
};

export default CustomSelect;
