import { IResourceComponentsProps, useInfiniteList, useList, useTranslate } from "@refinedev/core";
import { Edit, useForm, useModal, useSelect } from "@refinedev/antd";
import {
    Form,
    Input,
    Avatar,
    Row,
    Col,
    Typography,
    Space,
    Radio,
    InputProps,
    Select,
    Button,
    TabsProps,
    Tabs,
} from "antd";
import { useLocaleStore } from "stores/localeStore";
import useProductTabs from "components/productItems/useProductTabs";
import MediaUploadSection from "components/productItems/sections/mediaUploadSection";

export const ProductItemMediaEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const localeState = useLocaleStore();

    const { productItemTabs, onTabChange, activeKey, id } = useProductTabs();

    return (
      <>
        <Tabs
          activeKey={activeKey}
          type="card"
          items={productItemTabs}
          onChange={onTabChange}
        />
        <MediaUploadSection title={"Filer"} id={id} />
      </>
    );
};