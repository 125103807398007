import { CheckCircleTwoTone } from "@ant-design/icons";
import { Spin } from "antd";
import { Text } from "components/common/text";
import { IJob } from "interfaces";

type Props = {
    job: IJob;
};

type JobPropNames = keyof IJob;
interface NotificationFieldsProps {
    uniqueDecsriptiveName?: string;
    job: IJob;
    render: JobPropNames[];
}

const NotificationFields = ({ uniqueDecsriptiveName, job, render }: NotificationFieldsProps) => {
    // The Set approach offer better performance due to its optimized lookup time
    // and ensuring unique property names.
    const renderSet = Array.isArray(render) ? new Set(render) : new Set();

    return (
        <Text id={uniqueDecsriptiveName}>
            {job && (
                <>
                    {renderSet.has("name") && job?.name && (
                        <>
                            {"Jobb "}
                            <Text strong>{job.name}</Text>
                        </>
                    )}
                    {renderSet.has("type") && job?.type && ( 
                        <>
                            {" av typen "}
                            <Text strong>{job.type}</Text>
                        </>
                    )}
                    <br />
                    { renderSet.has("hasFinished") && (
                        <>
                            <Text strong>{job.hasFinished ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <Spin size="small"/>}</Text>
                        </>
                    )}
                    { renderSet.has("entityIds") && job?.entityIds && (
                        <>
                        {" Hanterande produkter "}
                        <Text strong>{job.entityIds?.length}</Text>
                        </>
                    )}
                </>
            )}
        </Text>
    );
};

export const NotificationMessage = ({ job }: Props) => {
    if (!job) return <Text>Loading...</Text>;

    return (
        <>
            {job?.type === "OpenAI" ? 
            <NotificationFields uniqueDecsriptiveName="open-ai-notification" job={job} render={["name", "type", "hasFinished", "entityIds"]} /> 
            : 
            <NotificationFields uniqueDecsriptiveName="default-notification" job={job} render={["name", "type",  "hasFinished", "entityIds"]} />}
        </>
    );
};