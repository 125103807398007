import styled from "@emotion/styled";
import { Badge } from "antd";
import useDataGridStore from "stores/dataGridStore";

const RowProductCounter = styled("div")`
  height: 100%;
  &.selected-products-count {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  .ant-badge {
    height: 100%;
    min-width: 40px;
  }

  .custom-badge-empty {
    border: 1px solid #dddddd;
    min-width: 40px;
    width: 100%;
    height: 100%;
    min-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 12px;
    padding: 0.12rem;
  }
  .custom-badge-empty.disabled {
    color: rgba(0, 0, 0, 0.25);;
  }
`;

const RowSelectionCounter = () => {
  const { selectedRows, checkboxColumnActive } = useDataGridStore();
  const count = selectedRows ? Object.keys(selectedRows).length : 0;
  return (
    <RowProductCounter>
      <span className="selected-products-count">
        {count ? (
          <Badge
            count={count}
            overflowCount={100}
            style={{
              backgroundColor: "#F6FFED",
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              color: "black",
            }}
          />
        ) : (
          <div className={`custom-badge-empty ${!checkboxColumnActive ? "disabled" : ""}`}>0</div>
        )}
      </span>
    </RowProductCounter>
  );
};


export default RowSelectionCounter;