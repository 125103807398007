import { Spin } from "antd";
import useDataGridStore from "stores/dataGridStore";

interface FullScreenLoaderProps {
  loading: boolean;
  message?: string;
}
const FullScreenLoader = ({ loading, message }: FullScreenLoaderProps) => {
  return <Spin tip={message} size="large" spinning={loading} fullscreen />;
};

export default FullScreenLoader;
