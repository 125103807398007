import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Edit, useForm, useSelect } from "@refinedev/antd";
import {
    Form,
    Input,
    Avatar,
    Row,
    Col,
    Typography,
    Space,
    Radio,
    InputProps,
    Select,
} from "antd";
import { ITenant } from "interfaces";

const { Text } = Typography;

export const TenantEdit: React.FC<IResourceComponentsProps> = () => {
    const t = useTranslate();
    const { formProps, saveButtonProps } = useForm<ITenant>();
    
    return (
      <Edit saveButtonProps={saveButtonProps}>
        <Form
          {...formProps}
          layout="vertical"
          initialValues={{
            ...formProps.initialValues,
          }}
        >
          <Form.Item
            label={"Namn"}
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Edit>
    );
};