import styled from "@emotion/styled";
import { Flex } from "antd";

const FooterGridWrapper = styled(Flex)`
  border-top: 2px solid #e4e3e2;
  min-height: 2.5rem;
  padding: 12px 8px 12px 8px;
  background-color: white;
`;

const FooterGrid = ({ itemsCount }: { itemsCount?: number | undefined }) => {
  return (
    <FooterGridWrapper justify="space-between">
      Antal produkter: {itemsCount}
    </FooterGridWrapper>
  );
};

export default FooterGrid;
