import React from 'react';
import { Card, Menu, Dropdown, Typography, Button, MenuProps, Divider, Image } from 'antd';
import { DeleteOutlined, DownloadOutlined, EditOutlined, EllipsisOutlined, ExportOutlined, SelectOutlined } from '@ant-design/icons';
import styled from '@emotion/styled'
import { IFile } from 'interfaces';
import PdfIcon from '../../assets/pdf-icon.png';
import { useDelete } from '@refinedev/core';
import { FILE_RES_ID } from 'utility/constants';
import NoImage from '../../assets/no-image.png';
const { Text } = Typography;

const MediaCardWrapper = styled('div')`
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 8px;
    
    img {
        object-fit: contain !important;
        height: 110px !important;
        cursor: pointer;
    }

    .ant-image {
      width: 100%;
      border-bottom: 1px solid #Fefe;
      padding-bottom: 0.5rem;
    }

    .media-card-body {
      padding: 2px 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .media-card-body .ant-btn-icon {
      transform: rotate(90deg);
    }

    .media-card-body .ant-typography {
      font-weight: 500;
      overflow: hidden;
      font-size: 12px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      padding: 0px 8px;
    }

    &:hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
`

const MenuDivider = styled(Divider)`
    margin: 4px 0px !important;
`

interface RelationCardProps {
  file: IFile;
}

const MediaCard: React.FC<RelationCardProps> = ({ file }) => {
  const { mutate } = useDelete();
  const { id, name, url, previewUrl, extension } = file;
  
  function openInNewTab(url : string) {
    const win = window.open(url, '_blank');
    win?.focus();
  }

  const menu = (
    <Menu>
      <Menu.Item
        key="edit"
        onClick={() => console.log(id)}
        icon={<EditOutlined></EditOutlined>}
      >
        Redigera
      </Menu.Item>
      <Menu.Item
        key="open-new-tab"
        onClick={() => openInNewTab(url)}
        icon={<SelectOutlined></SelectOutlined>}
      >
        Öppna i ny tabb
      </Menu.Item>
      <Menu.Item
        key="download"
        onClick={() => downloadFile(url, name)}
        icon={<DownloadOutlined></DownloadOutlined>}
      >
        Ladda ner
      </Menu.Item>
      <MenuDivider></MenuDivider>
      <Menu.Item
        key="delete"
        onClick={() =>
          mutate({
            resource: FILE_RES_ID,
            id: id,
          })
        }
        icon={<DeleteOutlined></DeleteOutlined>}
      >
        Ta bort
      </Menu.Item>
    </Menu>
  );


  function downloadFile(url: string, fileName: string) {
    fetch(url, {
      method: "GET",
      headers: {
        "Cache-Control": "no-cache", // Prevent caching
        Pragma: "no-cache", // For HTTP/1.0 compatibility
      },
    })
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        setTimeout((_) => {
          window.URL.revokeObjectURL(url);
        }, 60000);
        a.remove();
      })
      .catch((err) => {
        console.error("err: ", err);
      });
  }

  return (
    <MediaCardWrapper>
      {file.extension === ".pdf" ? (
        <Image
          src={PdfIcon}
          alt={name}
          preview={{
            imageRender: () => (
              <iframe
                src={url}
                style={{ width: "70%", height: "100%" }}
                frameBorder="0"
              ></iframe>
            ),
            toolbarRender: () => null,
          }}
        />
      ) : (
        <Image src={previewUrl} fallback={NoImage}/>
      )}
      <div className="media-card-body">
        <Text title={name}>{name}</Text>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button type="text" icon={<EllipsisOutlined />} />
        </Dropdown>
      </div>
    </MediaCardWrapper>
  );
};

export default MediaCard;
