import { HttpError, IResourceComponentsProps, useOne, useUpdate, useTranslate } from "@refinedev/core";
import {
    Form,
    Input,
    Tabs,
    Card,
    Space,
    Divider,
    Typography,
    Button,
    Flex,
    Checkbox,
    Tooltip,
    Empty
} from "antd";
import { ISpecification, ISpecificationLabel,  } from "interfaces";
import { useLocaleStore } from "stores/localeStore";
import { getDescriptionFromLocale } from "utility/helper";
import RelationCard from "components/cards/relationCard";
import RelationSection from "components/productItems/sections/relationSection";
import useProductTabs from "components/productItems/useProductTabs";
import { SPECIFICATION_RES_ID } from "utility/constants";
import styled from '@emotion/styled'
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { ReactNode, useEffect, useState } from "react";
import { Text } from "components/common/text";
import { useReducer } from "react";
import { BaseKey } from "@refinedev/core";
import { InfoCircleOutlined, CheckCircleOutlined, QuestionCircleOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { DividerLine } from "components/separators/dividerLine";
const { Title } = Typography;

const SpecificationCard = styled(Card)`
  .form-label {
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    margin-bottom: 0.5rem;
  }
  .ant-input {
    /* margin: 0.5rem 0; */
  }
`;

export interface ISpecificationListItem extends ISpecification {
  isNew?: boolean;
  isModified?: boolean;
}

interface ReducerState {
  specifications: ISpecificationListItem[];
  savedSpecifications?: ISpecificationListItem[];
  newSpecificationTempId: number; // Add this line
}

const initialState: ReducerState = {
  specifications: [],
  savedSpecifications: [], // Keep track of saved specifications
  newSpecificationTempId: -1, // Initialize with -1 or any negative value
};

type Action =
  | { type: "SET_SPECIFICATIONS"; payload: ISpecificationListItem[] }
  | { type: "CLEAR_ALL_SPECIFICATIONS" }
  | { type: "ADD_SPECIFICATION"; payload: ISpecificationListItem }
  | { type: "DELETE_SPECIFICATION"; payload: number } // For deleting from specifications
  | { type: "UPDATE_SPECIFICATION"; payload: {id: number; value: string} } // For deleting from specifications


function reducer(state: ReducerState, action: Action): ReducerState {
  switch (action.type) {
    case "SET_SPECIFICATIONS":
      return {
        ...state,
        specifications: action.payload.map(spec => ({ ...spec, isNew: false, isModified: false })),
        savedSpecifications: action.payload.map(spec => ({ ...spec })),
      };
    case "CLEAR_ALL_SPECIFICATIONS":
      return { ...initialState };
    case "DELETE_SPECIFICATION":
      return {
        ...state,
        specifications: state.specifications.filter(specification => specification.id !== action.payload),
      };
      case "ADD_SPECIFICATION": {
        // Check if dublicated names ?
        return {
          ...state,
          specifications: [...state.specifications, { ...action.payload, id: state.newSpecificationTempId, isNew: true, isModified: false }],
          newSpecificationTempId: state.newSpecificationTempId - 1, // Decrement the counter cretae a new temp id
        };
      }
      case "UPDATE_SPECIFICATION": {
        // Find the index of the specification with the given id
        const specIndex = state.specifications.findIndex(spec => spec.id === action.payload.id);
        if (specIndex === -1) return state; // Just in case, if id is not found
      
        // Directly creating a new array with the updated specification
        const newSpecifications = [...state.specifications];
        newSpecifications[specIndex] = { ...newSpecifications[specIndex], value: action.payload.value, isModified: true };
      
        return { ...state, specifications: newSpecifications };
      }
    default:
      return state;
  }
}

interface EditProductInfoSpecifications {
  id: BaseKey;
}

export const EditProductInfoSpecifications: React.FC<EditProductInfoSpecifications> = ({id}) => {
    const localeState = useLocaleStore();
    const [specificationState, dispatch] = useReducer(reducer, initialState);
    const { specifications, savedSpecifications } = specificationState;

    const { data, isLoading: isLoadingSpecifications, error: specificationsError, refetch: refetchSpecifications } = useOne<ISpecificationListItem[], HttpError>({
      id: id,
      resource: SPECIFICATION_RES_ID,
      meta: { headers: { "lang": localeState.locale } },
    });

    const { mutate: updateSpecificationList, isError: updateSpecificationListError, isLoading: isLoadingUpdate } = useUpdate();

    const handleSaveSpecifications = () => {
      const formattedSpecifications = specifications.map(spec => {
        // Destructure each specification to separate id and the rest of the properties
        const { id, label, isNew, isModified, ...rest } = spec;
        // Check if id is negative, set it to 0; otherwise, keep it as is
        const formattedId = id < 0 ? 0 : id;
        // Return a new object with the formattedId and the rest of the properties
        return { ...rest, id: formattedId };
      });

      updateSpecificationList(
        {
          id: id,
          resource: SPECIFICATION_RES_ID,
          values: {
            specifications: formattedSpecifications
          },
          meta: { headers: { "lang": localeState.locale } },
        },
        {
          onError: (error, variables, context) => {
            // Add error handling
          },
          onSuccess: (data, variables, context) => {
            refetchSpecifications()
          },
        }
      );
    };

    useEffect(() => {
      if(data?.data && Array.isArray(data?.data)) {
        // Clear existing specifications and addedSpecifications before setting new ones
        dispatch({ type: "CLEAR_ALL_SPECIFICATIONS" });
        // Set specifications initially from the api reuest
        dispatch({ type: "SET_SPECIFICATIONS", payload: data.data });
      }
    }, [data?.data])

    const deleteSpecification = (id: number) => {
      dispatch({ type: "DELETE_SPECIFICATION", payload: id });
    }

    const handleAddNewSpecification = (newSpecification: ISpecificationListItem) => {
      dispatch({ type: "ADD_SPECIFICATION", payload: newSpecification });
    }

    const handleSpecificationUpdate = (id: number, newValue: string) => {
      dispatch({
        type: 'UPDATE_SPECIFICATION',
        payload: { id, value: newValue },
      });
    };

    const isButtonDisabled = () => {
      const hasChanges = specifications.some((spec, index) => {
        return spec.isNew || spec.isModified || specifications.length !== savedSpecifications?.length;
      });
      const hasEmptyValues = specifications.some((spec, index) => {
        return !spec.value;
      });

      return !hasChanges || hasEmptyValues;
    }

    return (
      <>
        <SpecificationCard>
          <SectionHeader title="Specifikationer" icon={<UnorderedListOutlined style={{ fontSize: "22px", color: "#848484" }}/>} />
          <Flex vertical gap={10}>
            {!specifications || specifications.length === 0 ? (
            // <Empty description="Inga specifikationer tillagda" />
            <Flex style={{ justifyContent: "center", width: "100%" }}>Inga specifikationer tillagda</Flex>
              ) : (
                specifications?.map((specification, index) => {
                  const { id, label, labelId, value, } = specification;
                  return (
                    <Flex vertical key={`${id}-${index}`} style={{}}>
                      <Flex>
                        <Flex style={{justifyContent: "space-between", width: "100%"}}>
                          <div>
                            {/* <Text>ID: {id} LABEL_ID: {labelId}</Text> {' '} */}
                            <Typography.Text className="form-label">{label}</Typography.Text>
                          </div>
                          <Flex style={{ alignItems: "center"}}>
                            <CloseCircleOutlined style={{fontSize: "20px", marginLeft: "0.5rem"}} onClick={() => deleteSpecification(id)}/>
                          </Flex>
                        </Flex>
                      </Flex>

                      <Flex wrap="wrap">
                        <SpecificationInputValue
                          key={specification.id}
                          specification={specification}
                          onUpdate={handleSpecificationUpdate}
                        />
                      </Flex>
                    </Flex >
                  );
                })
              )}

            <Button onClick={handleSaveSpecifications} type="primary" style={{width: "100%"}} disabled={isButtonDisabled()} loading={isLoadingUpdate || isLoadingSpecifications}>
                Spara Specifikationer
            </Button>
          </Flex>

          <DividerLine lineIntensity={"strong"}/>

          <SpecificationForm handleAddNewSpecification={handleAddNewSpecification} specifications={specifications}/>

        </SpecificationCard>
      </>
    );
};


// Input component
interface IVideoInput {
  specification: ISpecificationListItem;
  onUpdate: (id: number, newValue: string) => void;
}

const SpecificationInputValue: React.FC<IVideoInput> = ({ specification, onUpdate }) => {
  const { unit, id, value } = specification ?? {}

  const validateInput = (spec: ISpecificationListItem): ReactNode => {
    const { unit, id, value, isModified, isNew } = spec ?? {}
    // Check if the value is either undefined, null, or an empty string (including strings of only whitespace)
    if (!value || value?.trim().length === 0) {
      // If the value is empty or only whitespace, show a validation error
      return (
        <Tooltip title="Fältet måste ha ett värde">
          <InfoCircleOutlined style={{ color: 'rgba(244, 6, 6, 0.45)' }} />
        </Tooltip>
      );
    // } else if (isNew ) {
    //   // If the value is non-empty and not just whitespace, return null indicating no error
    //  return ( <Tooltip title="Tillagt fält">
    //     <InfoCircleOutlined style={{ color: 'rgba(25, 126, 220, 0.45)' }} />
    //   </Tooltip>
    //  )
    // } else if ( isModified ) {
    //     return ( <Tooltip title="Modifierat fält">
    //     <InfoCircleOutlined style={{ color: 'rgba(163, 163, 2, 0.45)' }} />
    //   </Tooltip>
    // )
    } else {
      return <></>
    }
  };
  
  return (
    <Input
      suffix={
        validateInput(specification)
      }
      style={{margin: "0.5rem 0"}}
      addonAfter={unit?.unitSymbol ?? "unit"}
      type="text" // Use unit here
      value={value}
      onChange={(e) => onUpdate(id, e.target.value)}
    />
  );
};


// SpecificationForm
import React from 'react';
import { ProductSpecificationSearch } from "components/common/productSpecifications/specificationSearch";
import { SectionHeader } from "components/layout/header/sectionHeader";

interface SpecificationAddFormProps {
  handleAddNewSpecification: (value: ISpecification) => void;
  specifications?: ISpecification[]
}

const SpecificationForm: React.FC<SpecificationAddFormProps> = ({handleAddNewSpecification, specifications}) => {
  const [form] = Form.useForm();

  const handleAddNewSearchSpecification = (label: ISpecificationLabel) => {
    // Construct new specification object
    const newSpecification: ISpecificationListItem ={
      id: null,
      labelId: label.id,
      label: label.name,
      value: "",
      unit: {
        unitId: label?.unit?.unitId,
        unitName: label?.unit?.unitName,
        unitSymbol: label?.unit?.unitSymbol,
      }
    }
    // Add specification directly
    handleAddNewSpecification(newSpecification)
  };

  const onFinish = (values: ISpecificationListItem) => {
    // // Create new specification object
    // const newSpecification: ISpecificationListItem = {
    //   id: 0, 
    //   labelId: 0,
    //   label: values.label, 
    //   value: "",
    //   unit: {
    //     unitId: 0,
    //     unitName: "",
    //     unitSymbol: ""
    //   }
    // };

    // handleAddNewSpecification(newSpecification);

    // form.resetFields();
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <Form
      // {...layout}
      layout="vertical"
      form={form}
      name="control-hooks"
      onFinish={onFinish}
      // style={{ maxWidth: 600 }}
    >
      {/* <Checkbox
        checked={searchActive}
        onChange={(e) => setSearchActive(e.target.checked)}
      >
        Sök efter specifikationer
      </Checkbox> */}

     {/* <Form.Item name="label" style={{margin: "0.5rem 0"}} rules={[{ required: true }]}>
        { searchActive ? 
          (
          <ProductSpecificationSearch handleSetSpecification={handleAddNewSearchSpecification}/>) 
          :
          (<Input placeholder="Specifikationsnamn" style={{margin: "0.5rem 0"}}/>)
        }
      </Form.Item> */}
      <Flex gap='small' align="center">
        <Title level={5} style={{marginBottom: "0px"}} >Lägg till etikett</Title>                          
        <Tooltip title="Klicka på önskvärd etikett i sökresultatets lista för att lägga till etikett.">
          <InfoCircleOutlined style={{ color: "black", fontSize: "20px" }} />
        </Tooltip>
      </Flex>
      <Form.Item name="label" style={{margin: "0.5rem 0"}} rules={[{ required: true }]}>
        <ProductSpecificationSearch handleAddNewSearchSpecification={handleAddNewSearchSpecification}/>
      </Form.Item>
      
      {/* <Form.Item >
        <Space>
          <Button type="primary" htmlType="submit">
            Lägg till <PlusCircleOutlined />
          </Button>
          <Button htmlType="button" onClick={onReset}>
            Rensa <CloseCircleOutlined />
          </Button>
        </Space>
      </Form.Item> */}
    </Form>
  );
};






