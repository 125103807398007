import { Col, Row } from 'antd';
import type { IProductItem } from "interfaces";
import { ProductVariantsSearch } from "./productVariantsSearch";
import { ProductVariantsSelectedList } from "./productVariantsSelectedList";
import { useEffect } from 'react';
import { useProductVariantsStore } from 'stores/productVariantsStore';
import { HttpError } from '@refinedev/core';

interface ProductVariantsSelectProps {
  variants: IProductItem[];
  onChange: (variants: number[]) => void;
  formLoading: boolean;
  mutationError: HttpError | null;
  selectedVariantsActionEl?: JSX.Element;
}

const ProductVariantsSelect: React.FC<ProductVariantsSelectProps> = ({variants, onChange, formLoading, mutationError, selectedVariantsActionEl}) => {
  const { setProductVariants, productVariants} = useProductVariantsStore();
  useEffect(() => {
    if (!Array.isArray(productVariants)) {
      return
    }
      onChange(productVariants.map(variant => variant?.id));
  }, [productVariants]);
  
  useEffect(() => {
    if (!Array.isArray(variants)) {
      return
    }
    setProductVariants(variants)
  }, [variants, mutationError]);

  return (
      <Row gutter={[16, 16]}>
        <Col span={24} >
          <ProductVariantsSearch />
        </Col>
        <Col span={24} >        
          <ProductVariantsSelectedList formLoading={formLoading} selectedVariantsActionEl={selectedVariantsActionEl} />
        </Col>
      </Row>
  );
};

export default ProductVariantsSelect;

