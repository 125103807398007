import { Col, Row } from 'antd';
import type { ICategory } from "interfaces";
import { ProductCategoriesSelectProvider, useProductCategoriesSelectContext } from "components/common/productCategories/context/productCategoriesContext";
import { ProductCategoriesSearch } from "./productCategoriesSearch";
import ProductCategoriesTreeSelect from "./productCategoriesTreeSelect";
import { ProductCategoriesSelectedList } from "./productCategoriesSelectedList";
import { useEffect, useRef } from 'react';
import { areArraysEqual } from 'utility/helper';

interface ProductCategoriesSelectProps {
  categories: ICategory[];
  onChange: (categoryIds: number[]) => void;
  formLoading?: boolean;
}

// Initialize the context with the saved categories
const CategoriesContextInitializer: React.FC<ProductCategoriesSelectProps> = ({ categories, onChange }) => {
  const { replaceCategoryNodes, selectedCategoryNodes } = useProductCategoriesSelectContext();
  const prevCategory = useRef<ICategory[]>([]);

  useEffect(() => {
    onChange(selectedCategoryNodes?.map((node) => node?.key));
  }, [selectedCategoryNodes]);
  
  useEffect(() => {
    if (areArraysEqual(categories?.map((item) => item?.id), prevCategory?.current?.map((item) => item?.id))) {
      return
    }

    const checkedNodesData = categories?.map((category) => { 
      return {
        title: `${category?.name} (${category?.externalId})`, 
        key: category?.id,
        // slug: category?.slug,
    }})

    prevCategory.current = categories;
    replaceCategoryNodes(checkedNodesData)
  
  }, [categories]);
  
  return null;
};

const ProductCategoriesSelect: React.FC<ProductCategoriesSelectProps> = ({categories, onChange, formLoading}) => {
  return (
    <ProductCategoriesSelectProvider>
      <CategoriesContextInitializer categories={categories} onChange={onChange}/>
      <Row gutter={[16, 16]}>
        <Col span={24} >
          <ProductCategoriesSearch />
        </Col>
        <Col span={12} >
          <ProductCategoriesTreeSelect/>
        </Col>
        <Col span={12} >        
          <ProductCategoriesSelectedList formLoading={formLoading}/>
        </Col>
      </Row>
    </ProductCategoriesSelectProvider>
  );
};

export default ProductCategoriesSelect;

