import { CrudFilters, HttpError, useLink, useMany, useExport, useApiUrl, useCreate, CrudFilter, useTranslate, useNotification, ConditionalFilter, LogicalFilter, CrudOperators, BaseKey } from "@refinedev/core";
import React, { useEffect, useState } from 'react'
import { EditButton, List, useModal, ImportButton, useImport, useTable, RefreshButton, ExportButton, TagField } from "@refinedev/antd";
import { Button, Card, Checkbox, Drawer, Form, Input, Progress, Select, Space, Table, Image, MenuProps, Typography, Divider, Badge, Popover, Tooltip } from "antd";
import { IFile, IProduct, IProductItem, ITenant, IUser } from "interfaces";
import { JOB_RES_ID, JOB_TEXT_REWRITE, PRODUCT_RES_ID } from "utility/constants";
import styled from '@emotion/styled'
import { formatDate, getUserInfoFromCache, stringToColor } from "utility/helper";
import { useLocaleStore } from "stores/localeStore";
import { MultiGenerateTextModal } from "components/modals/multiGenerateText";
import { FilterConfigurationModal } from "components/modals/filterConfig";
import { FilterOutlined, OpenAIOutlined, StopOutlined, SyncOutlined, UserOutlined } from "@ant-design/icons";
import { addHubListener, startConnection, stopConnection } from "live-provider/signalRService";
import ImportFileModal from "components/modals/importFile";
import NoImage from '../../assets/no-image.png';
import { ProductItemList } from "pages/productItems/list";
import { Dropdown, Tag } from "antd/lib";
import ReactCountryFlag from "react-country-flag";
import { SignatureKind } from "typescript";
import { useMultiMenuItems } from "components/hooks/multiMenuItems";
import { Spin } from "antd";
import { usePublishProducts } from "components/hooks/publishProducts";
import Search from "antd/es/input/Search.js";
import FilterDrawer from "components/filters/filterDrawer";
import { useNavigate } from "react-router-dom";
import useNavigateOnClassName from "components/hooks/useOnClassNameClickNavigation";
import { MultiEditProductsModal } from "components/modals/multiEditProducts";
import { DividerLine } from "components/separators/dividerLine";
import { RowSelectMethod } from "antd/lib/table/interface";
import { useTextRewrite } from "components/hooks/textRewrite";
import { useKassanovaSync } from "components/hooks/kassanovaSync";
import CheckTag from "components/common/CheckTag";
import { RenderShippingSvg } from "components/Svg/RenderShippingSvg";
import KassanovaFetchModal from "components/modals/kassanovaFetch";
import FilterTabs from "components/tabs/FilterTabs";

const ImageWrapper= styled.div`
  .ant-image-img {
    object-fit: contain;
  }
`

const TableHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .top-header {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .sub-header {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }

  .header-buttons {
    display: flex;
    gap: 0.75rem;
  }

  .ant-badge {
    width: 100%;
    height: 100%;
  }
  /* .ant-badge-count {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
`

const StyledCard = styled(Card)`
  .ant-page-header-heading-left {
    display: none;
  }

  .ant-page-header-heading {
    display: none;
  }

  .ant-table-tbody > .ant-table-row .ant-table-cell:hover {
    cursor: pointer;
  }
  .default-cursor {
    cursor: default;
  }
  .pointer-cursor {
    cursor: pointer;
  }
  .auto-cursor {
    cursor: auto;
  }
  /* .ant-progress {
    min-width: 60px !important;
    max-width: 75px !important;
  } */
  .selected-products-count {
    min-width: 51px;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-end;
  }

  .custom-badge-empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F5F5F5;
    border-radius: 10px;
    color: #B8B8B8;
  }
`

const TableTitle = styled(Typography.Title)`
  margin-bottom: 0.75rem !important;
`

const TextSpan = styled.span`
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    max-width: 200px;
`

const ProductProgress = styled(Progress)`
  opacity: 0.8;
  min-width: 60px !important;
  max-width: 75px !important;
  :hover { 
    opacity: 1;
  }
`

interface ISearch {
    name: string;
    customer: string;
    status: string;
}

export const ProductList: React.FC = () => {
    const [tableIsLoading, setTableIsLoading] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const [kassanovaFetchModalOpen, setKassanovaFetchModalOpen] = useState(false);

    const t = useTranslate();
    const handleNavigationOnElementClick = useNavigateOnClassName();

    const { show: showTranslateContextModal, close: closeTranslateContextModal, modalProps: translateContextModalProps } = useModal();
    const { show: showMultiEditProductsModal, close: closeMultiEditProductsModal, modalProps: multiEditProductsModalProps } = useModal();
    const { show : showImportModal, close : closeImportModal, modalProps : importModalProps } = useModal();

    const localeState = useLocaleStore();
    const { menuItems } = useMultiMenuItems();
    const { rewriteText } = useTextRewrite();
    const { kassanovaSyncProductItems } = useKassanovaSync();
    
    const { tableProps, searchFormProps, setFilters, setCurrent } = useTable<
      IProduct,
      HttpError,
      ISearch
    >({
      resource: PRODUCT_RES_ID,
      liveMode: "auto",
      syncWithLocation: true,
      filters: {
        defaultBehavior: "replace",
        initial: [
          {
            field: "productstatus",
            operator: "eq",
            value: "0",
          }
        ]
      },
      sorters: {
        initial: [
          {
            field: "createdAt",
            order: "desc",
          },
        ],
      },
      meta: {
        headers: { lang: localeState.locale },
      }
    });

    const pagination : any = tableProps?.pagination;

    const handleSearch = (newValue : any) => {
      if(newValue == ""){
        setFilters([]);
        return;
      }

      setFilters(data => {
        const castedData = data as LogicalFilter[];
        const fullSearchIndex = castedData.findIndex((x) => x.field === "fullSearch")

        if (fullSearchIndex > -1) {
          castedData[fullSearchIndex].value = newValue;
          return castedData
        }

        return [...data, { field: "fullSearch", operator: "contains", value: newValue }];
      });
      setCurrent(1);
    };

    const onSelectChange = (
      newSelectedRowKeysArr: React.Key[],  // Array of newly selected or deselected row keys
      itemValues: any[],  // Values of the items corresponding to the selected/deselected rows (not used in this function)
      info: { type: RowSelectMethod }  // Object containing the type of selection event
    ) => {
      // Safely extract all row keys (IDs) from the current page's data source
      // This approach handles cases where dataSource might be undefined, null, or not an array.
      // It also accounts for items in dataSource that might be null/undefined or missing an id property.
      const allRowKeysOnCurrentPage = Array.isArray(tableProps?.dataSource)
      ? tableProps.dataSource.reduce((acc, item) => {
          if (item?.id !== undefined && item.id !== null) {
            acc.push(item.id);
          }
          return acc;
        }, [] as React.Key[])
      : [];

      // Filter out row keys from the selectedRowKeys state that are not part of the current page
      // This maintains selections from other pages while preparing to update current page selections
      const pageKeysStateWithCurrentPageIdsRemoved = selectedRowKeys.filter((key) => !allRowKeysOnCurrentPage.includes(key));
    
      // Merge the previous state (with current page IDs removed) with the new selected/deselected row keys
      // Use a Set to ensure all row keys are unique to avoid duplicate selections
      const uniqueSelectedRowKeys = Array.from(new Set([...pageKeysStateWithCurrentPageIdsRemoved, ...newSelectedRowKeysArr]));
    
      // Update the selectedRowKeys state with the new unique set of selected row keys
      setSelectedRowKeys(uniqueSelectedRowKeys);
    };
    
    const rowSelection = {
      selectedRowKeys,
      onChange: onSelectChange,
    };

    const onImportModalFinish = () => {
      closeImportModal();
    }

    const onTextRewriteFinish = async (data: any) => {
      closeTranslateContextModal();
      rewriteText(selectedRowKeys, data?.context);
      setSelectedRowKeys([]);
    }

    const onMultiEditProductsModalFinish = async (clearAllKeys: boolean) => {
      closeMultiEditProductsModal();
      if (clearAllKeys) {
        setSelectedRowKeys([]);
      }
    }

    const clearFilter = () => {
      setTableIsLoading(true);
      setFilters([]);
      setTableIsLoading(false);
    }

    const onMultiButtonClick = (e: any) => {
        setTableIsLoading(true);
        if(e.key == 1){
            showTranslateContextModal();
        } else if(e.key == 2){  
            showMultiEditProductsModal();
        } else if(e.key == 3){
          kassanovaSyncProductItems({ productItemIds: selectedRowKeys as BaseKey[] });
        }
        setTableIsLoading(false);
    }

    const closeFilter = (filters : any[]) => {
      setTableIsLoading(true);
      setFilterDrawerOpen(false);
      setFilters(filters);
      setTableIsLoading(false);
    };

    const showFilter = () => {
      setFilterDrawerOpen(true);
    };

    const addOrRemoveFilterByField = (field: string, operator: Exclude<CrudOperators, "or" | "and"> , value: any) => {
      setFilters(data => {
        const castedData = data as LogicalFilter[];
        const filterIndex = castedData.findIndex((x) => x.field === field);
    
        if (filterIndex > -1) {
          castedData.splice(filterIndex, 1);
        } else {
          castedData.push({ field, operator, value });
        }
    
        return [...castedData];
      });
    };

    const groupNameTagClicked = (status : boolean, tagValue : string) => {
      addOrRemoveFilterByField("productitems.group.name", "eq", tagValue);
    };
    
    return (
      <StyledCard bordered={false} className="criclebox h-full">
        <TableTitle level={4}>Produkter</TableTitle>
        <TableHeader style={{position: "relative"}}>
          <div className="top-header"><FilterTabs setFilters={setFilters} tabFilters={[{id: '0', label: 'Aktiva'}, {id: '1', label: 'Arkiverade'}]}/></div>
          <div className="sub-header">
            <Search placeholder="Sök produkt..." onSearch={handleSearch} allowClear enterButton={true}/>
            <div className="header-buttons">
              <span className="selected-products-count">
                {selectedRowKeys?.length ? (
                  <Badge
                    className="site-badge-count-109"
                    count={selectedRowKeys?.length}
                    overflowCount={100}
                    style={{ backgroundColor: '#F6FFED', width: "100%", height: "100%", alignItems: "center", justifyContent: "center", display: "flex", color: "black" }}
                  />
                ) : (
                  <span className="custom-badge-empty">0</span>
                )}
              </span>
              <Dropdown.Button
                menu={{ items: menuItems, onClick: onMultiButtonClick }}
                disabled={selectedRowKeys?.length == 0}
                onClick={showTranslateContextModal}
              >
                Generera texter
              </Dropdown.Button>
              <Button
                type="primary"
                icon={<FilterOutlined />}
                onClick={showFilter}
              >
                Filter
              </Button>{" "}
              <Button
                type="primary"
                icon={<StopOutlined />}
                onClick={clearFilter}
              >
                Rensa
              </Button>{" "}
              <Button
                type="primary"
                icon={<SyncOutlined />}
                onClick={() => setKassanovaFetchModalOpen(true)}
              >
                Hämta produkter
              </Button>{" "}
              {/* <Button type="primary" onClick={showImportModal} disabled={true}>
                Importera filer
              </Button>{" "} */}
            </div>
          </div>
        </TableHeader>

        <List title={"Produkter"}>
          <Table
            {...tableProps}
            loading={tableIsLoading}
            onRow={(record) => ({
              onClick: (event) => handleNavigationOnElementClick(event , "ant-table-cell", `/products/edit/`, record?.id),
            })}
            rowKey="id"
            rowSelection={rowSelection}
            pagination={{ ...pagination, showSizeChanger: true }}
            expandable={{
              expandedRowRender: expandedRowRender,
              expandedRowKeys: expandedRowKeys,
              onExpand: (expanded, record) => {
                if (expanded) {
                  setExpandedRowKeys([...expandedRowKeys, record.id]);
                  return;
                }
                setExpandedRowKeys(
                  expandedRowKeys.filter((item) => item != record.id)
                );
              },
            }}
            footer={() => (
              <span>
                Totalt antal produkter <strong>{pagination?.total}</strong>
              </span>
            )}
          >

            <Table.Column
              dataIndex="images"
              render={(value: IFile[]) => (
                <ImageWrapper className="no-navigate-action">
                  <Image
                    width={60}
                    height={60}
                    src={value[0]?.previewUrl ?? value[0]?.url}
                    fallback={NoImage}
                  />
                </ImageWrapper>
              )}
              width={105}
            />

            <Table.Column<IProduct>
              dataIndex="name"
              title="Namn"
              render={(_, record) => <span className="auto-cursor">{record.name}</span>}
            />
            <Table.Column<IProduct>
              dataIndex="mainItemNo"
              sorter={true}
              title="Huvudvariant"
              render={(_, record) => <TextSpan className="auto-cursor">{record.mainItemNo}</TextSpan>}
            />
            <Table.Column<IProduct>
              dataIndex="brandName"
              title="Märke"
              render={(_, record) => <Tag className="auto-cursor">{record.brandName}</Tag>}
            />
            <Table.Column<IProduct>
              dataIndex="groupName"
              title="Varugrupp"
              render={(_, record) => <CheckTag text={record.groupName} onChange={(status) => groupNameTagClicked(status, record.groupName)}></CheckTag>}
            />
            <Table.Column<IProduct>
              dataIndex="shippingClass"
              title="Fraktklass"
              render={(_, record) => (
                <RenderShippingSvg shippingType={record?.shippingMethod}></RenderShippingSvg>
              )}
            />
            <Table.Column<IProduct>
              dataIndex="longDescription"
              title="Beskrivning"
              responsive={["xxl"]}
              render={(_, record) => <TextSpan className="auto-cursor">{record?.description}</TextSpan>}
            />
            <Table.Column<IProduct>
              dataIndex="createdAt"
              title="Skapad"
              responsive={["xxl"]}
              render={(_, record) => <TextSpan className="auto-cursor">{formatDate(record?.createdAt)}</TextSpan>}
            />
            {/* <Table.Column<IProduct>
              dataIndex="longDescriptionSupplier"
              title="Leverantör text"
              render={(_, record) => (
                <TextSpan>{record.descriptionSupplier}</TextSpan>
              )}
            /> */}
            {/* <Table.Column<IProductItem>
              dataIndex="isComplete"
              title="Hanterad"
              align="center"
              render={(_, record) => (
                <Space>
                  <Dot color={record.isComplete ? "#04AA6D" : "#FF0000"}></Dot>
                </Space>
              )}
            /> */}
            <Table.Column<IProduct>
              dataIndex="channels"
              title="Publicerad"
              render={(_, record) => (
                <Tag className="auto-cursor" color={`${record.isPublished ? "green" : "red"}`}>
                  {record.isPublished ? "Publicerad" : "Ej Publicerad"}
                </Tag>
              )}
            />
            <Table.Column<IProduct>
              dataIndex="completeness"
              title="Status"
              render={(_, record) => (
                <Tooltip 
                  title={record?.completenessText ?? "Statusinformation saknas"} 
                  className="auto-cursor" 
                  color={
                    record?.completenessText ? record?.completenessPercentage < 100 ? "blue" : "green" : ""
                  } >
                  <ProductProgress
                    className="auto-cursor"
                    percent={record?.completenessPercentage ?? 0 } 
                    size="small"
                  />
                </Tooltip>
              )}
            />
            <Table.Column<IProduct>
              title="Handling"
              align="center"
              dataIndex="actions"
              render={(_, record) => (
                <Space>
                  <EditButton hideText size="small" recordItemId={record.id} />
                  {/* <DeleteButton hideText size="small" recordItemId={record.id} disabled={true}/> */}
                </Space>
              )}
            />
          </Table>
        </List>
        <ImportFileModal
          modalProps={importModalProps}
          onFinish={onImportModalFinish}
        ></ImportFileModal>
        <MultiGenerateTextModal
          modalProps={translateContextModalProps}
          onFinish={onTextRewriteFinish}
        ></MultiGenerateTextModal>
        <MultiEditProductsModal
          modalProps={multiEditProductsModalProps}
          onFinish={onMultiEditProductsModalFinish}
          selectedIds={selectedRowKeys}
        ></MultiEditProductsModal>
        <FilterDrawer
          visible={filterDrawerOpen}
          onClose={closeFilter}
          primaryType={{ name: "Grundfiltrering", key: "Product" }}
          secondaryTypes={[
            { name: "Textfiltrering", key: "ProductTranslation" },
            {
              name: "Statusfiltrering",
              key: "ProductTranslation.Completeness",
            },
            { name: "Märkesfiltrering", key: "Brand" },
            { name: "Varugrupp", key: "ProductItem.ProductItemGroup" },
          ]}
        ></FilterDrawer>
        <KassanovaFetchModal isOpen={kassanovaFetchModalOpen} onClose={() => setKassanovaFetchModalOpen(false)}></KassanovaFetchModal>
      </StyledCard>
    );
};

const expandedRowRender = (record: IProduct) => {
    return <ProductItemList record={record} />;
};