import TableModal from "components/modals/tableModal";

const TextModalGrid = ({ editorProps }: { editorProps: any }) => {

  const onClose = () => {
    editorProps.cell.stopEdit();
  };
  const onSave = async () => {
    await editorProps.onComplete();
  };

  return (
    <TableModal
      isOpen={editorProps?.cell?.isInEdit()}
      onClose={onClose}
      onSave={onSave}
      title={editorProps?.cellProps?.header}
      initialValue={editorProps?.value}
      onChange={editorProps?.onChange}
    ></TableModal>
  );
};

export default TextModalGrid;