import { BaseKey } from '@refinedev/core';
import VbSelect from 'components/common/vbSelector/vbSelect';
import { IUnit } from 'interfaces/index';
import React, { useState } from 'react';

interface UnitSelectorProps {
    selectedIds?: BaseKey[];
    onSelect: (ids: BaseKey[]) => void;
}

const UnitSelector: React.FC<UnitSelectorProps> = ({ selectedIds, onSelect }) => {

    const unitLabelExpression = (unit: IUnit) => {
      return `(${unit.unitId}) ${unit.unitName}`;
    };

    const keyExpression = (unit: IUnit) => {
      return unit.unitId.toString();
    };

    return (
      <VbSelect<IUnit>
          placeholder="Välj enhet"
          selectedIds={selectedIds}
          labelExpression={unitLabelExpression}
          keyExpression={keyExpression}
          resource={"units"}
          onChange={(values) => {
          onSelect(values);
        }}
      ></VbSelect>
    );
};

export default UnitSelector;