import React, { useState } from 'react';
import { Tag } from 'antd';

interface CheckTagProps {
  text: string;
  checked?: boolean;
  onChange: (checked: boolean) => void;
}

const CheckTag: React.FC<CheckTagProps> = ({ text, checked, onChange }) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked ?? false);

  const handleClick = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <Tag.CheckableTag checked={isChecked} onChange={handleClick}>
      {text}
    </Tag.CheckableTag>
  );
};

export default CheckTag;
