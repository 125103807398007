import { BaseKey, useTranslate, useUpdateMany } from "@refinedev/core";
import { Modal, Form, Typography, Button } from "antd";
import { ModalProps } from "antd";
import { Radio } from "antd/lib";
import { DividerLine } from "components/separators/dividerLine";
import { PRODUCT_RES_ID } from "utility/constants";
import { ClearOutlined } from "@ant-design/icons";
const { Title } = Typography;

interface MultiEditProductsModalProps { 
    modalProps: ModalProps;
    onFinish: (clearAllKeys: boolean) => void;
    selectedIds: React.Key[];
}

interface UpdateRequest { 
    isPublished?: boolean;
    productStatus?: number;
    shippingMethod?: number;
}

export const MultiEditProductsModal: React.FC<MultiEditProductsModalProps> = ({ modalProps, onFinish, selectedIds }) => {
    const [form, ] = Form.useForm();
    const t = useTranslate();
    const { mutate } = useUpdateMany();

    const handleFinish = (values : any) => {
      let updateValues: UpdateRequest = {};
      if (values.isPublished !== undefined && values.isPublished !== "unchanged") {
        updateValues.isPublished = values.isPublished == "publish" ? true : false;
      }
      if (values.productStatus !== undefined && values.productStatus !== 99) {
        updateValues.productStatus = values.productStatus;
      }
      if (values.shippingMethod !== undefined) {
        updateValues.shippingMethod = values.shippingMethod;
      }
      if (Object.keys(updateValues).length === 0) {
        onFinish(true);
        return;
      }
      mutate(
        {
          resource: PRODUCT_RES_ID,
          values: updateValues,
          ids: selectedIds as BaseKey[],
        },
        {
          onError: (error, variables, context) => {
            console.log("error", error);
          },
          onSuccess: (data, variables, context) => {
            //console.log(data);
          },
        }
      );
      onFinish(true);
    };
    
    return (
      <>
        <Modal
          {...modalProps}
          width={800}
          onCancel={() => onFinish(false)}
          onOk={() => form.submit()}
          footer={(_, { OkBtn, CancelBtn }) => (
            <>
              <Button
                type="primary"
                icon={<ClearOutlined />}
                onClick={() => form.resetFields()}
              />
              <CancelBtn />
              <OkBtn />
            </>
          )}
        >
          <Title level={3} style={{ marginBottom: "1rem" }}>
            Multiredigering
          </Title>
          <DividerLine />
          <Form form={form} layout="vertical" onFinish={handleFinish}>
            <Form.Item
              name="isPublished"
              label="Publicering"
              className="extra-space"
            >
              <Radio.Group>
                <Radio value={"publish"}>Publicera</Radio>
                <Radio value={"unPublish"}>Avpublicera</Radio>
                <Radio value={"unchanged"}>Oförändrad</Radio>
              </Radio.Group>
            </Form.Item>
            <DividerLine />
            <Form.Item
              name="productStatus"
              label="Produktstatus"
              className="extra-space"
            >
              <Radio.Group>
                <Radio value={0}>{"Aktiv"}</Radio>
                <Radio value={1}>{"Arkivera"}</Radio>
                <Radio value={99}>{"Oförändrad"}</Radio>
              </Radio.Group>
            </Form.Item>
            <DividerLine />
            <Form.Item
              name="shippingMethod"
              label="Fraktklass"
              className="extra-space"
            >
              <Radio.Group>
                <Radio value={0}>{t("0")}</Radio>
                <Radio value={1}>{t("1")}</Radio>
                <Radio value={2}>{t("2")}</Radio>
                <Radio value={3}>{t("3")}</Radio>
                <Radio value={4}>{t("4")}</Radio>
                <Radio value={5}>{t("5")}</Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      </>
    );
};