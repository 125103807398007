import React, { useEffect, useState } from 'react';
import { Modal, Input } from 'antd';
import { useKassanovaSync } from 'components/hooks/kassanovaSync';
import { BaseKey } from '@refinedev/core';
import { Alert } from 'antd/lib/index.js';
import TextArea from 'antd/es/input/TextArea.js';

interface KassanovaFetchModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const KassanovaFetchModal: React.FC<KassanovaFetchModalProps> = ({
  onClose,
  isOpen,
}) => {
  const { kassanovaSyncProductItems } = useKassanovaSync();
  const [isError, setIsError] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>("");

  const validateAndSendRequest = () => {
    const regex = /^\d+$/;
    const articleNumbers = inputValue.split("\n").map((item) => item.trim()).filter((item) => item !== "");
    if (articleNumbers.every(number => regex.test(number))) {
      kassanovaSyncProductItems({
        productItemNos: articleNumbers as BaseKey[],
      });
      setIsError(false);
      onClose();
    } else {
      setIsError(true);
    }
  };

  return (
    <Modal
      title="Hämta artiklar från Kassanova"
      open={isOpen}
      onCancel={onClose}
      okText="Hämta"
      onOk={validateAndSendRequest}
    >
      <span style={{ marginBottom: "1rem", display: "block" }}>
        Skriv in ett artikelnummer på varje enskild rad för att hämta artiklar från Kassanova och skapa nya produkter i PIM.
      </span>
      {isError && (
        <Alert
          style={{ marginBottom: "0.5rem" }}
          message="Validering misslyckades enbart siffror är tillåtna."
          type="error"
          showIcon
        />
      )}
    <TextArea
      showCount
      maxLength={2000}
      onChange={(e) => setInputValue(e.target.value)}
      placeholder="Skriv in artikelnummer"
      style={{ height: 220, resize: 'none', marginBottom: '1rem'}}
    />
    </Modal>
  );
};

export default KassanovaFetchModal;