import {
  BaseKey,
  HttpError,
  useList,
} from "@refinedev/core";
import { Modal, Button, Typography, Spin, Empty } from "antd";
import { IBase } from "interfaces";
import styled from "@emotion/styled";
import { useMediaQuery } from "@react-hook/media-query";
import Search from "antd/es/input/Search";
import {useEffect, useState } from "react";
import { RelationType } from "utility/helper";
import useHandleRelationValues from "components/hooks/dataGrid/useHandleRelationValues";
import SelectedRelationsSectionGrid from "components/dataGrid/editors/relationModal/selectedRelationSectionGrid";
import { swap } from "react-grid-dnd";
const { Text } = Typography;

// Sync size on loading/error/empty element with succeful dataItems element
enum UI_SYNC_FETCH {
  EXPECTED_ITEM_HEIGHT = "141.844px",
  MARGIN_BOTTOM = "1.5rem",
}

const ModalWrapper = styled("div")``;

const RequestIndicatorContainer = styled("div")`
  margin-bottom: ${UI_SYNC_FETCH.MARGIN_BOTTOM};
  min-height: ${UI_SYNC_FETCH.EXPECTED_ITEM_HEIGHT};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.88);
`;

const CardContainer = styled("div")`
  margin-bottom: ${UI_SYNC_FETCH.MARGIN_BOTTOM};
  background: white;
  border-radius: 0 0 8px 8px;
  display: grid;
  grid-template-columns: repeat(
    ${(props: MediaGridStyleProps) => props.columnCount},
    1fr
  );
  grid-gap: 1rem;
`;

const Header = styled("div")`
  /* padding: 16px 0px; */
  /* margin-bottom: 0.5rem; */
`;

const SelectedThumbnails = styled("div")`
  background-color: red;
`;

const Footer = styled("div")`
  padding: 0px 2px;
  display: flex;
  align-items: center;
  gap: 1rem;

  .ant-typography {
    font-weight: 600;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
  }

  .page-div {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex: 1;
  }

  .info-div {
    display: flex;
    gap: 1rem;
    flex: 1;
  }

  .page-count-div {
    display: flex;
    gap: 1rem;
    flex: 1;
    justify-content: flex-end;
  }
`;

type MediaGridStyleProps = {
  columnCount: number;
};
interface RelationModalProps<T extends IBase> {
  modalProps: any;
  onFinish: (selectedItems: T[]) => void;
  onCancel: () => void;
  initSelectedItems: T[];
  cardComp: any;
  type: RelationType;
  title: string;
}

export const RelationModalGrid = <T extends IBase,>({ 
  modalProps, 
  onFinish, 
  onCancel, 
  cardComp: CardComponent,
  initSelectedItems,
  type, 
  title
 }: RelationModalProps<T>) =>{
  const { isProductRelation, addModuleFilter, addModuleResource, setSearchValue } = useHandleRelationValues(type);
  const [selectedItems, setSelectedItems] = useState<T[]>(initSelectedItems ?? []);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(18);
  const [inputValue, setInputValue] = useState<string>("");
  const isMobile = useMediaQuery("(max-width: 800px)");
  const columnCount = isMobile ? 4 : 6;

  const {
    data,
    isLoading,
    isError,
    isFetching,
    isRefetching,
    isInitialLoading,
  } = useList<T, HttpError>({
    resource: addModuleResource,
    pagination: {
      current,
      pageSize,
    },
    queryOptions: {
      enabled: !!modalProps?.open && !!addModuleResource,
    },
    // meta: { headers: { exclude: idsToExclude } },
    filters: addModuleFilter,
  });

  const relationItems = Array.isArray(data?.data) ? data?.data : [];

  const onItemSelect = (id: BaseKey, checked: boolean) => {
    if (checked) {
      const item = relationItems?.find((i) => i.id === id);
      if (item) {
        setSelectedItems([...selectedItems, item]);
      }
    } else {
      setSelectedItems(selectedItems.filter((i) => i.id !== id));
    } 
  };

  const handleSearch = (newValue: any) => {
    setCurrent(1);
    setSearchValue(newValue);
  };

  const handleOk = () => {
    onFinish(selectedItems);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  useEffect(() => {
    return () => {
      setSearchValue("");
      setInputValue("");
    }
  }, []);

  const onOrderChange = (
    sourceId: string,
    sourceIndex: number,
    targetIndex: number
  ) => {
    const nextState = swap(selectedItems ?? [], sourceIndex, targetIndex);
    setSelectedItems(nextState);
  };

  const onDelete = (id: BaseKey) => {
    const newItems = selectedItems.filter((item) => item.id !== id);
    setSelectedItems(newItems);
  };

  return (
    <>
      <Modal
        {...modalProps}
        okText={"Lägg till relationer"}
        width={1000}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <ModalWrapper>
          <h3 style={{ fontSize: "18px" }}>{title} - lägg till relationer </h3>
          <Header>
            <Search
              placeholder={
                isProductRelation ? "Sök efter produkter" : "Sök efter filer"
              }
              size={"large"}
              value={inputValue}
              onChange={handleInputChange}
              onSearch={() => handleSearch(inputValue)}
              allowClear
              enterButton={true}
              disabled={isInitialLoading}
            />
          </Header>

          <SelectedRelationsSectionGrid
            type={type}
            title={title}
            items={selectedItems}
            onDelete={onDelete}
            onOrderChange={onOrderChange}
          />

          {isLoading || isFetching || isRefetching ? (
            <RequestIndicatorContainer>
              <Spin />
            </RequestIndicatorContainer>
          ) : isError ? (
            <RequestIndicatorContainer>
              <div> Något gick fel </div>
            </RequestIndicatorContainer>
          ) : !relationItems?.length ? (
            <RequestIndicatorContainer>
              <Empty
                style={{ padding: "0px" }}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </RequestIndicatorContainer>
          ) : (
            <CardContainer columnCount={columnCount}>
              {relationItems?.map((relationItem, i) => {
                return (
                  <CardComponent
                    key={i}
                    item={relationItem}
                    onSelect={onItemSelect}
                    selected={selectedItems?.some((item) => item?.id === relationItem.id)}
                    className="file-relation-card"
                  ></CardComponent>
                );
              })}
            </CardContainer>
          )}

          <Footer>
            <div className="info-div">
              <Text>Antal objekt: {data?.total ?? 0} </Text>
              {data?.total && (
                <Text>Antal valda objekt {selectedItems?.length}</Text>
              )}
            </div>
            <>
              <div className="page-div">
                <Button
                  type="primary"
                  size="small"
                  disabled={current == 1}
                  onClick={() => setCurrent((prev) => prev - 1)}
                >
                  Föregående sida
                </Button>
                <Button
                  type="primary"
                  size="small"
                  disabled={current * pageSize >= (data?.total ?? 0)}
                  onClick={() => setCurrent((prev) => prev + 1)}
                >
                  Nästa sida
                </Button>
              </div>
              <div className="page-count-div">
                <Text>
                  {current} /{" "}
                  {data?.total ? Math.ceil(data?.total / pageSize) : 1}
                </Text>
              </div>
            </>
          </Footer>
        </ModalWrapper>
      </Modal>
    </>
  );
};


// const setDefaultStatesOnModalLeave = () => {
//   setSelectedIds([]);
//   setSearchValue("");
//   setInputValue("");
// };