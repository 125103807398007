import React from "react";
import { Text } from "./text";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import relativeTime  from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(relativeTime);

type Props = {
    date: string;
};

export const DateText: React.FC<Props> = ({ date }: Props) => {
    const utcTime = dayjs.utc(date);
    const formattedTime = utcTime.local().format("YYYY-MM-DD HH:mm:ss");
    const timeMessage = dayjs().to(dayjs(formattedTime));
    return (
        <Text size="xs" type="secondary">
            {timeMessage}
        </Text>
    );
};