import * as signalR from "@microsoft/signalr";

const hubConnection = new signalR.HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_API_URL}/dashboard-events`) // Use the URL mapped in your backend
    .configureLogging(signalR.LogLevel.Information)
    .build();

export const startConnection = async () => {
    try {
        await hubConnection.start();
        console.log("SignalR Connected!");
    } catch (err) {
        console.log("Error while establishing connection: " + err);
    }
};

export const addHubListener = (eventName : any, callback : any) => {
    hubConnection.on(eventName, callback);
};

export const stopConnection = async () => {
    await hubConnection.stop();
    console.log("SignalR Disconnected!");
};