import React from 'react';
import { Image } from "antd";
import OpenAiLogo from '../../../assets/openai-chatgpt-logo.png';
import GenericJobIcon from '../../../assets/generic-job-icon.svg';
import ErpIcon from '../../../assets/erp.svg';
import CSS from 'csstype';
import styled from '@emotion/styled'

type NotificationImageProps = {
  type: string;
};

type ImageWrapperProps = {
    marginX?: string
  }

const ImageWrapper = styled('div')<ImageWrapperProps>`
  .ant-image {
    margin-left: ${props => props.marginX ?? '0px'};
    margin-right: ${props => props.marginX ?? '0px'};
  }
`;

const NotificationImage: React.FC<NotificationImageProps> = ({ type }) => {

  switch (type) {
    case "OpenAI":
      return <ImageWrapper><Image width={60} preview={false} src={OpenAiLogo} /></ImageWrapper>;
    case 'Kassanova':
        return <ImageWrapper marginX={'10px'}><Image width={39} preview={false} src={ErpIcon} /></ImageWrapper>;
    default:
        return <ImageWrapper><Image width={40} preview={false} src={GenericJobIcon} /></ImageWrapper>;
  }
};

export default NotificationImage;
