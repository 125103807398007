import { CopyOutlined } from "@ant-design/icons";
import { copyToClipboard } from "utility/helper";
import styled from "@emotion/styled";

const RenderStringCellWithCopyWrapper = styled('div')`
  .value-content {
    opacity: 0.7;
  }
  .copy-icon {
    position: absolute;
    top: 0;
    right: 6px;
    bottom: 0;
    height: 100%;
  }
  .copy-icon-container {
    opacity: 0.7;
    display: flex;
    height: 100%;
    align-items: center;
    :hover {
      opacity: 1;
    }
  }
  .copy-icon-button {
    cursor: pointer;
    background-color: white;
    padding: 3px 2px 0px 2px;
    border-radius: 4px;
    border: 1px solid #d8d8d8;
  }
`;

type StringCellWithCopyProps = {
  value: string;
  formatValue?: (value: string) => string;
};

type NonStringCellWithCopyProps<T> = {
  value: T;
  formatValue: (value: T) => string; // must be provided for non-string values
};

type CellWithCopyProps<T> = T extends string
  ? StringCellWithCopyProps
  : NonStringCellWithCopyProps<T>;

/**
 * If not passing value as string, a formatValue function must 
 * be provided to convert the value to a string.
 */
function RenderStringCellWithCopy(
  props: StringCellWithCopyProps
): JSX.Element;
function RenderStringCellWithCopy<T>(
  props: NonStringCellWithCopyProps<T>
): JSX.Element;
function RenderStringCellWithCopy<T>(props: CellWithCopyProps<T>) {
  const { value, formatValue } = props;

  // Use the formatter if provided, otherwise convert to string
  const formattedValue = formatValue
    ? formatValue(value as any)
    : String(value);

  return <RenderStringCellWithCopyWrapper>
    <span className="value-content">{formattedValue}</span>
    <span className="copy-icon">
      <div className="copy-icon-container">
        <span className="copy-icon-button">
          <CopyOutlined onClick={() => copyToClipboard(formattedValue)} />
        </span>
      </div>
    </span>
  </RenderStringCellWithCopyWrapper>;
}

export default RenderStringCellWithCopy;
