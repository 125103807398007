import { getUserInfoFromCache } from "utility/helper";
import type { ICategory } from "interfaces";

export const fetchSubCategories = async ( apiUrl: string, key: string, locale: string,): Promise<ICategory[]> => {
  try {
    const userInfo = getUserInfoFromCache();

    const response = await fetch(`${apiUrl}/categories/${key}`, {
      method: 'GET',
      headers: { 'Authorization': `Bearer ${userInfo.token}`,
      'Lang' : `${locale}`
     },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch subcategories.');
    }
    
    const responseData = await response.json(); // Parse the response JSON
    return responseData?.subCategories ?? []; // Return the parsed JSON data

  } catch (error) {
    throw error; // Re-throw the error to handle it elsewhere
  }
};