
import { BaseKey } from "@refinedev/core";
import { useSaveStackData } from "components/hooks/dataGrid/useDataGridSource";
import {
  FlexibleIProductUpdatePayload,
  IProduct,
} from "interfaces";
import useDataGridStore, { IStackDataItem } from "stores/dataGridStore";

interface UpdatedTableData {
  id: string;
  updatedEntityNames: string[];
}

interface PreparedTableDataToSave {
  id: BaseKey;
  payload: FlexibleIProductUpdatePayload;
}

function selectPropertiesByName(
  obj: Record<string, any>,
  properties: string[]
) {
  const result: { [key: string]: any } = {};
  properties.forEach((prop) => {
    if (prop in obj) {
      result[prop] = obj[prop];
    }
  });
  return result;
}

function extractModifiedTableData(updatedStackData: IStackDataItem[]) {
  const resultObj: { [key: string]: UpdatedTableData } = {};

  updatedStackData?.forEach((updatedStackDataItem) => {
    const { cell } = updatedStackDataItem;
    if (!cell || typeof cell !== "string") return;

    const parts = cell.trim().split(",");
    if (parts.length !== 2) return; // Ensure there are exactly two parts

    const [productId, entityName] = parts;
    if (!resultObj[productId]) {
      resultObj[productId] = { id: productId, updatedEntityNames: [] };
    }
    const entityNames = resultObj[productId];
    if (!entityNames.updatedEntityNames.includes(entityName)) {
      entityNames.updatedEntityNames.push(entityName);
    }
  });
  return Object.values(resultObj);
}

function constructPayloadData(data: Record<string, any>) {
  const normalizedPayload: Record<string, any> = {};
  const keyMappings = {
    categories: "categoryIds",
    images: "imageIds",
    manuals: "manualIds",
    safetySheets: "safetySheetIds",
    dimensionalSheets: "dimensionalSheetIds",
    spareParts: "sparePartIds",
    accessories: "accessoryIds",
  };
  Object.entries(data).forEach(([key, value]) => {
    // Map the key or use the original key
    const newKey = keyMappings[key as keyof typeof keyMappings] || key;
    // Extract nested object IDs if the value is an array of objects
    if (Array.isArray(value) && value.every(item => typeof item === 'object' && item !== null && 'id' in item)) {
      normalizedPayload[newKey] = value.map(item => item.id);
    } else {
      normalizedPayload[newKey] = value; // Use the original value
    }
  });
  return normalizedPayload;
}

function prepareTableDataToSave(
  updatedTableData: UpdatedTableData[],
  updatedSourceData: IProduct[]
) {
  let preparedData: PreparedTableDataToSave[] = [];

  updatedTableData.forEach((updatedTableItem) => {
    const matchedUpdatedSourceDataObj = updatedSourceData.find(
      (updatedSourceDataItem) => updatedSourceDataItem.id == updatedTableItem.id
    );

    if (matchedUpdatedSourceDataObj) {
      const pickedKeyValuePairs = selectPropertiesByName(
        matchedUpdatedSourceDataObj,
        updatedTableItem.updatedEntityNames
      );

      const payload = constructPayloadData(pickedKeyValuePairs);

      const preparedDataItem = {
        id: matchedUpdatedSourceDataObj?.id,
        payload: payload,
      };

      preparedData.push(preparedDataItem);
    }
  });

  return preparedData;
}

const createPatchDataRequests = (
  updateStack: IStackDataItem[],
  dataSource: IProduct[]
): PreparedTableDataToSave[] => {
  const updatedTableData = extractModifiedTableData(updateStack);
  const preparedTableDataToSave = prepareTableDataToSave(
    updatedTableData,
    dataSource
  );
  return preparedTableDataToSave;
};

const useSaveDataGridData = () => {
  const { updateStack, dataSource } = useDataGridStore();
  const { saveStackData } = useSaveStackData();

  const saveDataGridData = () => { 
    const patchDataRequests = createPatchDataRequests(updateStack, dataSource);
    saveStackData(patchDataRequests);
  };

  return { saveDataGridData };
};

export default useSaveDataGridData;
