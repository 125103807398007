import React, { PropsWithChildren, createElement } from "react";
import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Grid, Breadcrumb, Layout, Menu, theme } from 'antd';
import { CustomSider } from "components/layout/sider";
import { Logo } from "components/layout/logo";
import { RefineThemedLayoutV2Props, ThemedLayoutContextProvider } from "@refinedev/antd";

export const CustomLayout: React.FC<RefineThemedLayoutV2Props> = ({
    children,
    Header,
    Sider,
    Title,
    Footer,
    OffLayoutArea,
    initialSiderCollapsed,
}) => {
  
  const breakpoint = Grid.useBreakpoint();
  const isSmall = typeof breakpoint.sm === "undefined" ? true : breakpoint.sm;
  const { Content } = Layout;
  const Ele = () => <></>;
  const HeaderToRender : React.FC = Header ?? Ele;

  return (
    <ThemedLayoutContextProvider initialSiderCollapsed={initialSiderCollapsed}>
      <Layout
        style={{ minHeight: "80vh", display: "flex", flexDirection: "column" }}
      >
        <HeaderToRender/>
        <Layout>
          <CustomSider></CustomSider>
          <Layout style={{ padding: "0 24px 24px", backgroundColor: '#ededed' }}>
          <Content>
            <div
                style={{
                    minHeight: 360,
                    padding: isSmall ? 24 : 12,
                }}
            >
                {children}
            </div>
            {OffLayoutArea && <OffLayoutArea />}
          </Content>
          </Layout>
        </Layout>
      </Layout>
    </ThemedLayoutContextProvider>
  );
};