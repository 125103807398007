import { BaseKey, useApiUrl, useCustomMutation, useSelect } from '@refinedev/core';
import { getUserInfoFromCache } from 'utility/helper';

export const useSyncJetshopImages = () => {
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation();
  const userInfo = getUserInfoFromCache();
  
  const syncJetshopImages= (productIds: React.Key[]) => {
    mutate({
      url: `${apiUrl}/actions/update-jetshop-images`,
      method: "post",
      successNotification: (data, values) => {
        return {
          message: "Bilder skickas mot Norce",
          description: "",
          type: "success",
        };
      },
      values: {
        ids: productIds,
      },
      config: {
        headers: { Authorization: `Bearer ${userInfo.token}`}
      }
    });
  };

  return { syncJetshopImages };
};