import { BaseKey, CrudFilters, HttpError, useMany } from '@refinedev/core';
import { use } from 'i18next';
import { IFile, IProduct, IProductItem } from 'interfaces';
import { useState, useEffect } from 'react';
import { useProductStore } from 'stores/productStore';
import { FILE_RES_ID, PRODUCT_ITEM_RES_ID, PRODUCT_RES_ID } from 'utility/constants';
import { RelationType } from 'utility/helper';

interface UseRelation {
  addModuleFilter: CrudFilters;
  addModuleResource: string;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  typeOptions: RelationType[];
  setItems: React.Dispatch<React.SetStateAction<any[] | undefined>>;
  items?: any[];
  idsToExclude: BaseKey[];
}

const useRelation = (type: RelationType): UseRelation => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [addModuleResource, setAddModuleResource] = useState<string>("");
  const [items, setItems] = useState<any[] | undefined>([]);
  const [ids, setIds] = useState<BaseKey[]>([]);
  const { product } = useProductStore();

  const isProductRelation = type == RelationType.RelatedParts || type == RelationType.SpareParts || type == RelationType.Accessories;

  let filters: CrudFilters = [];
  let typeOptions: RelationType[] = [];

  const { data, isLoading, isError } = useMany<IProduct, HttpError>({
    resource: PRODUCT_RES_ID,
    ids,
    queryOptions: {
      enabled: ids.length >= 0 && isProductRelation,
    }
  });

  useEffect(() => {
    if (type == RelationType.ProductImage){
      setItems(product?.images ?? []);
      setIds(product?.images.map((image) => image.id) ?? []);
      setAddModuleResource(FILE_RES_ID);
    } else if(type == RelationType.Manual){
      setItems(product?.manuals ?? []);
      setIds(product?.manuals.map((manuals) => manuals.id) ?? []);
      setAddModuleResource(FILE_RES_ID);
    } else if(type == RelationType.RelatedParts){
      // setIds(product?.relatedParts ?? []);
      setIds(product?.relatedPartIds ?? []);
      setAddModuleResource(PRODUCT_RES_ID);
    } else if(type == RelationType.SpareParts){
      // setIds(product?.spareParts ?? []);
      setIds(product?.sparePartIds ?? []);
      setAddModuleResource(PRODUCT_RES_ID);
    }else if(type == RelationType.Accessories){
      // setIds(product?.accessories ?? []);
      setIds(product?.accessoryIds ?? []);
      setAddModuleResource(PRODUCT_RES_ID);
    } else if(type == RelationType.SafetySheet){
      setItems(product?.safetySheets ?? []);
      setIds(product?.safetySheets.map((safetySheet) => safetySheet.id) ?? []);
      setAddModuleResource(FILE_RES_ID);
    } else if(type == RelationType.DimensionalSheet){
      setItems(product?.dimensionalSheets ?? []);
      setIds(product?.dimensionalSheets.map((dimensionalSheet) => dimensionalSheet.id) ?? []);
      setAddModuleResource(FILE_RES_ID);
    }
  }, [product]);

  // useEffect(() => {
  //   if(type == RelationType.Accessories){
  //     setIds(product?.accessoryIds ?? []);
  //   }
  // }, [product?.accessoryIds]);

  useEffect(() => {
    setItems(data?.data ?? []);
  }, [data?.data]);

  if (
    type == RelationType.Manual ||
    type == RelationType.SafetySheet ||
    type == RelationType.DimensionalSheet
  ) {
    filters = [
      {
        field: "name",
        operator: "contains",
        value: searchValue,
      },
      {
        field: "extension",
        operator: "eq",
        value: ".pdf",
      },
    ];
    typeOptions = [
      RelationType.Manual,
      RelationType.SafetySheet,
      RelationType.DimensionalSheet,
    ];
  }

  if (type == RelationType.ProductImage) {
    filters = [
      {
        field: "name",
        operator: "contains",
        value: searchValue,
      },
      {
        field: "extension",
        operator: "ne",
        value: ".pdf",
      },
    ];
    typeOptions = [];
  }

  if (isProductRelation) {
    filters = [
      {
        field: "fullSearch",
        operator: "contains",
        value: searchValue,
      }
    ];
    typeOptions = [];
  }

  return {
    addModuleFilter: filters,
    addModuleResource,
    typeOptions: typeOptions,
    setSearchValue,
    items,
    setItems,
    idsToExclude: ids,
  };
};

export default useRelation;